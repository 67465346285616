import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Routes as ReactRouterDomRoutes,
  Route,
  Navigate,
} from 'react-router-dom';

import Flex from '../../../../components/Flex';
import MailNavigation from '../MailNavigation';
import {
  getActiveMailFolderId,
  getMailSettings,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import MailNavigationBar from '../NavigationBar/MailNavigationBar';
import { setMailListNavigationState } from '../../actions/actionControllers/mailNavigationActionController';
import MailRowLayout from '../layouts/MailRowLayout';
import MailColumnLayout from '../layouts/MailColumnLayout';
import MailHiddenLayout from '../layouts/MailHiddenLayout';
import { Message } from '../../../../models/Message';
import MailSettingsDrawer from '../MailSettingsDrawer';
import { fetchMailFoldersSagaAction } from '../../actions/sagas';
import { addDraftMessage } from '../../actions/actionControllers/draftsActionController';
import { apiCreateMessageDraft } from '../../api';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../../theme/utils';
import { MailFolderId } from '../../../../models/Types';
import * as Sentry from '@sentry/react';
import { MailFilterContextProvider } from '../MailSearch/MailFilterContext';

const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  content: {
    height: '100%',
    overflow: 'hidden',
  },
  panel: {
    transition: 'width 375ms ease-in-out, opacity 375ms ease-in-out',
    overflow: 'hidden',
    position: 'relative',
  },
}));

interface MailMeSubModuleProps {}

export const MailMeSubModule: React.FC<MailMeSubModuleProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const mailSettings = useSelector(getMailSettings);

  const [selectedMessages, setSelectedMessages] = useState<Message[]>([]);

  const [mailSettingsDrawerVisible, setMailSettingsDrawerVisible] =
    useState<boolean>(false);

  const activeMailFolderId = useSelector<RootReducerState, MailFolderId>(
    (state) => getActiveMailFolderId(state, 'me')
  );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const clearSelection = () => setSelectedMessages([]);

  const doShowNewMessage = async (setIsCreating: (value: boolean) => void) => {
    setIsCreating(true);
    const { data } = await apiCreateMessageDraft('me');
    if (data) {
      dispatch(addDraftMessage('me', data, true));
    } else {
      notification.open({
        message: t('common:error'),
        description: t('mail:errorMessages.messages.createDraftError'),
      });
    }
    setIsCreating(false);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setTimeout(() => {
      dispatch(fetchMailFoldersSagaAction('me'));
    }, 500);
  }, [dispatch]);

  /*
  useEffect(() => {
    const handler = setTimeout(() => {
      dispatch(deleteProjectNews('me'));
    }, 5000);
    return () => {
      clearTimeout(handler);
    };
  }, [dispatch]);
  */

  useEffect(() => {
    if (!window.location.href.match(/message\/(.*)\/details?$/)) {
      dispatch(setMailListNavigationState(null, 'me'));
    }
  }, [dispatch]);
  //#endregion

  return (
    <Flex.Column className={classes.root}>
      <MailFilterContextProvider>
        <MailNavigationBar
          projectId={'me'}
          doShowNewMessage={doShowNewMessage}
          selectedMessages={selectedMessages}
          clearSelection={clearSelection}
        />
        <Flex.Row flex={1} className={classes.content}>
          <Routes>
            <Route
              path="*"
              element={<Navigate to={activeMailFolderId ?? 'inbox'} />}
            />
            <Route
              path=":mailFolderId/*"
              element={
                <MailNavigation
                  projectId={'me'}
                  handleOpenSettings={setMailSettingsDrawerVisible}
                  openSettings={mailSettingsDrawerVisible}
                  clearSelectedMessages={setSelectedMessages}
                />
              }
            />
            <Route
              path=":mailFolderId/message/:messageId/details"
              element={
                <MailNavigation
                  projectId={'me'}
                  handleOpenSettings={setMailSettingsDrawerVisible}
                  openSettings={mailSettingsDrawerVisible}
                  clearSelectedMessages={setSelectedMessages}
                />
              }
            />
          </Routes>
          {mailSettings.messageViewLayout === 'right' ? (
            <MailRowLayout
              projectId={'me'}
              pathPrefix="../../../"
              selectedMessages={selectedMessages}
              onSelectionChange={setSelectedMessages}
              mailSettings={mailSettings}
            />
          ) : mailSettings.messageViewLayout === 'bottom' ? (
            <MailColumnLayout
              projectId={'me'}
              pathPrefix="../../../"
              selectedMessages={selectedMessages}
              onSelectionChange={setSelectedMessages}
              mailSettings={mailSettings}
            />
          ) : (
            <MailHiddenLayout
              projectId={'me'}
              pathPrefix="../../../"
              selectedMessages={selectedMessages}
              onSelectionChange={setSelectedMessages}
              mailSettings={mailSettings}
            />
          )}
          <MailSettingsDrawer
            mailSettings={mailSettings}
            mailSettingsDrawerVisible={mailSettingsDrawerVisible}
            setMailSettingsDrawerVisible={setMailSettingsDrawerVisible}
          />
        </Flex.Row>
      </MailFilterContextProvider>
    </Flex.Column>
  );
};

export default MailMeSubModule;
