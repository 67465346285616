import React, { useEffect, useRef, useState } from 'react';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import FilterContextProvider from '../../../components/Filter/FilterContextProvider';
import {
  FILTER_DATA_LIST_CLASS_PREFIX,
  FilterBar,
} from '../../../components/Filter/FilterBar';
import classNames from 'classnames';
import ProjectContactsPageTable, {
  ProjectContactsPageTableRef,
  actions,
} from './ProjectContactsPageTable';
import { ProjectMemberSearchResultItem } from '../../../models/ProjectContacts';
import { Modal, Select } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { fetchInternalJobTitles } from '../../settings/actions/internalJobTitles';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootReducerState,
  getAllHourlyRates,
  getInternalJobTitles,
  getUserMe,
} from '../../../apps/main/rootReducer';
import {
  fetchHourlyRates,
  updateProjectContacts,
} from '../../projects/actions';
import { HourlyRate } from '../../../models/HourlyRate';
import { ProjectRole } from '../../../models/Types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    padding: 24,
    flex: 1,
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  panelHeadline: {
    '&.ant-typography': {
      fontWeight: theme.old.typography.fontWeight.regular,
    },
  },
  form: {
    height: 'calc(100% - 33px)',
  },
}));

interface ProjectContactsPageProps {
  projectId: string;
  className?: string;
  showProject?: boolean;
}

export const ProjectContactsPage: React.FC<ProjectContactsPageProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { projectId, className } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const tableRef = useRef<ProjectContactsPageTableRef>(null);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalAction, setModalAction] = useState<actions | null>(null);
  const dispatch = useDispatch();

  const [selectedProjectMembers, setSelectedProjectMembers] = useState<
    ProjectMemberSearchResultItem[]
  >([]);

  //#region ------------------------------ Methods / Handlers

  const onSelectionChange = (items: ProjectMemberSearchResultItem[]) => {
    setSelectedProjectMembers(items);
  };

  const onOpenModal = (action: actions) => {
    setModalAction(action);
    setOpenModal(true);
  };

  const onCloseModal = () => {
    setOpenModal(false);
    setModalAction(null);
  };

  const updateProjectMemberTable = () => {
    tableRef.current?.fetchProjectMember();
  };

  const onOkModal = (selectedOption: string | string[]) => {
    switch (modalAction) {
      case 'adjustFunction':
        const updatedItems = selectedProjectMembers?.map((i) => ({
          ...i.data,
          jobTitle: selectedOption as string,
        }));
        dispatch(updateProjectContacts(projectId, updatedItems));
        updateProjectMemberTable();

        break;
      case 'adjustRoles':
        const updatedItems1 = selectedProjectMembers?.map((i) => ({
          ...i.data,
          projectRoles: [
            ...(selectedOption as ProjectRole[]),
            'projectMember',
          ] as ProjectRole[],
        }));
        dispatch(updateProjectContacts(projectId, updatedItems1));
        updateProjectMemberTable();
        break;
      case 'adjustHourlyRate':
        const updatedItems2 = selectedProjectMembers?.map((i) => ({
          ...i.data,
          hourlyRateId: selectedOption,
        }));
        dispatch(updateProjectContacts(projectId, updatedItems2));
        updateProjectMemberTable();
        break;
      case 'archive':
        const updatedItems3 = selectedProjectMembers?.map((i) => ({
          ...i.data,
          archived: true,
        }));
        dispatch(updateProjectContacts(projectId, updatedItems3));
        updateProjectMemberTable();
        break;
      default:
        break;
    }
    setOpenModal(false);
    setModalAction(null);
  };

  //#endregion

  return (
    <Flex.Column className={classNames(classes.root, className)}>
      <FilterContextProvider<ProjectMemberSearchResultItem>
        searchType="projectMembers"
        equalityFunction={(a, b) =>
          a.data.data.contactId === b.data.data.contactId
        }
      >
        <FilterBar
          contextSearchString={`Data.ProjectId eq '${projectId}'`}
          disabledPickers={['Data.ProjectId']}
          customDefaultSearchParameters={[
            {
              parameterName: 'Data.ProjectId',
              defaultValue: projectId,
              defaultMethod: 'eq',
            },
          ]}
        />
        <ProjectContactsPageTable
          className={FILTER_DATA_LIST_CLASS_PREFIX}
          ref={tableRef}
          projectId={projectId}
          tableId="pcp1"
          selectedProjectMembers={selectedProjectMembers}
          onSelectionChange={onSelectionChange}
          onOpenModal={onOpenModal}
        />
        <ActionsModal
          visible={openModal}
          onClose={onCloseModal}
          action={modalAction}
          onOk={onOkModal}
          projectId={projectId}
          projectMembers={selectedProjectMembers}
        />
      </FilterContextProvider>
    </Flex.Column>
  );
};
export default ProjectContactsPage;

//#region ------------------------------ PendingActionItems
interface ActionsModalProps {
  action: actions;
  visible: boolean;
  onClose: () => void;
  onOk: (selectedOption: string | string[]) => void;
  projectId: string;
  projectMembers: ProjectMemberSearchResultItem[];
}

const ActionsModal: React.FC<ActionsModalProps> = (props) => {
  //#region ------------------------------ Defaults
  const { action, visible, onClose, onOk, projectId, projectMembers } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const internalJobTitles: string[] =
    useSelector(getInternalJobTitles)?.map((i) => i.name) || [];

  const hourlyRates = useSelector<RootReducerState, HourlyRate[]>((state) =>
    getAllHourlyRates(state, projectId)
  );

  const pojectRoles: ProjectRole[] = [
    'projectAdmin',
    'projectAssistance',
    'projectController',
  ];

  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const Funktionoptions = internalJobTitles?.map((i) => ({
    label: i,
    value: i,
  }));

  const HourlyRateOptions = hourlyRates?.map((i) => ({
    label: i.name,
    value: i.hourlyRateId,
  }));

  const RoleOptions = pojectRoles?.map((i) => ({
    label: i,
    value: i,
  }));

  const userMe = useSelector(getUserMe);

  const title = t(`contacts:projectContactsPage.actions.${action}`);
  //#endregion

  //#region ------------------------------ Effects
  const handleSelectChange = (value: string) => {
    setSelectedOption(value);
  };

  const handleSelectChangeMultiple = (value: string[]) => {
    setSelectedOptions(value);
  };

  useEffect(() => {
    if (action === 'adjustFunction') {
      dispatch(fetchInternalJobTitles());
    }
    if (action === 'adjustHourlyRate') {
      dispatch(fetchHourlyRates(projectId));
    }
  }, [dispatch, action, projectId]);
  //#endregion

  //#region ------------------------------ Methods / Handlers

  const handleOk = () => {
    switch (action) {
      case 'adjustFunction':
        onOk(selectedOption);
        setSelectedOption(null);
        break;
      case 'adjustHourlyRate':
        onOk(selectedOption);
        setSelectedOption(null);
        break;
      case 'adjustRoles':
        onOk(selectedOptions);
        setSelectedOptions([]);
        break;
      case 'archive':
        onOk(null);
        break;
      default:
        break;
    }
  };

  const renderContent = () => {
    switch (action) {
      case 'adjustFunction':
        return (
          <>
            {projectMembers.length}
            {t('contacts:projectContactsPage.itemsSelected')}
            <Select
              showSearch={false}
              options={Funktionoptions}
              onChange={handleSelectChange}
            ></Select>
          </>
        );
      case 'adjustRoles':
        return (
          <>
            {projectMembers.length}
            {t('contacts:projectContactsPage.itemsSelected')}
            <Select
              showSearch={false}
              options={RoleOptions}
              onChange={handleSelectChangeMultiple}
              mode="tags"
            ></Select>
          </>
        );
      case 'adjustHourlyRate':
        return (
          <>
            {projectMembers.length}
            {t('contacts:projectContactsPage.itemsSelected')}
            <Select
              showSearch={false}
              options={HourlyRateOptions}
              onChange={handleSelectChange}
            ></Select>
          </>
        );
      case 'archive':
        return projectMembers.length !== 1
          ? t('contacts:projectContactsPage.deleteItems', {
              count: projectMembers.length,
            })
          : t('contacts:projectContactsPage.deleteSingleItem');
      default:
        return null;
    }
  };

  return (
    console.log('userMe', userMe),
    (
      <Modal
        width="550px"
        visible={visible}
        title={title}
        onClose={onClose}
        onOk={handleOk}
        okText={t('contacts:projectContactsPage.okText')}
        cancelText={t('contacts:projectContactsPage.cancelText')}
        destroyOnClose
      >
        {renderContent()}
      </Modal>
    )
  );
};
