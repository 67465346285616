import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Typography, notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTheme } from '@prio365/prio365-react-library/lib/ThemeProvider';
import Flex from '../../../../components/Flex';
import { DriveItem, Preview } from '../../../../models/Drive';
import {
  DefaultDateTimeFormatString,
  formatHumanFileSize,
} from '../../../../util';
import { iconForMimeType } from '../../util';
import { PrioTheme } from '../../../../theme/types';
import i18n from '../../../../i18n';
import { useSelector } from 'react-redux';
import { getOpenCurrentPreviewModal } from '../../../../apps/main/rootReducer';
import { MessageAttachment } from '../../../../models/Message';
import { apiDownloadDriveItem } from '../../api';
import { GroupId } from '../../../../models/Types';
import { makePrioStyles } from '../../../../theme/utils';
import { DriveItemVersion } from '../../../../models/Document';
import { downloadAttachment } from '../../../mail/components/EmailComposer/EmailComposer';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '48px',
    overflowX: 'scroll',
    overflowY: 'hidden',
    alignItems: 'baseline',
  },
  previewItemsSmallerPadding: {
    backgroundColor: theme.colors.application.background.default,
    padding: theme.spacing.small,
    paddingLeft: theme.spacing.regular,
  },
  nameLabel: {
    maxWidth: '500px',
  },
  label: {
    color: 'rgba(0,0,0,0.6)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  boldLabel: {
    fontWeight: 700,
  },
  descriptionRow: {
    fontSize: theme.font.fontSize.small,
  },
}));

interface PreviewModalNavBarProps {
  className?: string;
  groupId: GroupId;
  preview: Preview;
  isDriveItem: (item: any) => boolean;
  selectedDriveItems?: (DriveItem | MessageAttachment)[];
  currentDriveItemVersion?: DriveItemVersion;
  onClose?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  itemList: (DriveItem | MessageAttachment)[];
}

export const PreviewModalNavBar: React.FC<PreviewModalNavBarProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    className,
    groupId,
    preview,
    isDriveItem,
    selectedDriveItems,
    currentDriveItemVersion,
    onClose = () => {},
    itemList,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme() as PrioTheme;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const driveItemsSelected = selectedDriveItems?.length > 0;
  const totalSizeOfSelectedDriveItems = useMemo(
    () => selectedDriveItems?.reduce((prev, curr) => prev + curr.size, 0),
    [selectedDriveItems]
  );
  const previewModal = useSelector(getOpenCurrentPreviewModal);

  const [id, setId] = useState<string | undefined>(undefined);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const downloadSmimeAttachment = async (
    messageAttachment: MessageAttachment
  ) => {
    if (messageAttachment?.contentBytes?.length > 0) {
      const linkSource = `data:${messageAttachment.contentType};base64,${messageAttachment.contentBytes}`;
      const downloadLink = document.createElement('a');
      downloadLink.href = linkSource;
      downloadLink.download = messageAttachment.name;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };

  const onItemDownload = useCallback(
    async (item: DriveItem | MessageAttachment) => {
      if (isDriveItem(item)) {
        if (!(await apiDownloadDriveItem(groupId, item as DriveItem))) {
          notification.open({
            message: t('common:error'),
            description: t('documents:errorMessages.downloadFileError'),
          });
        }
      } else {
        const _item = item as MessageAttachment;
        const itemListItem = itemList.find(
          (i) => i.id === id
        ) as MessageAttachment;
        const attachment = {
          ..._item,
          contentBytes: itemListItem?.contentBytes ?? null,
        } as MessageAttachment;

        if (attachment.contentBytes) {
          downloadSmimeAttachment(attachment).catch((error) => {
            notification.open({
              message: t('common:error'),
              description: t('documents:errorMessages.downloadFileError'),
            });
          });
        } else {
          downloadAttachment(
            previewModal.projectId,
            previewModal.messageId,
            {
              ...attachment,
              contentBytes: null,
            },
            t
          );
        }
      }
    },
    [groupId, t, isDriveItem, id, itemList, previewModal]
  );

  // TODO: Share Funtion wird erst später umgesetzt
  // const onItemShare = useCallback(async (item: DriveItem) => {}, []);

  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setId(preview?.item?.id);
  }, [preview?.item?.id]);
  //#endregion

  return (
    <Flex.Row
      className={classNames(
        classes.root,
        className,
        classes.previewItemsSmallerPadding
      )}
    >
      <Flex.Row flex={1}>
        {/* Beschreibung */}
        {!driveItemsSelected && (
          <Flex.Row
            flex={1}
            alignItems="center"
            childrenGap={theme.spacing.large}
            className={classes.descriptionRow}
          >
            <Flex.Item flexShrink={1} className={classes.nameLabel}>
              <div title={preview?.item?.name ?? ''}>
                <Typography.Text ellipsis>
                  {preview?.item?.name ?? ''}
                </Typography.Text>
              </div>
            </Flex.Item>
            {isDriveItem(preview?.item) && (
              <Flex.Row alignItems="center" childrenGap={theme.spacing.small}>
                <Flex.Item flexGrow={0}>
                  <Typography.Text className={classes.label}>
                    <span className={classes.boldLabel}>{`${t(
                      'documents:documentDetails.labels.version'
                    )}`}</span>
                    <span>:</span>
                  </Typography.Text>
                </Flex.Item>
                <Flex.Item flexGrow={0}>
                  <Typography.Text className={classes.label}>
                    {currentDriveItemVersion?.id}
                  </Typography.Text>
                </Flex.Item>
              </Flex.Row>
            )}
            <Flex.Row alignItems="center" childrenGap={theme.spacing.small}>
              <Flex.Item flexGrow={0}>
                <Typography.Text className={classes.label}>
                  <span className={classes.boldLabel}>{`${t(
                    'documents:documentDetails.labels.type'
                  )}`}</span>
                  <span>:</span>
                </Typography.Text>
              </Flex.Item>
              <Flex.Item flexGrow={0}>
                <Typography.Text className={classes.label}>
                  {preview?.mimeType
                    ? t(
                        `documents:fileTypeNames.${iconForMimeType(
                          preview.mimeType
                        )}`
                      )
                    : ''}
                </Typography.Text>
              </Flex.Item>
            </Flex.Row>
            <Flex.Row alignItems="center" childrenGap={theme.spacing.small}>
              <Flex.Item flexGrow={0}>
                <Typography.Text className={classes.label}>
                  <span className={classes.boldLabel}>{`${t(
                    'documents:documentDetails.labels.size'
                  )}`}</span>
                  <span>:</span>
                </Typography.Text>
              </Flex.Item>
              <Flex.Item flexGrow={0}>
                <Typography.Text className={classes.label}>
                  {preview?.item.size
                    ? formatHumanFileSize(preview.item.size)
                    : ''}
                </Typography.Text>
              </Flex.Item>
            </Flex.Row>
            {preview?.optional?.lastModifiedDateTime && (
              <Flex.Row alignItems="center" childrenGap={theme.spacing.small}>
                <Flex.Item flexGrow={0}>
                  <Typography.Text className={classes.label}>
                    <span className={classes.boldLabel}>{`${t(
                      'documents:documentDetails.labels.lastModifiedDateTime'
                    )}`}</span>
                    <span>:</span>
                  </Typography.Text>
                </Flex.Item>
                <Flex.Row flexGrow={0} childrenGap={theme.spacing.small}>
                  <Flex.Item>
                    <Typography.Text className={classes.label}>
                      {DefaultDateTimeFormatString(
                        preview?.optional?.lastModifiedDateTime,
                        i18n.language
                      )}
                    </Typography.Text>
                  </Flex.Item>
                  {isDriveItem(preview.item) && (
                    <Flex.Item>
                      <Typography.Text className={classes.label}>
                        |
                      </Typography.Text>
                    </Flex.Item>
                  )}
                  {isDriveItem(preview.item) && (
                    <Flex.Item>
                      <Typography.Text className={classes.label}>
                        {
                          (preview.item as DriveItem).lastModifiedBy?.user
                            ?.displayName
                        }
                      </Typography.Text>
                    </Flex.Item>
                  )}
                </Flex.Row>
              </Flex.Row>
            )}
          </Flex.Row>
        )}
        {/* Beschreibung MultiSelect */}
        {driveItemsSelected && (
          <Flex.Row
            flex={1}
            alignItems="center"
            childrenGap={theme.spacing.large}
          >
            <Flex.Row alignItems="center" childrenGap={theme.spacing.small}>
              <Flex.Item flexGrow={0}>
                <Typography.Text className={classes.label}>
                  <span className={classes.boldLabel}>{`${t(
                    'documents:documentDetails.labels.numberOfSelectedDriveItems'
                  )}`}</span>
                  <span>:</span>
                </Typography.Text>
              </Flex.Item>
              <Flex.Item flexGrow={0}>
                <Typography.Text className={classes.label}>
                  {selectedDriveItems?.length}
                </Typography.Text>
              </Flex.Item>
            </Flex.Row>
            <Flex.Row alignItems="center" childrenGap={theme.spacing.small}>
              <Flex.Item flexGrow={0}>
                <Typography.Text className={classes.label}>
                  <span className={classes.boldLabel}>{`${t(
                    'documents:documentDetails.labels.totalSize'
                  )}`}</span>
                  <span>:</span>
                </Typography.Text>
              </Flex.Item>
              <Flex.Item flexGrow={0}>
                <Typography.Text className={classes.label}>
                  {formatHumanFileSize(totalSizeOfSelectedDriveItems)}
                </Typography.Text>
              </Flex.Item>
            </Flex.Row>
          </Flex.Row>
        )}
        {/* Actions */}
        {!driveItemsSelected && (
          <Flex.Row justifyContent="end" alignItems="center">
            {isDriveItem(previewModal.itemList[0]) && (
              <Flex.Item flexGrow={0}>
                <Button type="link" href={preview?.optional?.driveItemWebUrl}>
                  {t('documents:documentDetails.actions.openInWebBrowser')}
                </Button>
              </Flex.Item>
            )}
            {preview?.optional?.localDriveItemUrl?.includes(':ofe|ofc|u|') && (
              <Flex.Item flexGrow={0}>
                <Button
                  type="link"
                  href={preview?.optional?.localDriveItemUrl ?? undefined}
                >
                  {t('documents:documentDetails.actions.openLocally')}
                </Button>
              </Flex.Item>
            )}
            <Flex.Item flexGrow={0}>
              <Button type="link" onClick={() => onItemDownload(preview?.item)}>
                {t('documents:documentDetails.actions.download')}
              </Button>
            </Flex.Item>
            {/* TODO: Share Funktion kommt später erst
            {isDriveItem(preview) && (
              <Flex.Item flexGrow={0}>
                <Button
                  type="link"
                  className={classes.actionButton}
                  onClick={() =>
                    onItemShare(preview?.item as DriveItem)
                  }
                >
                  {t('documents:documentDetails.actions.share')}
                </Button>
              </Flex.Item>
            )} */}
            <Flex.Item flexGrow={0}>
              <Button
                type="default"
                onClick={(e) => onClose(e)}
                iconProp={['fal', 'xmark']}
              />
            </Flex.Item>
          </Flex.Row>
        )}
      </Flex.Row>
    </Flex.Row>
  );
};

export default PreviewModalNavBar;
