import React, { useCallback, useRef, useState } from 'react';
import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';
import { Typography, Drawer, Switch, Tabs } from 'antd';
import { AccountingOverviewPage } from './AccountingOverviewPage';
import { CreateInvoice } from '../../../models/Accounting';
import { ProjectId, OfficeId, InvoiceTypes } from '../../../models/Types';
import { useTranslation } from 'react-i18next';
import { apiCreateInvoice } from '../api';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import FilterContextProvider from '../../../components/Filter/FilterContextProvider';
import {
  DefaultSearchParameterItem,
  FilterBar,
} from '../../../components/Filter/FilterBar';
import InvoicesTableNew, {
  InvoiceTableNewRef,
  InvoicesSearchResult,
  InvoicesSearchResultCalculatedData,
  SearchResultInvoicesPage,
} from './InvoicesTableNew';
import InvoiceDetailsNew from './InvoiceDetailsNew';
import InvoiceFormNew from './InvoiceFormNew';
import { useSelector } from 'react-redux';
import { getUserMe } from '../../../apps/main/rootReducer';
import useOfficesContext from '../../companies/hooks/useOfficesContext';

const panelWidth = 600;

const useStyles = makePrioStyles((theme) => ({
  root: {
    flex: 1,
    overflowX: 'scroll',
    backgroundColor: theme.old.palette.backgroundPalette.sub,
  },
  filterTabs: {
    '& .ant-tabs-tab': {
      paddingTop: 0,
    },
    '& .ant-tabs-content': {
      height: '100%',
    },
    overflow: 'visible',
    flex: 1,
  },
  cardWidget: {
    boxShadow: theme.old.palette.boxShadow.regular,
  },
  content: {
    flex: 1,
    padding: theme.old.spacing.defaultPadding,
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    background: theme.colors.application.background.default,
  },
  drawer: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    height: '100%',
  },
  drawerContent: {
    height: '100%',
    overflow: 'hidden',
  },
  closeButton: {
    position: 'absolute',
    top: theme.old.spacing.defaultPadding,
    right: theme.old.spacing.defaultPadding,
    background: 'transparent',
    color: theme.old.palette.primaryColor,
  },
  filterBar: {
    marginBottom: theme.spacing.regular,
  },
}));

interface InvoicesManagementProps {
  type: InvoiceTypes;
  createInvoiceActive: boolean;
  onClose: VoidFunction;
  projectId?: ProjectId;
  officeId?: OfficeId;
}

export const InvoicesManagement: React.FC<InvoicesManagementProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    type,
    createInvoiceActive,
    onClose: onNewInvoiceClose,
    projectId,
    officeId,
  } = props;
  const { t } = useTranslation();
  const invoicesRef = useRef<InvoiceTableNewRef>(null);
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { getOfficeById } = useOfficesContext();
  const userMe = useSelector(getUserMe);
  const userMeOfficeRoles = userMe.prioData.officeRoles;
  const userMeHasOfficeControllerRole: boolean =
    !!userMeOfficeRoles[officeId]?.find(
      (role) => !!(role === 'officeController')
    ) || false;

  const [showPayments, setShowPayments] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [selectedInvoice, setSelectedInvoice] =
    useState<SearchResultInvoicesPage | null>();

  const customDefaultSearchParameters: DefaultSearchParameterItem[] = officeId
    ? [
        {
          parameterName:
            type === 'incomingInvoices'
              ? 'Data.RecipientCompanyId'
              : 'Data.BillerCompanyId',

          defaultValue: getOfficeById(officeId).companyId,
          defaultMethod: 'eq',
        },
      ]
    : [];
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleClose = useCallback(() => {
    setSelectedInvoice(null);
    onNewInvoiceClose();
  }, [onNewInvoiceClose]);

  const onInvoiceSelect = useCallback(
    (invoice: SearchResultInvoicesPage) => {
      onNewInvoiceClose();
      setSelectedInvoice(invoice);
    },
    [onNewInvoiceClose]
  );

  const createInvoice = (newInvoice: CreateInvoice) => {
    setIsSaving(true);
    const _invoice = {
      data: {
        ...newInvoice,
        invoiceId: 'new',
        status: null,
        netSum: null,
        grossSum: null,
        invoicePayments: [],
        isArchived: false,
      },
    } as SearchResultInvoicesPage;

    invoicesRef.current?.optimisticWrite([
      {
        data: _invoice.data,
        method: 'add',
        callback: async () => {
          const { result, data } = await apiCreateInvoice(
            newInvoice,
            userMeHasOfficeControllerRole ? officeId : undefined,
            projectId
          );
          if (result.ok) {
            const _netSum = data.invoicePositions.reduce(
              (sum, payment) =>
                sum + payment.pricePerUnit.value * payment.amount,
              0
            );

            const _grossSum = data.invoicePositions.reduce(
              (sum, payment) =>
                sum +
                ((payment.pricePerUnit.value * (100 + payment.vat)) / 100) *
                  payment.amount,
              0
            );

            const calculated: InvoicesSearchResultCalculatedData = {
              currency: data?.invoicePositions[0]?.pricePerUnit?.isoCode,
              netTotalSum: _netSum ?? undefined,
              outstandingBalance: _grossSum * -1 ?? undefined,
              grossTotalSum: _grossSum ?? undefined,
              paidBalance: _grossSum ? 0 : undefined,
            };

            const _invoice = {
              data: data,
              calculated: calculated,
            } as SearchResultInvoicesPage;

            return {
              result,
              data: _invoice,
            };
          } else {
            return { result, data: null };
          }
        },
      },
    ]);
    setIsSaving(false);
    handleClose();
  };

  const handleShowPaymentsChange = (checked: boolean) => {
    setShowPayments(checked);
  };

  const parseInvoiceTypes = (type: InvoiceTypes) => {
    switch (type) {
      case 'outgoingInvoices':
        return 'outgoingInvoice';
      case 'incomingInvoices':
        return 'incomingInvoice';
      default:
        return undefined;
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Row className={classes.root}>
      <FilterContextProvider<
        InvoicesSearchResult,
        InvoicesSearchResultCalculatedData
      >
        searchType={type}
        equalityFunction={({ data: a }, { data: b }) =>
          a?.invoiceId === b?.invoiceId
        }
      >
        <Flex.Column
          className={classes.content}
          childrenGap={theme.old.spacing.unit(2)}
        >
          <FilterBar
            className={classes.filterBar}
            customDefaultSearchParameters={customDefaultSearchParameters}
          />

          <Tabs
            defaultActiveKey="invoices"
            className={classes.filterTabs}
            tabBarExtraContent={
              <Flex.Row
                alignItems={'end'}
                childrenGap={theme.old.spacing.baseSpacing}
              >
                <Switch
                  checked={showPayments}
                  onChange={handleShowPaymentsChange}
                />
                <Flex.Item>{t('common:filter.label.showPayments')}</Flex.Item>
              </Flex.Row>
            }
          >
            <Tabs.TabPane tab={t('accounting:tabs.invoices')} key="invoices">
              <InvoicesTableNew
                ref={invoicesRef}
                type={type}
                onRowClick={onInvoiceSelect}
                showPayments={showPayments}
                projectId={projectId}
              />
            </Tabs.TabPane>
            {type !== 'incomingInvoices' && (
              <Tabs.TabPane tab={t('accounting:tabs.summary')} key="summary">
                <AccountingOverviewPage
                  projectId={projectId}
                  officeId={officeId}
                />
              </Tabs.TabPane>
            )}
          </Tabs>
        </Flex.Column>
        <Drawer
          visible={!!selectedInvoice || createInvoiceActive}
          width={panelWidth}
          onClose={handleClose}
          className={classes.drawer}
          destroyOnClose
        >
          {createInvoiceActive && (
            <Flex.Column className={classes.drawerContent}>
              <Typography.Title>
                {t('accounting:navigationBar.createInvoice')}
              </Typography.Title>
              <InvoiceFormNew
                actionLabel={t('common:save')}
                onFinish={createInvoice}
                isoCode="EUR"
                disableForm={isSaving}
                projectId={projectId}
                officeId={officeId}
                invoiceType={parseInvoiceTypes(type)}
              />
            </Flex.Column>
          )}
          {!createInvoiceActive && selectedInvoice && (
            <Flex.Column
              childrenGap={theme.old.spacing.unit(5)}
              className={classes.drawerContent}
            >
              <InvoiceDetailsNew
                invoice={selectedInvoice}
                reloadInvoices={() => invoicesRef.current?.fetchInvoices()}
                officeId={officeId}
                projectId={projectId}
                handleDrawerClose={handleClose}
                userMeHasOfficeControllerRole={userMeHasOfficeControllerRole}
              />
            </Flex.Column>
          )}
        </Drawer>
      </FilterContextProvider>
    </Flex.Row>
  );
};

export default InvoicesManagement;
