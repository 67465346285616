import React, {
  MutableRefObject,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Dropdown, Menu, notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../../theme/utils';
import { MailFolderId, ProjectId } from '../../../../models/Types';
import { MailFolder } from '../../../../models/MailFolder';
import { SpecialMailFolders } from '../../actions/types';
import { Message } from '../../../../models/Message';
import Flex from '../../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { setMailListNavigationState } from '../../actions/actionControllers/mailNavigationActionController';
import { DND_TYPE_EMAIL, DND_TYPE_EMAIL_FOLDER } from '../../../../dnd/types';
import { useDrag, useDrop } from 'react-dnd';
import {
  addOpenMailFolder,
  fetchMailFolders,
} from '../../actions/actionControllers/mailFoldersController';
import { apiMoveMailFolder } from '../../api';
import { moveMessage } from '../../actions/actionControllers/messageActionController';
import { useDispatch, useSelector } from 'react-redux';
import { IconName } from '@fortawesome/pro-light-svg-icons';
import { getDisplayName } from '../../util';
import { useTheme } from 'theming';
import { PrioTheme } from '../../../../theme/types';
import { fetchMessagesSagaAction } from '../../actions/sagas';
import { getMailSettings } from '../../../../apps/main/rootReducer';
import { UltimateFilterSearchRef } from '../../../../components/UltimateFilter/createUltimateFilter';
import { MailSearchFormModel } from '../../../../models/MailSearch';
import { MailFilterContext } from '../MailSearch/MailFilterContext';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {},
  menuItem: {
    '&.ant-menu-item': {
      paddingRight: 28,
    },
    '&.ant-menu-item-selected': {
      color: '#000',
    },
    borderRadius: 2,
  },
  subMenuItem: {
    '& a': {
      color: '#000',
    },
    '& a:hover': {
      color: theme.old.palette.primaryColor,
      backgroundColor: theme.old.palette.backgroundPalette.hover.sub,
    },
    '& .ant-menu-submenu-title:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.sub,
    },
    '& .ant-menu-submenu-title:hover > span > a > div > .svg-inline--fa': {
      color: theme.old.palette.primaryColor,
    },
    '& .ant-menu-submenu-arrow': {
      marginTop: '2px',
    },
  },
  subMenuItemCompressedView: {
    '& .ant-menu-submenu-arrow': {
      right: '6px',
    },
  },
  subMenuItemMiddleView: {
    '& .ant-menu-submenu-arrow': {
      right: '6px',
    },
  },

  selectedSubMenu: {
    '& .ant-menu-item-selected': {
      backgroundColor: 'transparent!important',
    },
    '&.ant-menu-submenu > div': {
      backgroundColor: theme.old.palette.backgroundPalette.active.sub,
      color: theme.old.palette.primaryColor,
      fontWeight: 600,
      '& a': {
        color: `${theme.old.palette.primaryColor}!important`,
      },
      '& > span > a > div > .svg-inline--fa': {
        color: theme.old.palette.primaryColor,
      },
    },
    '&.ant-menu-submenu-selected > .ant-menu-submenu-title::before': {
      content: '""',
      position: 'absolute',
      width: 2,
      height: '100%',
      left: 0,
      backgroundColor: theme.old.palette.primaryColor,
    },
    '&.ant-menu-submenu-selected': {
      color: '#000',
    },
    '&.ant-menu-submenu-selected > .ant-menu-submenu-title:hover': {
      backgroundColor: theme.old.palette.backgroundPalette.hover.sub,
    },
  },
  selectedMenuItem: {
    color: `${theme.old.palette.primaryColor}!important`,
    '& a': {
      color: `${theme.old.palette.primaryColor}!important`,
    },
    '&.ant-menu-item-selected': {
      color: theme.old.palette.primaryColor,
    },
    '&.ant-menu-item-selected a': {
      color: `${theme.old.palette.primaryColor}!important`,
    },
  },
  collapsedMenuItem: {
    width: 48,
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
  button: {
    height: 'auto',
    backgroundColor: 'transparent',
    zIndex: 1,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    '& > .prio-button-icon': {
      color: theme.old.typography.colors.base,
    },
    '&:hover > .prio-button-icon': {
      color: theme.old.typography.colors.base,
    },
  },
  activeMenuItem: {
    '&.ant-menu-item-only-child': {
      outline: `1px dashed ${theme.old.palette.primaryColor}`,
      outlineWidth: 2,
      outlineOffset: '-2px',
    },
    '& > div': {
      outline: `1px dashed ${theme.old.palette.primaryColor}`,
      outlineWidth: 2,
      outlineOffset: '-2px',
    },
    '&.ant-menu-item > a': {
      color: theme.old.typography.colors.inverted,
    },
  },
  unreadItemCount: {
    fontSize: theme.old.components.mailFolderItem.fontSize - 1,
    color: theme.old.typography.colors.muted,
  },
  invisibleIcon: {
    visibility: 'hidden',
  },
  folderName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  hoverMenu: {
    '& > span > a > div > button': {
      visibility: 'hidden',
    },
    '&:hover': {
      '& > span > a > div > button': {
        visibility: 'visible',
      },
    },
  },
}));

const isDescendantOf: (item: MailFolder, of: MailFolder) => boolean = (
  item: MailFolder,
  of: MailFolder
) => {
  if (!of?.childFolder) return false;
  for (const childFolder of of.childFolder) {
    if (childFolder.id === item.id) return true;
    if (childFolder.childFolder?.length > 0) {
      const isDescendant = isDescendantOf(item, childFolder);
      if (isDescendant) return true;
    }
  }
  return false;
};

const getIconName: (
  mailFolderId: MailFolderId,
  specialMailFolders: SpecialMailFolders
) => IconName = (mailFolderId, specialMailFolders) => {
  if (!mailFolderId || !specialMailFolders) return 'folder';
  switch (mailFolderId) {
    case specialMailFolders['inboxFolder']?.id:
      return 'inbox';
    case specialMailFolders['sendFolder']?.id:
      return 'paper-plane';
    case specialMailFolders['draftFolder']?.id:
      return 'pen';
    case specialMailFolders['deletedFolder']?.id:
      return 'trash';
    default:
      return 'folder';
  }
};

interface MailFolderMenuItemProps {
  projectId: ProjectId;
  mailFolder: MailFolder;
  key: MailFolderId;
  showNewFolderModal: (parentFolderId?: MailFolderId) => void;
  onDelete: (mailFolderId: MailFolderId, mailFolderName: string) => void;
  specialMailFolders: SpecialMailFolders;
  pathPrefix?: string;
  collapsed?: boolean;
  selectedMailFolderId?: string;
  clearSelectedMessages?: (messageIds: Message[]) => void;
}

export const MailFolderMenuItem: React.FC<MailFolderMenuItemProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const {
    mailFolder,
    projectId,
    key,
    showNewFolderModal,
    onDelete,
    specialMailFolders,
    pathPrefix,
    collapsed,
    selectedMailFolderId,
    clearSelectedMessages,
    ...rest
  } = props;

  const classes = useStyles();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const iconName = getIconName(mailFolder?.id, specialMailFolders);
  const displayName = getDisplayName(mailFolder, specialMailFolders, t);

  const mailFolderId = useMemo(() => mailFolder?.id, [mailFolder]);

  const isSubMenu = useMemo(() => !!mailFolder.childFolder, [mailFolder]);

  const [timer, setTimer] = useState(null);

  const mailSettings = useSelector(getMailSettings);

  const ultimateFilterRef =
    useContext<MutableRefObject<UltimateFilterSearchRef<MailSearchFormModel>>>(
      MailFilterContext
    );
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const moveMail = (
    message: Message,
    selectedMessages: Message[],
    destinationId: MailFolderId,
    originId: MailFolderId,
    inboxFolderId: MailFolderId
  ) => {
    const selectedMessageIds = selectedMessages.map(
      (selectedMessage) => selectedMessage.id
    );
    const messages = selectedMessageIds.includes(message.id)
      ? selectedMessages
      : [message];
    dispatch(
      moveMessage(projectId, messages, destinationId, originId, inboxFolderId)
    );
    dispatch(fetchMessagesSagaAction(projectId, originId));
  };

  const moveFolder = async (
    mailFolderId: MailFolderId,
    destinationId: MailFolderId
  ) => {
    const { result } = await apiMoveMailFolder(
      projectId,
      mailFolderId,
      destinationId
    );
    if (result.status >= 200 && result.status < 300) {
      dispatch(fetchMailFolders(projectId));
    } else {
      notification.open({
        message: t('common:error'),
        description: t('mail:errorMessages.messages.moveMailFolderError'),
      });
    }
  };

  const handleDelete = () => {
    onDelete(mailFolder.id, mailFolder.displayName);
  };
  //#endregion

  //#region ------------------------------ Drag & Drop
  const [{ canDrop, isOver }, dropRef] = useDrop({
    accept: [DND_TYPE_EMAIL, DND_TYPE_EMAIL_FOLDER],
    drop: (item, monitor) => {
      const type = monitor.getItemType();
      switch (type) {
        case DND_TYPE_EMAIL: {
          if (monitor.didDrop()) {
            return undefined;
          }
          if ((item as any).isPersonal && projectId !== 'me') {
            return {
              destinationMailFolder: mailFolder.id,
              message: (item as any).message as Message,
              projectId: projectId,
            };
          } else {
            if (clearSelectedMessages) {
              clearSelectedMessages([]);
            }
            const originId = ((item as any).message as Message).parentFolderId;
            moveMail(
              (item as any).message as Message,
              (item as any).selectedMessages as Message[],
              mailFolder.id === specialMailFolders['inboxFolder']?.id
                ? 'inbox'
                : mailFolder.id,
              specialMailFolders &&
                originId === specialMailFolders['inboxFolder']?.id
                ? 'inbox'
                : originId,
              specialMailFolders['inboxFolder']?.id
            );
            return undefined;
          }
        }
        case DND_TYPE_EMAIL_FOLDER: {
          if (monitor.didDrop()) {
            return undefined;
          }
          moveFolder(
            ((item as any).mailFolder as MailFolder).id,
            mailFolder.id
          );
          break;
        }
        default:
      }
      return undefined;
    },
    canDrop: (item) => {
      const target = mailFolder;
      const source = (item as any).mailFolder as MailFolder;
      return source?.id !== target.id && !isDescendantOf(target, source);
    },
    collect: (monitor: any) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [{ opacity }, dragRef] = useDrag({
    type: DND_TYPE_EMAIL_FOLDER,
    item: { mailFolder },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.5 : 1,
    }),
  });

  const isActive = canDrop && isOver;
  //#endregion

  //#region ------------------------------ Components
  const menu = (
    <Menu>
      <Menu.Item key="delete" onClick={handleDelete}>
        {t('common:delete')}
      </Menu.Item>
      <Menu.Item
        key="newFolder"
        onClick={() => showNewFolderModal(mailFolder.id)}
      >
        {t('mail:mailNav.newFolder')}
      </Menu.Item>
    </Menu>
  );

  const link = (
    <Link
      to={`${pathPrefix ?? ''}../${
        specialMailFolders &&
        mailFolder.id === specialMailFolders['inboxFolder']?.id
          ? 'inbox'
          : mailFolder.id
      }`}
      onClick={(e) => {
        e.stopPropagation();
        dispatch(setMailListNavigationState(null, projectId));
        ultimateFilterRef?.current?.setFilterValue({}, true);
      }}
      ref={dropRef}
    >
      <Flex.Row alignItems="center">
        <FontAwesomeIcon
          fixedWidth
          icon={['fal', iconName]}
          className={classNames({
            [classes.invisibleIcon]: iconName === 'folder',
            [classes.icon]: !collapsed,
          })}
        />

        <Flex.Row
          flex={1}
          className={classes.folderName}
          childrenGap={theme.old.spacing.baseSpacing}
        >
          <span
            style={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}
          >
            {displayName}
          </span>
          {mailFolder.unreadItemCount && !collapsed ? (
            <span className={classes.unreadItemCount}>
              {mailFolder.unreadItemCount}
            </span>
          ) : null}
        </Flex.Row>

        {!collapsed && (
          <Dropdown overlay={menu} trigger={['click']} placement="bottomRight">
            <Button
              iconProp={['fal', 'ellipsis-v']}
              onClick={(e) => e.stopPropagation()}
              type="link"
              className={classes.button}
            />
          </Dropdown>
        )}
      </Flex.Row>
    </Link>
  );
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (isSubMenu && !collapsed && isOver) {
      setTimer(
        setTimeout(() => {
          dispatch(addOpenMailFolder(projectId, mailFolderId));
        }, 1000)
      );
    }
  }, [isOver, projectId, mailFolderId, isSubMenu, collapsed, dispatch]);

  useEffect(() => {
    if (!isOver && timer) {
      clearTimeout(timer);
    }
  }, [isOver, timer]);
  //#endregion

  return (
    <div
      className={classNames(collapsed ? classes.collapsedMenuItem : '')}
      key={key}
    >
      <div ref={dragRef} style={{ opacity }} title={displayName}>
        {mailFolder.childFolder && !collapsed ? (
          <Menu.SubMenu
            key={key}
            title={link}
            className={classNames(classes.subMenuItem, classes.hoverMenu, {
              [classes.subMenuItemCompressedView]:
                mailSettings.mailListSpacing === 'tight',
              [classes.subMenuItemMiddleView]:
                mailSettings.mailListSpacing === 'middle',

              [classes.activeMenuItem]: isActive,
              [classes.selectedSubMenu]: selectedMailFolderId === mailFolder.id,
            })}
            style={{
              ...(collapsed
                ? {}
                : {
                    fontSize: theme.old.components.mailFolderItem.fontSize,
                    lineHeight: theme.old.components.mailFolderItem.lineHeight,
                  }),
              padding: 0,
            }}
            {...rest}
          >
            {mailFolder.childFolder?.map((folder: MailFolder) => (
              <MailFolderMenuItem
                key={folder.id}
                mailFolder={folder}
                projectId={projectId}
                showNewFolderModal={showNewFolderModal}
                onDelete={onDelete}
                specialMailFolders={specialMailFolders}
                selectedMailFolderId={selectedMailFolderId}
                clearSelectedMessages={clearSelectedMessages}
                pathPrefix={pathPrefix}
              />
            ))}
          </Menu.SubMenu>
        ) : (
          <Menu.Item
            key={key}
            className={classNames(classes.menuItem, classes.hoverMenu, {
              [classes.activeMenuItem]: isActive,
              [classes.selectedMenuItem]:
                selectedMailFolderId === mailFolder.id,
            })}
            title={
              <Flex.Row alignItems="center">
                <FontAwesomeIcon
                  fixedWidth
                  icon={['fal', iconName]}
                  className={classNames(classes.icon, {
                    [classes.invisibleIcon]: iconName === 'folder',
                  })}
                />
                <Flex.Item flex={1} className={classes.folderName}>
                  {displayName}
                  {mailFolder.unreadItemCount ? (
                    <span> {mailFolder.unreadItemCount}</span>
                  ) : null}
                </Flex.Item>
              </Flex.Row>
            }
            style={{
              ...(collapsed
                ? {}
                : {
                    fontSize: theme.old.components.mailFolderItem.fontSize,
                    lineHeight: theme.old.components.mailFolderItem.lineHeight,
                  }),
            }}
            {...rest}
          >
            {link}
          </Menu.Item>
        )}
      </div>
    </div>
  );
};

export default MailFolderMenuItem;
