import React from 'react';
import { useParams } from 'react-router-dom';
import Flex from '../../../components/Flex';
import ProjectSettingsNavigation from './ProjectSettingsNavigation';
import EditProject from './EditProject';
import ExternalProjectContactsTablePage from './ExternalProjectContactsTablePage';
import ProjectDistributionListPage from './ProjectDistributionListPage';
import HourlyRatesPage from './HourlyRatesPage';
import AddInsPage from './AddInsPage';
import MailSettingsPage from './MailSettingsPage';
import DocumentsSettingsPage from './DocumentsSettingsPage';
import AdministrationPage from './AdministrationPage';
import { getUserMe, RootReducerState } from '../../../apps/main/rootReducer';
import { User } from '../../../models/User';
import { useSelector } from 'react-redux';
import { Result } from 'antd';
import { useTranslation } from 'react-i18next';
import { ProjectId } from '../../../models/Types';
import { makePrioStyles } from '../../../theme/utils';
import ProjectPhaseSettings from './ProjectPhaseSettings';
import InternalProjectContactsTablePage from './InternalProjectContactsTablePage';

const useStyles = makePrioStyles((theme) => ({
  wrapper: {
    backgroundColor: theme.old.palette.backgroundPalette.sub,
    padding: theme.old.spacing.defaultPadding,
    height: '100%',
    overflowY: 'hidden',
  },
  root: {
    position: 'relative',
    height: '100%',
  },
  body: {
    overflow: 'hidden',
  },
  page: {
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  newPage: {
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: `${theme.old.spacing.defaultPadding}px ${
      theme.old.spacing.defaultPadding * 2
    }px`,

    backgroundColor: theme.colors.application.background.light,
  },
  table: {
    height: '100%',
    overflow: 'hidden',
  },
}));

interface ProjectSettingsProps {
  projectId: ProjectId;
}

export const ProjectSettings: React.FC<ProjectSettingsProps> = (props) => {
  const { projectId } = props;
  const { selectedList } = useParams();
  const classes = useStyles();

  const userMe = useSelector<RootReducerState, User>(getUserMe);

  const { t } = useTranslation();

  let component: React.ReactNode;
  switch (selectedList) {
    case 'edit':
      component = (
        <Flex.Item flex={1} className={classes.page}>
          <EditProject />
        </Flex.Item>
      );
      break;
    case 'projectPhase':
      component = (
        <Flex.Item flex={1} className={classes.newPage}>
          <ProjectPhaseSettings />
        </Flex.Item>
      );
      break;
    case 'project-contacts-internal':
      component = (
        <Flex.Item flex={1} className={classes.table}>
          <InternalProjectContactsTablePage projectId={projectId} />
        </Flex.Item>
      );
      break;
    case 'project-contacts-external':
      component = (
        <Flex.Item flex={1} className={classes.table}>
          <ExternalProjectContactsTablePage projectId={projectId} />
        </Flex.Item>
      );
      break;
    case 'project-distribution-list':
      component = (
        <Flex.Item flex={1} className={classes.page}>
          <ProjectDistributionListPage projectId={projectId} />
        </Flex.Item>
      );
      break;
    case 'hourly-rates':
      component = (
        <Flex.Item flex={1} className={classes.page}>
          <HourlyRatesPage projectId={projectId} />
        </Flex.Item>
      );
      break;
    case 'admin':
      if (userMe.prioData.globalRoles.includes('globalAdmin')) {
        component = (
          <Flex.Item flex={1} className={classes.page}>
            <AdministrationPage />
          </Flex.Item>
        );
      } else {
        component = (
          <Flex.Item flex={1} className={classes.page}>
            <Result
              status="500"
              title="500"
              subTitle={t('projects:errorMessages.somethingWentWrong')}
            />
          </Flex.Item>
        );
      }

      break;
    case 'addins':
      component = (
        <Flex.Item flex={1} className={classes.page}>
          <div className={classes.wrapper}>
            <AddInsPage projectId={projectId} />
          </div>
        </Flex.Item>
      );
      break;
    case 'mail':
      component = (
        <Flex.Item flex={1} className={classes.page}>
          <MailSettingsPage projectId={projectId} />
        </Flex.Item>
      );
      break;
    case 'documents':
      component = (
        <Flex.Item flex={1} className={classes.page}>
          <DocumentsSettingsPage projectId={projectId} />
        </Flex.Item>
      );
      break;
    default:
  }

  return (
    <Flex.Column className={classes.root}>
      <Flex.Row flex={1} className={classes.body}>
        <ProjectSettingsNavigation projectId={projectId} />
        {component}
      </Flex.Row>
    </Flex.Column>
  );
};

export default ProjectSettings;
