import { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Dropdown, Menu, notification } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import { AbsenceProposal } from '../../../models/AbsenceProposal';
import { formatNumber } from '../../../util';
import ContactText from '../../contacts/components/ContactText';
import moment from 'moment';
import Flex from '../../../components/Flex';
import { PrioTheme } from '../../../theme/types';
import useContactsContext from '../../contacts/hooks/useContactsProvider';
import useFilterContext, {
  useFilterContextDataConvertion,
} from '../../../components/Filter/hooks/useFilterContext';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import FilterResultNoItemsScreen from '../../../components/Filter/FilterResultNoItemsScreen';
import {
  apiAcceptAbsenceProposal,
  apiAcceptOfficeAbsenceProposal,
  apiDeclineAbsenceProposal,
  apiDeclineOfficeAbsenceProposal,
} from '../api';
import { OfficeId } from '../../../models/Types';
import { VirtualListItemOnRowProps } from '@prio365/prio365-react-library/lib/VirtualList/components/VirtualListItem';
import { useExportAbsenceProposalsToCsv } from '../export';
import useOfficesContext from '../../companies/hooks/useOfficesContext';
import { useQueryClient } from '@tanstack/react-query';
import FilterContextVirtualTable from '../../../components/Filter/FilterContextVirtualTable';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {},
  row: {
    cursor: 'pointer',
    position: 'relative',
    '&::before': {
      position: 'absolute',
      content: '""',
      top: 2,
      bottom: 2,
      left: 0,
      width: 5,
      backgroundColor: 'transparent',
    },
  },
  menuButton: {
    backgroundColor: 'transparent',
    height: '100%',
    '& > .prio-button-icon': {
      color: theme.old.typography.colors.base,
    },
    '&:hover': {
      backgroundColor: theme.old.components.table.menuButton.backgroundColor,
      color: theme.old.components.table.menuButton.color,
      '& > .prio-button-icon': {
        color: theme.old.typography.colors.base,
      },
    },
  },
  stateGreen: {
    '&::before': {
      backgroundColor: theme.old.palette.chromaticPalette.green,
    },
  },
  stateYellow: {
    '&::before': {
      backgroundColor: theme.old.palette.chromaticPalette.yellow,
    },
  },
  stateRed: {
    '&::before': {
      backgroundColor: theme.old.palette.chromaticPalette.red,
    },
  },
  stateGrey: {
    '&::before': {
      backgroundColor: theme.old.palette.chromaticPalette.grey,
    },
  },
  fullHeight: {
    height: '100%',
    overflow: 'hidden',
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
  },
  textCentered: {
    textAlign: 'center',
  },
}));

interface AbsenceManagementTableProps {
  className?: string;
  onRowClick?: (entry: AbsenceProposal) => void;
  loading?: boolean;
  officeId: OfficeId;
}

export const AbsenceManagementTable = (props: AbsenceManagementTableProps) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();

  const { className, onRowClick, officeId } = props;
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { getContactById } = useContactsContext();
  const { getOfficeById } = useOfficesContext();

  const { data, isLoading, optimisticWrite } =
    useFilterContext<AbsenceProposal>();

  const absenceProposals = useFilterContextDataConvertion(data);

  const [menuSubmitting, setMenuSubmitting] = useState<boolean>(false);

  const [selectedAbsenceProposals, setSelectedAbsenceProposals] = useState<
    AbsenceProposal[]
  >([]);

  const onSelectionChange = (items: AbsenceProposal[]) => {
    setSelectedAbsenceProposals(items);
  };
  //#endregion

  //#region ------------------------------ Methods / Handlers

  const getClassNameTableRow = useCallback(
    (item: AbsenceProposal) => {
      switch (item.absenceState) {
        case 'accepted':
          return classNames(classes.row, classes.stateGreen);
        case 'declined':
          return classNames(classes.row, classes.stateRed);
        case 'planned':
          return classNames(classes.row, classes.stateGrey);
        case 'requested':
          return classNames(classes.row, classes.stateYellow);
        case 'revokeAccepted':
          return classNames(classes.row, classes.stateRed);
        case 'revokeDeclined':
          return classNames(classes.row, classes.stateGreen);
        case 'revokeRequested':
          return classNames(classes.row, classes.stateGrey);
        default: {
          return classes.row;
        }
      }
    },
    [classes]
  );

  const handleOnRowClick: (item: AbsenceProposal) => VirtualListItemOnRowProps =
    useCallback(
      (item) => {
        return {
          onClick: (e) => {
            onRowClick(item);
          },
          className: getClassNameTableRow(item),
        };
      },
      [onRowClick, getClassNameTableRow]
    );

  const declineProposal = useCallback(
    async (absenceProposal: AbsenceProposal) => {
      const optimisticAbsenceProposal: AbsenceProposal = {
        ...absenceProposal,
        absenceState:
          absenceProposal.absenceState === 'revokeRequested'
            ? 'revokeDeclined'
            : 'declined',
      };

      optimisticWrite(
        [
          {
            data: optimisticAbsenceProposal,
            method: 'update',
          },
        ],
        async () => {
          let fetchedAbsenceProposal: AbsenceProposal = null;
          const { result } = officeId
            ? await apiDeclineOfficeAbsenceProposal(
                absenceProposal.absenceProposalId,
                // temporär aus absenceProposal, damit aus anderem office bestätigt werden kann
                // officeId
                absenceProposal.officeId
              )
            : await apiDeclineAbsenceProposal(
                absenceProposal.absenceProposalId
              );
          if (result.status >= 200 && result.status < 300) {
            fetchedAbsenceProposal = optimisticAbsenceProposal;
          } else {
            notification.open({
              message: t('common:error'),
              description:
                absenceProposal.absenceState === 'revokeRequested'
                  ? t(
                      'absences:errorMessages.declineAbsenceProposalRevokeError'
                    )
                  : t('absences:errorMessages.declineAbsenceProposalError'),
            });
          }
          return {
            result,
            data: [
              {
                data: fetchedAbsenceProposal,
                calculated: undefined,
              },
            ],
          };
        }
      );
    },
    [officeId, t, optimisticWrite]
  );

  const acceptProposal = useCallback(
    async (absenceProposal: AbsenceProposal) => {
      const optimisticAbsenceProposal: AbsenceProposal = {
        ...absenceProposal,
        absenceState:
          absenceProposal.absenceState === 'revokeRequested'
            ? 'revokeAccepted'
            : 'accepted',
      };

      optimisticWrite(
        [
          {
            data: optimisticAbsenceProposal,
            method: 'update',
          },
        ],
        async () => {
          let fetchedAbsenceProposal: AbsenceProposal = null;
          const { result } = officeId
            ? await apiAcceptOfficeAbsenceProposal(
                absenceProposal.absenceProposalId,
                // temporär aus absenceProposal, damit aus anderem office bestätigt werden kann
                // officeId
                absenceProposal.officeId
              )
            : await apiAcceptAbsenceProposal(absenceProposal.absenceProposalId);
          if (result.status >= 200 && result.status < 300) {
            fetchedAbsenceProposal = optimisticAbsenceProposal;
          } else {
            notification.open({
              message: t('common:error'),
              description:
                absenceProposal.absenceState === 'revokeRequested'
                  ? t('absences:errorMessages.acceptAbsenceProposalRevokeError')
                  : t('absences:errorMessages.acceptAbsenceProposalError'),
            });
          }
          return {
            result,
            data: [
              {
                data: fetchedAbsenceProposal,
                calculated: undefined,
              },
            ],
          };
        }
      );
    },
    [officeId, t, optimisticWrite]
  );

  const exportToCsv = useExportAbsenceProposalsToCsv();
  //#endregion

  //#region ------------------------------ Components
  const menu = useCallback(
    (entry: AbsenceProposal) => (
      <Menu>
        <Menu.Item
          disabled={
            menuSubmitting ||
            entry.absenceState === 'accepted' ||
            entry.absenceState === 'declined' ||
            entry.absenceState === 'revokeAccepted' ||
            entry.absenceState === 'revokeDeclined'
          }
          onClick={async (e) => {
            e.domEvent.stopPropagation();
            setMenuSubmitting(true);
            await acceptProposal(entry);
            queryClient.invalidateQueries({
              queryKey: ['absenceProposals'],
              refetchType: 'all',
            });
            setMenuSubmitting(false);
          }}
        >
          {t('absences:tableMenu.accept')}
        </Menu.Item>
        <Menu.Item
          disabled={
            menuSubmitting ||
            entry.absenceState === 'accepted' ||
            entry.absenceState === 'declined' ||
            entry.absenceState === 'revokeAccepted' ||
            entry.absenceState === 'revokeDeclined'
          }
          onClick={async (e) => {
            e.domEvent.stopPropagation();
            setMenuSubmitting(true);
            await declineProposal(entry);
            queryClient.invalidateQueries({
              queryKey: ['absenceProposals'],
              refetchType: 'all',
            });
            setMenuSubmitting(false);
          }}
        >
          {t('absences:tableMenu.decline')}
        </Menu.Item>
      </Menu>
    ),
    [menuSubmitting, t, acceptProposal, declineProposal, queryClient]
  );
  //#endregion

  //#region ------------------------------ Columns
  const columns: Column<AbsenceProposal>[] = useMemo(
    () => [
      {
        id: 'applicantId',
        width: officeId ? 20 : 25,
        title: t('absences:absenceManagement.table.columnTitle.applicantId'),
        accessor: 'applicantId',
        className: classes.cell,
        alignSelf: true,
        sortingFn: (a, b) =>
          getContactById(a.applicantId.toLowerCase())?.lastName?.localeCompare(
            getContactById(b.applicantId.toLowerCase())?.lastName
          ),
        Cell: ({ originalData }) => (
          <Flex.Row childrenGap={10} className={classes.fullHeight}>
            <Flex.Row
              alignItems="center"
              flex={1}
              className={classes.fullHeight}
            >
              <ContactText contactId={originalData.applicantId.toLowerCase()} />
            </Flex.Row>
          </Flex.Row>
        ),
      },
      ...((officeId
        ? [
            {
              id: 'officeId',
              accessor: 'officeId',
              title: t('absences:absenceManagement.table.columnTitle.officeId'),
              sortingFn: (rowA, rowB) =>
                getOfficeById(rowA.officeId)?.name?.localeCompare(
                  getOfficeById(rowB.officeId)?.name
                ),
              className: classes.cell,
              width: 15,
              alignSelf: true,
              Cell: ({ originalData: { officeId } }) => (
                <>{officeId && getOfficeById(officeId)?.name}</>
              ),
            },
          ]
        : []) as Column<AbsenceProposal>[]),
      {
        id: 'absenceType',
        accessor: 'absenceType',
        title: t('absences:absenceManagement.table.columnTitle.absenceType'),
        className: classes.cell,
        alignSelf: true,
        sortingFn: (rowA, rowB) =>
          t(`absences:types.${rowA.absenceType}`).localeCompare(
            t(`absences:types.${rowB.absenceType}`)
          ),

        Cell: ({ originalData: { absenceType } }) => (
          <>{t(`absences:types.${absenceType}`)}</>
        ),
        width: 20,
      },
      {
        id: 'period',
        width: officeId ? 15 : 20,
        accessor: 'from',
        sortingFn: (rowA, rowB) =>
          moment(rowA.from).diff(moment(rowB.from), 'minutes'),
        title: t('absences:absenceManagement.table.columnTitle.period'),
        className: classes.cell,
        Cell: ({ originalData }) => (
          <>
            {originalData.from === originalData.to ? (
              moment(originalData.from).format('DD.MM.YYYY')
            ) : (
              <>
                {moment(originalData.from).format('DD.MM.')}
                {' - '}
                {moment(originalData.to).format('DD.MM.YYYY')}
              </>
            )}
          </>
        ),
      },
      {
        id: 'days',
        width: 10,
        accessor: 'to',
        sortingFn: (rowA, rowB) => rowA.absentWorkdays - rowB.absentWorkdays,
        title: t('absences:absenceManagement.table.columnTitle.days'),
        className: classNames(classes.cell, classes.textCentered),
        alignSelf: true,
        Cell: ({ originalData: { absentWorkdays } }) => (
          <>{formatNumber(absentWorkdays)}</>
        ),
      },
      {
        id: 'principalId',
        width: officeId ? 15 : 20,
        sortingFn: (rowA, rowB) =>
          getContactById(rowA.principalId)?.lastName?.localeCompare(
            getContactById(rowB.principalId)?.lastName
          ),
        accessor: 'principalId',
        title: t('absences:absenceManagement.table.columnTitle.principalId'),
        className: classes.cell,
        alignSelf: true,
        Cell: ({ originalData: { principalId } }) => (
          <ContactText contactId={principalId} plain />
        ),
      },
      {
        id: 'menu',
        width: 5,
        minWidth: 50,
        accessor: 'principalId',
        title: '',
        className: classes.cell,
        alignSelf: true,
        Cell: ({ originalData }) => (
          <Flex.Row justifyContent="center">
            <Dropdown
              overlay={menu(originalData)}
              trigger={['click']}
              placement="bottomRight"
            >
              <Button
                iconProp={['fal', 'ellipsis-v']}
                className={classes.menuButton}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                type="default"
              />
            </Dropdown>
          </Flex.Row>
        ),
      },
    ],
    [classes, getContactById, t, menu, officeId, getOfficeById]
  );
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <FilterContextVirtualTable<AbsenceProposal>
      id={'absence-management-table'}
      className={classNames(classes.root, className)}
      data={absenceProposals}
      columns={columns}
      onRow={handleOnRowClick}
      onSelectionChange={onSelectionChange}
      onCheckEquality={(a, b) => a.absenceProposalId === b.absenceProposalId}
      resizable="relative"
      noItemsScreen={<FilterResultNoItemsScreen />}
      loading={
        isLoading && {
          type: 'noItems',
        }
      }
      rowsAreSelectable
      actionBarButtons={[
        {
          children: t(
            'absences:absenceManagement.table.actionBarActions.exportToCSV'
          ),
          iconProp: ['fal', 'file-csv'],
          onClick: () => {
            exportToCsv(selectedAbsenceProposals);
          },
        },
      ]}
    />
  );
};

export default AbsenceManagementTable;
