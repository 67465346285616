import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { makePrioStyles } from '../../../theme/utils';
import Flex from '../../../components/Flex';

import TimelineComponent, {
  AbsenceTimelineGroup,
  TimelineEvent,
} from './absenceOverviewTimeline/TimelineComponent';
import {
  AbsenceType,
  ContactId,
  OfficeId,
  ProjectId,
} from '../../../models/Types';
import { useDispatch, useSelector } from 'react-redux';
import { AbsenceProposal } from '../../../models/AbsenceProposal';
import {
  apiFetchAbsenceProposals,
  apiFetchOfficeAbsenceProposals,
  apiFetchPublicAbsenceProposals,
} from '../api';
import { notification, Select } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import {
  getAbsenceTimelineSelectedEmployees,
  getAllInternalOffices,
  getContactsByIdState,
  getContactsByOfficeIds,
  getInternalProjectContactsData,
  getOfficesByIdState,
  getUserMe,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { Center } from '../../../components/Center';
import { groupBy } from '../../../util';
import { ContactsByIdState } from '../../contacts/reducers/contacts';
import { Contact, InternalContact } from '../../../models/Contact';
import { TFunction } from 'i18next';
import { addEmployee, removeEmployee } from '../actions/absenceTimeline';
import ProjectPicker from '../../projects/components/ProjectPicker';
import { fetchInternalProjectContacts } from '../../projects/actions';
import PrioSpinner from '../../../components/PrioSpinner';
import { Moment } from 'moment-timezone';
import moment from 'moment';
import { OfficesByIdState } from '../../companies/reducers/offices';
import { fetchInternalOffices } from '../../companies/actions';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import OfficePicker from '../../companies/components/OfficePicker';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    position: 'relative',
  },
  column: {
    height: '100%',
  },
  shadow: {
    boxShadow: theme.old.palette.boxShadow.regular,
  },
  timeline: {
    overflow: 'hidden',
    backgroundColor: 'var(--background_base)',
  },
  loadingIndicator: {
    backgroundColor: '#ffffff80',
    zIndex: 1000,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  projectPicker: {
    width: 250,
  },
  absenceTypePicker: {
    width: 250,
  },
  officePickerWrapper: {
    width: 250,
  },
  backgroundColor: {
    backgroundColor: 'var(--background_base)',
  },
}));

const groupForApplicant: (
  applicantId: string,
  index: number,
  contactsByIdState: ContactsByIdState,
  officesByIdState: OfficesByIdState
) => AbsenceTimelineGroup = (
  applicantId,
  index,
  contactsByIdState,
  officesByIdState
) => {
  const contact = contactsByIdState[applicantId?.toLowerCase()];
  const office = contact ? officesByIdState[contact.officeId] : null;

  const name = `${contact?.firstName} ${contact?.lastName}`;
  return {
    id: index,
    title: name,
    name,
    description: office?.name ?? '',
    contactId: applicantId.toLowerCase(),
  };
};

const getTimelineEventsFromAbsenceProposals: (
  absenceProposals: AbsenceProposal[],
  contactsByIdState: ContactsByIdState,
  officesByIdState: OfficesByIdState,
  t: TFunction,
  customApplicantSelection?: ContactId[]
) => [TimelineEvent[], AbsenceTimelineGroup[]] = (
  absenceProposals,
  contactsByIdState,
  officesByIdState,
  t,
  customApplicantSelection
) => {
  if (!absenceProposals) return [[], []];
  const groupedByApplicantId = groupBy<AbsenceProposal>(
    absenceProposals,
    'applicantId'
  );

  if (!!customApplicantSelection) {
    customApplicantSelection.forEach((customApplicantId) => {
      groupedByApplicantId[customApplicantId] =
        groupedByApplicantId[customApplicantId] ?? [];
    });
  }

  const timelineEventFromAbsenceProposal: (
    absenceProposal: AbsenceProposal,
    applicantId: string,
    index: number
  ) => TimelineEvent = (absenceProposal, applicantId, index) => {
    const { absenceProposalId, absenceType, from, to } = absenceProposal;
    const contact = contactsByIdState[applicantId.toLowerCase()];
    const office = contact ? officesByIdState[contact.officeId] : null;
    const managerName =
      (contact as InternalContact)?.managerId &&
      contactsByIdState[(contact as InternalContact)?.managerId]
        ? `${contactsByIdState[(contact as InternalContact)?.managerId]
            ?.firstName} ${contactsByIdState[
            (contact as InternalContact)?.managerId
          ]?.lastName}`
        : null;
    const startTimeUtc = new Date(Date.parse(from));
    const endTimeUtc = new Date(Date.parse(to));
    const timelineEvent: TimelineEvent = {
      id: absenceProposalId,
      isHoliday: false,
      group: index,
      title: `${contact?.firstName} ${contact?.lastName}, ${t(
        `absences:types.${absenceType}`
      )}`,
      start_time: new Date(
        startTimeUtc.getFullYear(),
        startTimeUtc.getMonth(),
        startTimeUtc.getDate(),
        0,
        0,
        0,
        0
      ).valueOf(),
      end_time: new Date(
        endTimeUtc.getFullYear(),
        endTimeUtc.getMonth(),
        endTimeUtc.getDate() + 1,
        0,
        0,
        0,
        0
      ).valueOf(),
      additionalInfo: {
        officeName: office?.name ?? '',
        managerName,
        contact,
      },
      absenceProposal,
    };
    return timelineEvent;
  };

  const result = Object.entries(groupedByApplicantId).reduce<
    [TimelineEvent[], AbsenceTimelineGroup[]]
  >(
    (
      [timelineEvents, groups],
      [applicantId, absenceProposalsForApplicant],
      index
    ) => [
      [
        ...timelineEvents,

        ...absenceProposalsForApplicant.map((p) =>
          timelineEventFromAbsenceProposal(p, applicantId, index)
        ),
      ],
      [
        ...groups,
        ...(contactsByIdState[applicantId.toLowerCase()]?.isArchived
          ? []
          : [
              groupForApplicant(
                applicantId,
                index,
                contactsByIdState,
                officesByIdState
              ),
            ]),
      ],
    ],
    [[], []]
  );

  return [result[0], result[1].sort((a, b) => a.name.localeCompare(b.name))];
};

type EmployeeSelection = 'custom' | 'byProject' | 'byLocation' | 'all';

interface AbsenceOverviewPageProps {
  className?: string;
  officeId?: OfficeId;
  context: 'me' | 'office' | 'global';
}

export const AbsenceOverviewPage: React.FC<AbsenceOverviewPageProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { className, officeId, context } = props;
  const { t } = useTranslation();

  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const userMe = useSelector(getUserMe);

  const [employeeSelection, setEmployeeSelection] =
    useState<EmployeeSelection>('custom');

  const contactsByIdState = useSelector(getContactsByIdState);
  const officesByIdState = useSelector(getOfficesByIdState);
  const internalProjectContactsData = useSelector(
    getInternalProjectContactsData
  );

  const [selectedOffices, setSelectedOffices] = useState<OfficeId[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<ProjectId[]>([]);

  const userMeRoles = useSelector(getUserMe).prioData;
  const isGlobalHR = userMeRoles.globalRoles.includes('globalHR');
  const [userOfficeId, setUserOfficeId] = useState<OfficeId | null>(null);

  const officeIdsWhereOfficeHR = Object.entries(userMeRoles.officeRoles)
    .filter(([officeId, roles]) => roles.includes('officeHR'))
    .map(([officeId, roles]) => officeId);

  const allInternalOfficeIds = useSelector(getAllInternalOffices).map(
    (off) => off.officeId
  );

  const allOfficesEmployees = useSelector<RootReducerState, Contact[]>(
    (state) => getContactsByOfficeIds(state, allInternalOfficeIds)
  );

  const relevantOfficesEmployees = useMemo(
    () =>
      allOfficesEmployees.filter((employee) =>
        selectedOffices?.length > 0
          ? selectedOffices?.includes(employee.officeId)
          : true
      ),
    [selectedOffices, allOfficesEmployees]
  );

  const relevantProjectContacts = selectedProjects
    .reduce(
      (contacts, projectId) => [
        ...contacts,
        ...(internalProjectContactsData[projectId]?.map((c) => c.contactId) ??
          []),
      ],
      []
    )
    .map((contactId) => contactsByIdState[contactId])
    .filter((contact) => !!contact);

  const relevantContacts: Contact[] = useMemo(
    () =>
      employeeSelection === 'byProject'
        ? relevantProjectContacts
        : employeeSelection === 'all'
        ? relevantOfficesEmployees
        : [],
    [employeeSelection, relevantProjectContacts, relevantOfficesEmployees]
  );

  const [absenceProposals, setAbsenceProposals] = useState<
    AbsenceProposal[] | null
  >();

  const customSelectedEmployees = useSelector<RootReducerState, ContactId[]>(
    (state) => getAbsenceTimelineSelectedEmployees(state, officeId)
  );

  const [customProposalsTypeFilter, setCustomProposalsTypeFilter] = useState<
    AbsenceType[]
  >([]);

  const [isAbsenceProposalsLoading, setIsAbsenceProposalsLoading] =
    useState<boolean>(false);

  const onlyOpen = false;

  const [timelineItems, groups] = useMemo(() => {
    if (!absenceProposals) return [[], []];
    const absenceProposalWithLeave = absenceProposals.filter(
      (a) =>
        a.absenceState === 'requested' ||
        a.absenceState === 'accepted' ||
        a.absenceState === 'revokeRequested' ||
        a.absenceState === 'revokeDeclined' ||
        a.absenceState === 'planned'
    );
    let selectedAbsenceProposals: AbsenceProposal[] = [];
    let customApplicantSelection;
    switch (employeeSelection) {
      case 'all':
        var _selectedAbsenceProposals: AbsenceProposal[] = [];
        if (selectedOffices.length === 0) {
          _selectedAbsenceProposals = absenceProposalWithLeave;
        } else {
          _selectedAbsenceProposals = absenceProposalWithLeave.filter((a) =>
            selectedOffices.includes(a.officeId)
          );
        }
        selectedAbsenceProposals = _selectedAbsenceProposals;
        break;
      case 'custom':
        selectedAbsenceProposals = absenceProposalWithLeave.filter((a) =>
          customSelectedEmployees.includes(a.applicantId)
        );
        customApplicantSelection = customSelectedEmployees;
        break;
      case 'byProject':
        const projectContacts = selectedProjects.reduce(
          (contacts, projectId) => [
            ...contacts,
            ...(internalProjectContactsData[projectId]?.map(
              (c) => c.contactId
            ) ?? []),
          ],
          []
        );
        selectedAbsenceProposals = absenceProposalWithLeave.filter((a) =>
          projectContacts.includes(a.applicantId)
        );
        break;
      case 'byLocation':
        selectedAbsenceProposals = absenceProposalWithLeave;
        break;
    }

    if (customProposalsTypeFilter.length > 0) {
      selectedAbsenceProposals = absenceProposalWithLeave.filter((a) =>
        customProposalsTypeFilter.includes(a.absenceType)
      );
    }

    return getTimelineEventsFromAbsenceProposals(
      selectedAbsenceProposals,
      contactsByIdState,
      officesByIdState,
      t,
      customApplicantSelection ?? relevantContacts.map((c) => c.contactId)
    );
  }, [
    absenceProposals,
    employeeSelection,
    contactsByIdState,
    officesByIdState,
    t,
    customSelectedEmployees,
    selectedProjects,
    internalProjectContactsData,
    customProposalsTypeFilter,
    selectedOffices,
    relevantContacts,
  ]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const fetchAbsenceProposal = useCallback(
    async (
      filter: { from: Moment; to: Moment },
      proposalsToAdd?: AbsenceProposal[]
    ) => {
      setIsAbsenceProposalsLoading(true);
      try {
        const { data } =
          context !== 'me'
            ? officeId
              ? await apiFetchOfficeAbsenceProposals(officeId, filter)
              : await apiFetchAbsenceProposals(filter)
            : await apiFetchPublicAbsenceProposals(filter);
        if (data) {
          const filtered = onlyOpen
            ? data.filter(
                (p) =>
                  p.absenceState === 'requested' ||
                  p.absenceState === 'accepted'
              )
            : data;

          var _filtered = filtered.map((proposal) => ({
            ...proposal,
            principalId: proposal.principalId.toLowerCase(),
          }));
          setAbsenceProposals([
            ...(proposalsToAdd ?? []).filter(
              (p1) =>
                !_filtered.find(
                  (p2) => p2.absenceProposalId === p1.absenceProposalId
                )
            ),
            ..._filtered,
          ]);
        } else {
          notification.open({
            message: t('common:error'),
            description: t('absences:errorMessages.fetchOverviewError'),
          });
        }
      } catch {}
      setIsAbsenceProposalsLoading(false);
    },
    [t, onlyOpen, officeId, context]
  );

  const onAddContact = (contactId: ContactId) => {
    dispatch(addEmployee(contactId, officeId));
  };
  const onRemoveContact = (contactId: ContactId) => {
    dispatch(removeEmployee(contactId, officeId));
  };

  const onProjectSelectionChange = (value: string[]) => {
    setSelectedProjects(value as string[]);

    const newProjectId = value.filter(
      (x) => !selectedProjects.some((k) => x === k)
    )[0];

    if (newProjectId !== undefined) {
      dispatch(fetchInternalProjectContacts(newProjectId));
    }
  };

  const onOfficeSelectionChange = (value: string[]) => {
    setSelectedOffices(value as string[]);

    const newOfficeId = value.filter(
      (x) => !selectedOffices.some((k) => x === k)
    )[0];

    if (newOfficeId !== undefined) {
      dispatch(fetchInternalProjectContacts(newOfficeId));
    }
  };

  const onAbsenceTypeSelectionChange = (value: AbsenceType[]) => {
    setCustomProposalsTypeFilter(value as AbsenceType[]);
  };
  const onProjectButtonClick = () => {
    setEmployeeSelection('byProject');
    setSelectedOffices([]);
  };
  const onAllButtonClick = () => {
    setEmployeeSelection('all');
    setSelectedProjects([]);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    dispatch(fetchInternalOffices());
  }, [dispatch]);

  useEffect(() => {
    fetchAbsenceProposal({
      from: moment().subtract(1, 'month').startOf('month'),
      to: moment().add(1, 'month').endOf('month'),
    });
  }, [fetchAbsenceProposal]);

  useEffect(() => {
    setUserOfficeId(contactsByIdState[userMe?.id ?? '']?.officeId ?? null);
  }, [userMe, contactsByIdState]);

  useEffect(() => {
    if (context === 'me') {
      setSelectedOffices([userOfficeId ?? '']);
    }
    if (context === 'office') {
      setSelectedOffices([officeId ?? '']);
    }
  }, [userOfficeId, officeId, context]);

  //#endregion

  return (
    <div className={classNames(classes.root, className)}>
      <Flex.Column
        className={classes.column}
        childrenGap={theme.old.spacing.unit(2)}
      >
        <Flex.Row
          childrenGap={theme.old.spacing.unit(1)}
          padding={theme.old.spacing.defaultPadding}
          className={classes.backgroundColor}
        >
          <Button
            type={employeeSelection === 'custom' ? 'primary' : 'link'}
            onClick={() => setEmployeeSelection('custom')}
          >
            {t('absences:absenceTimeline.selectionButtons.custom')}
          </Button>
          <Button
            type={employeeSelection === 'byProject' ? 'primary' : 'link'}
            onClick={onProjectButtonClick}
          >
            {t('absences:absenceTimeline.selectionButtons.byProject')}
          </Button>
          <Button
            type={employeeSelection === 'all' ? 'primary' : 'link'}
            onClick={onAllButtonClick}
          >
            {t('absences:absenceTimeline.selectionButtons.all')}
          </Button>
          {employeeSelection === 'byProject' && (
            <ProjectPicker
              maxTagCount={1}
              className={classes.projectPicker}
              multiple
              value={selectedProjects}
              onChange={onProjectSelectionChange}
              label={t('absences:absenceTimeline.projectPicker.label')}
            />
          )}
          {employeeSelection === 'all' && (
            <div className={classes.officePickerWrapper}>
              <OfficePicker
                multiple
                value={selectedOffices}
                onChange={onOfficeSelectionChange}
                label={'Alle Niederlassungen'}
                officeIds={!isGlobalHR ? officeIdsWhereOfficeHR : null}
                disabled={context === 'office' || context === 'me'}
              />
            </div>
          )}

          <Select
            maxTagPlaceholder={(omitted) =>
              t('common:select.omittedPlaceholder', { count: omitted.length })
            }
            className={classes.absenceTypePicker}
            mode={'multiple'}
            showSearch
            value={customProposalsTypeFilter}
            placeholder={t('absences:absenceTimeline.filter.absenceType')}
            optionFilterProp="label"
            onChange={onAbsenceTypeSelectionChange}
            filterOption={(input, option) =>
              option.label
                .toString()
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            <Select.Option
              value={'annualLeave'}
              key={'annualLeave'}
              label={t('absences:form.absenceTypes.annualLeave')}
            >
              {t('absences:form.absenceTypes.annualLeave')}
            </Select.Option>

            <Select.Option
              value={'overtimeCompensation'}
              key={'overtimeCompensation'}
              label={t('absences:form.absenceTypes.overtimeCompensation')}
            >
              {t('absences:form.absenceTypes.paidSpecialLeave')}
            </Select.Option>

            <Select.Option
              value={'paidSpecialLeave'}
              key={'paidSpecialLeave'}
              label={t('absences:form.absenceTypes.paidSpecialLeave')}
            >
              {t('absences:form.absenceTypes.paidSpecialLeave')}
            </Select.Option>

            <Select.Option
              value={'dayOfIllness'}
              key={'dayOfIllness'}
              label={t('absences:form.absenceTypes.dayOfIllness')}
            >
              {t('absences:form.absenceTypes.dayOfIllness')}
            </Select.Option>

            <Select.Option
              value={'parentalLeave'}
              key={'parentalLeave'}
              label={t('absences:form.absenceTypes.parentalLeave')}
            >
              {t('absences:form.absenceTypes.parentalLeave')}
            </Select.Option>

            <Select.Option
              value={'maternityLeave'}
              key={'maternityLeave'}
              label={t('absences:form.absenceTypes.maternityLeave')}
            >
              {t('absences:form.absenceTypes.maternityLeave')}
            </Select.Option>

            <Select.Option
              value={'annualLeavePlanning'}
              key={'annualLeavePlanning'}
              label={t('absences:form.absenceTypes.annualLeavePlanning')}
            >
              {t('absences:form.absenceTypes.annualLeavePlanning')}
            </Select.Option>
          </Select>
        </Flex.Row>
        <TimelineComponent
          className={classes.timeline}
          timelineEvents={timelineItems}
          sidebarWidth={300}
          title={t(`absences:absenceTimeline.titles.${employeeSelection}`)}
          groups={groups}
          customContacts={employeeSelection === 'custom'}
          onAddContact={onAddContact}
          onRemoveContact={onRemoveContact}
          canMove={false}
          canResize={false}
          onReload={fetchAbsenceProposal}
          officeId={officeId}
          context={context}
        />
      </Flex.Column>
      {isAbsenceProposalsLoading && (
        <Center className={classes.loadingIndicator}>
          <PrioSpinner />
        </Center>
      )}
    </div>
  );
};

export default AbsenceOverviewPage;
