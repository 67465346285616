import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import {} from 'antd';
import { makePrioStyles } from '../../../../theme/utils';
import { OfficeId } from '../../../../models/Types';
import FilterContextProvider from '../../../../components/Filter/FilterContextProvider';
import {
  DefaultSearchParameterItem,
  FILTER_DATA_LIST_CLASS_PREFIX,
  FilterBar,
} from '../../../../components/Filter/FilterBar';
import HRTimekeepingPageTable, {
  HRTimekeepingPageTableRef,
} from './HRTimekeepingPageTable';
import {
  TimeKeepingDay,
  TimeKeepingDayCalculatedData,
  TimeKeepingDaySearchResultItem,
} from '../../../../models/TimeKeeping';

const useStyles = makePrioStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'auto',
    flex: 1,
    padding: 24,
  },
  content: {
    padding: theme.old.spacing.defaultPadding,
    flex: 1,
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface HRTimekeepingPageProps {
  className?: string;
  officeId: OfficeId;
}

export const HRTimekeepingPage: React.FC<HRTimekeepingPageProps> = (props) => {
  //#region ------------------------------ Defaults
  const { className, officeId } = props;
  const classes = useStyles();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const tableRef = useRef<HRTimekeepingPageTableRef>(null);

  const [selectedSearchResults, setSelectedSearchResults] = useState<
    TimeKeepingDaySearchResultItem[]
  >([]);
  // TODO: Setup for drawer
  // const [activeTimekeepingDay, setActiveTimekeepingDay] =
  //   useState<ExtendedTimeKeepingDay>(undefined);
  // const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleOnRowSelectionChange = (
    items: TimeKeepingDaySearchResultItem[]
  ) => {
    setSelectedSearchResults(items);
  };

  const customDefaultSearchParameters: DefaultSearchParameterItem[] = [
    {
      parameterName: 'Calculated.OfficeId',
      defaultValue: officeId,
      defaultMethod: 'eq',
    },
  ];
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(className, classes.root)}>
      <FilterContextProvider<TimeKeepingDay, TimeKeepingDayCalculatedData>
        searchType="timeKeepingDays"
        equalityFunction={({ data: a }, { data: b }) =>
          a.timeKeepingDayId === b.timeKeepingDayId
        }
      >
        <FilterBar
          customDefaultSearchParameters={customDefaultSearchParameters}
        />
        <HRTimekeepingPageTable
          className={FILTER_DATA_LIST_CLASS_PREFIX}
          ref={tableRef}
          selectedSearchItems={selectedSearchResults}
          tableId={'trp1'}
          onRowSelectionChange={handleOnRowSelectionChange}
        />
        {/* TODO: Einfügen, wenn Design und Funktionalität klar */}
        {/* <HRTimekeepingPageDrawer
          timekeepingDay={activeTimekeepingDay}
          isDrawerOpen={isDrawerOpen}
          setIsDrawerOpen={setIsDrawerOpen}
        /> */}
      </FilterContextProvider>
    </div>
  );
};

export default HRTimekeepingPage;
