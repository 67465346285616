import React, { useState, useEffect, useMemo } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  Divider,
  Typography,
  Checkbox,
  Select,
  Slider,
  TimePicker,
} from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { makePrioStyles } from '../../../../theme/utils';
import useDatePickerLocale from '../../../../hooks/useDatePickerLocale';

import ProjectPicker from '../../../projects/components/ProjectPicker';
import {
  TimeRecord,
  CreateTimeRecordRequest,
  emptyCreateTimeRecordRequest,
  TimeRecordFormModel,
} from '../../../../models/TimeRecord';
import {
  ContactId,
  EditTimeRecordContextType,
  OfficeRole,
  ProjectId,
} from '../../../../models/Types';
import ContactPicker from '../../../contacts/components/ContactPicker';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInternalProjectContacts } from '../../../projects/actions';
import {
  getActiveProjectWithoutMe,
  getUserMeContactId,
  RootReducerState,
  getUserMeIsFetching,
  getAllTimeRecords,
  getAllProjects,
  getInternalProjectContactsIsFetching,
  getProject,
} from '../../../../apps/main/rootReducer';
import { useAccessRights } from '../../../users/hooks/useAccessRights';
import { sumUpDurationInMinutes } from '../../util';
import { rowGutter, colon } from '../../../../util/forms';
import { createSelector } from 'reselect';
import { Project } from '../../../../models/Project';
import { internalProjectContactContactIdSelector } from '../../../projects/selectors';
import Flex from '../../../../components/Flex';
import { apiFetchContact } from '../../../contacts/api';
import { Contact } from '../../../../models/Contact';
import CustomSingleDatePicker from '../../../../components/CustomSingleDatePicker';
import classNames from 'classnames';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { ProjectPhase } from '../../../../models/ProjectPhase';
import { apiGetProjectPhases } from '../../../projects/api';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 1024,
    height: '100%',
    '&.ant-form': {
      marginTop: '0px',
    },
  },
  actionButtonsRow: {
    marginTop: theme.old.spacing.unit(3),
  },
  datePicker: {
    width: '100%',
  },
  timePicker: {
    width: `100%`,
    display: 'flex',
    marginRight: theme.old.spacing.unit(1),
  },
  duration: {
    transition: 'border-color 0.3s',
  },
  periodSum: {
    textAlign: 'right',
  },
  mutedColor: {
    color: theme.old.typography.colors.muted,
  },
  danger: {
    color: theme.colors.base.red[80],
    '&:hover': {
      backgroundColor: theme.old.palette.chromaticPalette.red,
      color: theme.old.palette.chromaticPalette.white,
      '& > .prio-button-icon': {
        color: theme.old.palette.chromaticPalette.white,
      },
    },
    '& > .prio-button-icon': {
      color: theme.old.palette.chromaticPalette.red,
    },
  },
  warning: {
    color: theme.old.palette.chromaticPalette.red,
  },
  scrollable: {
    height: 'calc(100% - 56px - 12px)',
    overflowX: 'hidden',
    overflowY: 'auto',
  },
  noMargin: {
    marginBottom: '0px',
  },
  divider: {
    margin: '8px 0px 24px 0px',
  },
  slider: {
    marginLeft: '12px',
    marginRight: '12px',
  },
  wrapper: {
    height: 'calc(100% - 56px)',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
}));

interface EditTimeRecordFormProps {
  className?: string;
  initialValues?: TimeRecord;
  disableActionButton?: boolean;
  disableForm?: boolean;
  actionLabel: string;
  cancelLabel?: string;
  onFinish: (value: CreateTimeRecordRequest | TimeRecord) => void;
  onDelete?: (value: TimeRecord) => void;
  onCancel?: () => void;
  resetOnFinish?: boolean;
  contextType: EditTimeRecordContextType;
  officeRoles?: OfficeRole[];
  onlyOffice?: boolean;
}

const numberPattern = new RegExp('[0-9]+');

const convertToModel: (
  initialValue: TimeRecord | CreateTimeRecordRequest
) => TimeRecordFormModel = (
  initialValue: TimeRecord | CreateTimeRecordRequest
) => ({
  ...initialValue,
  day: initialValue.day && moment(initialValue.day, moment.ISO_8601),
  periods: initialValue.timeRecordEntries.map((value) => [
    moment(value.startTime, moment.ISO_8601),
    moment(value.endTime, moment.ISO_8601),
  ]),
  kilometerDistance: initialValue.kilometerDistance?.toString(),
});

const calcDuration = (period: Moment[]): number => {
  const [startTime, endTime] = period;
  const duration =
    Math.round(moment.duration(endTime.diff(startTime)).asHours() * 100) / 100;
  return duration;
};

const myProjectIdsSelector = createSelector<
  [(state: RootReducerState) => Project[]],
  ProjectId[]
>(
  (state) => getAllProjects(state),
  (projects) => (projects ?? []).map((project) => project.projectId)
);

export const EditTimeRecordForm: React.FC<EditTimeRecordFormProps> = (
  props
) => {
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    className,
    initialValues,
    actionLabel,
    cancelLabel,
    onFinish,
    onDelete,
    onCancel,
    disableActionButton,
    disableForm,
    resetOnFinish,
    contextType,
    officeRoles,
    onlyOffice,
  } = props;
  //#region ------------------------------ Defaults
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const userMeContactId = useSelector(getUserMeContactId);
  const activeProject = useSelector(getActiveProjectWithoutMe);

  const [projectPhases, setProjectPhases] = useState<ProjectPhase[]>([]);
  const [currentProjectId, setCurrentProject] = useState<ProjectId>(
    initialValues?.projectId ?? activeProject
  );

  const currentProject = useSelector<RootReducerState, Project>((state) =>
    getProject(state, currentProjectId)
  );

  const projectContactIds: ContactId[] = useSelector<
    RootReducerState,
    ContactId[]
  >((state) =>
    internalProjectContactContactIdSelector(currentProjectId)(state)
  );

  const isFetchingMe = useSelector(getUserMeIsFetching);
  const [isBreakOpen, setIsBreakOpen] = useState<boolean>(false);
  const [currentBreakDuration, setCurrentBreakDuration] = useState<number>(0);
  const accessRights = useAccessRights(
    [
      'writeOtherUserTimeRecord',
      'writeOtherUserTimeRecordByProjectRole',
      'writeOtherUserTimeRecordByGlobalRole',
    ],
    { projectId: currentProjectId, companyId: currentProject?.subsidiaryId }
  );
  const canSelectContact = accessRights['writeOtherUserTimeRecord'];

  const convertedInitialValue = useMemo(() => {
    return convertToModel(
      initialValues ?? {
        ...emptyCreateTimeRecordRequest,
        projectId: currentProjectId ?? activeProject,
        contactId: userMeContactId,
      }
    );
  }, [initialValues, currentProjectId, activeProject, userMeContactId]);

  const [periods, setPeriods] = useState<[Moment, Moment][]>(
    convertedInitialValue.periods
  );
  const datePickerLocale: PickerLocale = useDatePickerLocale();

  const periodSum = periods.reduce(
    (sum, value) => (value ? sum + calcDuration(value) : sum),
    0
  );

  const [day, setDay] = useState<Moment>(convertedInitialValue.day ?? moment());
  const [title, setTitle] = useState<string>(convertedInitialValue.title ?? '');
  const [contact, setContact] = useState<ContactId>(
    convertedInitialValue.contactId ?? null
  );

  const timeRecords = useSelector(getAllTimeRecords);
  const isDuplicate: boolean =
    timeRecords.filter((record) => {
      if (!record) {
        return false;
      }
      return (
        record.projectId === currentProjectId &&
        record.contactId === contact &&
        initialValues?.timeRecordId !== record.timeRecordId &&
        record.day === day.format('YYYY-MM-DD') &&
        record.title === title &&
        record.timeRecordEntries.length === periods.length &&
        record.timeRecordEntries.filter((entry) =>
          periods.find(
            (period) =>
              period &&
              moment(entry.startTime).toISOString().slice(0, 19) ===
                period[0]
                  ?.date(day.date())
                  ?.month(day.month())
                  ?.year(day.year())
                  ?.toISOString()
                  .slice(0, 19) &&
              moment(entry.endTime).toISOString().slice(0, 19) ===
                period[1]
                  ?.date(day.date())
                  ?.month(day.month())
                  ?.year(day.year())
                  ?.toISOString()
                  .slice(0, 19)
          )
        ).length === periods.length
      );
    }).length > 0;

  const myProjectIds = useSelector(myProjectIdsSelector);
  const isInternalFetching = useSelector(getInternalProjectContactsIsFetching);

  const [includedContact, setIncludedContact] = useState<Contact[]>([]);
  const [isFetchingIncludedContact, setIsFetchingIncludedContact] =
    useState<boolean>(false);
  const shouldIncludeIncludedContact =
    initialValues?.projectId === currentProjectId;
  //#endregion

  //#region ------------------------------ Methods
  //Converts Moment into Slider-Index
  const decalculateTime = (m1: Moment, m2: Moment) => {
    const hour1 = ((m1.hour() - 6) / 15) * 60;
    const min1 = m1.minute() / 15;

    const index1 = hour1 + min1;

    const hour2 = ((m2.hour() - 6) / 15) * 60;
    const min2 = m2.minute() / 15;

    const index2 = hour2 + min2;

    return [index1, index2];
  };

  const initSliderTimeRecords = (timeRecord: TimeRecord) => {
    const _timeRecords = timeRecord.timeRecordEntries;
    var _timeRecordsSliderRange = [];
    _timeRecords.forEach((e) => {
      _timeRecordsSliderRange.push([
        decalculateTime(
          moment(new Date(e.startTime)),
          moment(new Date(e.endTime))
        )[0],
        decalculateTime(
          moment(new Date(e.startTime)),
          moment(new Date(e.endTime))
        )[1],
      ]);
    });
    return _timeRecordsSliderRange as [[number, number]];
  };

  const [timeRecordsSliderRange, setTimeRecords2] = useState<
    [[number, number]]
  >(initSliderTimeRecords(initialValues));

  //Converts Slider-Index into Timestring
  const calculateTime = (range: [number, number], startTime: number) => {
    let minStr = '';
    var _value = range[1] - range[0];
    const _x = (_value * 15) / 60;
    const hour = Math.trunc(_x) + startTime;
    const min = Math.round((_x - Math.trunc(_x)) * 60);
    if (min.toString().length === 1) {
      minStr = min.toString() + '0';
    } else {
      minStr = min.toString();
    }
    return `${hour}:${minStr}`;
  };

  //Calculates Difference between both Moments
  const calculateRange = (index: number) => {
    var _timeRanges = [];
    for (var i = 0; i < 57 - timeRecordsSliderRange[index][0]; i++) {
      _timeRanges.push(i);
    }
    return _timeRanges;
  };

  //#endregion

  //#region ------------------------------ Handlers

  const handleOnRangeChange = (
    value: [number, number],
    index: number,
    isSlider: boolean
  ) => {
    var _periods = periods;
    if (isSlider) {
      let _records = JSON.parse(JSON.stringify(timeRecordsSliderRange));

      _records[index] = value;
      setTimeRecords2(_records);
      _periods[index] = [
        moment(calculateTime([0, value[0]], 6), 'h:mm a'),
        moment(calculateTime([0, value[1]], 6), 'h:mm a'),
      ];
      setPeriods(_periods);
    } else {
      let _dis = value[1] - value[0];
      let _records = JSON.parse(JSON.stringify(timeRecordsSliderRange));

      _records[index] = [_records[index][0], _records[index][0] + _dis];

      setTimeRecords2(_records);

      _periods[index] = [
        moment(calculateTime([0, _records[index][0]], 6), 'h:mm a'),
        moment(calculateTime([0, _records[index][0] + _dis], 6), 'h:mm a'),
      ];
      setPeriods(_periods);

      form.setFieldsValue({ periods: _periods });
    }
  };

  const handleBreakDurationChange = (value: number) => {
    if (value === 0) {
      setIsBreakOpen(false);
    }
    setCurrentBreakDuration(value);
  };

  const cancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleDelete = () => {
    onDelete(initialValues);
    form.resetFields();
  };

  const handleFinish: (value: TimeRecordFormModel) => void = (
    value: TimeRecordFormModel
  ) => {
    const { periods, ...otherValues } = value;
    const timeRecordEntries = periods.map(([startTime, endTime]) => ({
      startTime: `${value.day.toISOString(true).split('T')[0]}T${startTime
        .toISOString(true)
        .split('T')[1]
        .slice(0, 12)}Z`,
      endTime: `${value.day.toISOString(true).split('T')[0]}T${endTime
        .toISOString(true)
        .split('T')[1]
        .slice(0, 12)}Z`,
    }));

    onFinish({
      ...otherValues,
      day: value.day.toISOString(true).split('T')[0],
      timeRecordEntries,
      kilometerDistance: value.kilometerDistance
        ? Math.round(parseFloat(value.kilometerDistance))
        : undefined,
      durationInMinutes: sumUpDurationInMinutes(timeRecordEntries),
      ...(initialValues
        ? {
            companyId: initialValues.companyId,
            invoiceId: initialValues.invoiceId,
            invoiceNumber: initialValues.invoiceNumber,
            subProjectId: initialValues.subProjectId,
          }
        : {}),
    });
    if (resetOnFinish) {
      form.resetFields();
      form.setFieldsValue({
        projectId: currentProjectId,
        contactId: value.contactId,
      });
      setTitle('');
      setDay(convertedInitialValue.day ?? moment());
    }
  };

  const handleAddTimeRecordEntry = (
    add: (defaultValue?: any, insertIndex?: number) => void
  ) => {
    if (!isBreakOpen) {
      add([
        moment(periods[periods.length - 1][1].toISOString()),
        moment(periods[periods.length - 1][1].toISOString()),
      ]);
    } else {
      const start = moment(periods[periods.length - 1][1].toISOString()).add(
        currentBreakDuration,
        'minutes'
      );
      const end = moment(start.toISOString());

      if (!start.isSame(periods[periods.length - 1][0])) {
        start.day(periods[periods.length - 1][0].day());
      }
      if (!end.isSame(periods[periods.length - 1][1])) {
        end.day(periods[periods.length - 1][1].day());
      }
      add([start, end]);
    }
  };

  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (initialValues?.projectId || activeProject) {
      dispatch(
        fetchInternalProjectContacts(initialValues?.projectId ?? activeProject)
      );
    }
  }, [initialValues, activeProject, dispatch]);

  useEffect(() => {
    const getProjectPhases = async () => {
      // get projectPhases
      const { data } = await apiGetProjectPhases(currentProjectId);
      if (data) {
        setProjectPhases(
          data?.filter((phase) => phase.isActive && !phase.isArchived)
        );
      } else {
        setProjectPhases([]);
      }
    };

    getProjectPhases();
  }, [form, currentProjectId]);

  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue(initialValues);
      setCurrentProject(initialValues.projectId);
      setContact(initialValues.contactId);
      if (initialValues.contactId) {
        const fetchContact = async () => {
          setIsFetchingIncludedContact(true);
          const { data } = await apiFetchContact(initialValues.contactId);
          if (data) {
            setIncludedContact([data]);
            form.setFieldsValue({ contactId: data.contactId });
            setContact(data.contactId);
          }
          setIsFetchingIncludedContact(false);
          form.validateFields(['contactId']);
        };
        fetchContact();
      }
    }
  }, [form, initialValues]);

  useEffect(() => {
    form.resetFields();
  }, [initialValues, form]);

  useEffect(() => {
    setPeriods(convertedInitialValue.periods);
  }, [convertedInitialValue, setPeriods]);

  useEffect(() => {
    if (contact && userMeContactId && projectContactIds) {
      if (contact !== userMeContactId) {
        if (shouldIncludeIncludedContact) {
          if (
            !isInternalFetching &&
            !isFetchingIncludedContact &&
            !projectContactIds.includes(contact) &&
            !includedContact.find((iC) => iC.contactId === contact)
          ) {
            form.setFieldsValue({ contactId: null });
            setContact(null);
          }
        } else if (
          !isInternalFetching &&
          !projectContactIds.includes(contact)
        ) {
          form.setFieldsValue({ contactId: null });
          setContact(null);
        }
      }
    }
  }, [
    projectContactIds,
    contact,
    form,
    userMeContactId,
    isInternalFetching,
    shouldIncludeIncludedContact,
    isFetchingIncludedContact,
    includedContact,
  ]);
  //#endregion

  return (
    <Form
      className={classNames(classes.root, className)}
      initialValues={convertedInitialValue}
      form={form}
      onValuesChange={(
        changedValues: TimeRecordFormModel,
        allValues: TimeRecordFormModel
      ) => {
        if (changedValues.projectId) {
          dispatch(fetchInternalProjectContacts(changedValues.projectId));
          setCurrentProject(changedValues.projectId);
          if (allValues.contactId) {
            if (contact === userMeContactId) {
              if (!myProjectIds.includes(changedValues.projectId)) {
                form.setFieldsValue({
                  contactId: null,
                });
                setContact(null);
              }
            }
          } else if (myProjectIds.includes(changedValues.projectId)) {
            form.setFieldsValue({
              contactId: userMeContactId,
            });
            setContact(userMeContactId);
          }
        }
        if (changedValues.periods) {
          const updatedPeriods = [...changedValues.periods];
          const itemCount = allValues.periods.length;
          const result = Array<[Moment, Moment]>(itemCount);
          const _timeRecordsSliderRange = timeRecordsSliderRange;
          for (let i = 0; i < result.length; i++) {
            result[i] = updatedPeriods[i] || periods[i];
            _timeRecordsSliderRange[i] = [
              decalculateTime(result[i][0], result[i][1])[0],
              decalculateTime(result[i][0], result[i][1])[1],
            ];
          }
          setTimeRecords2(_timeRecordsSliderRange);
          setPeriods(result);
        }
        if (changedValues.title) {
          setTitle(changedValues.title);
        }
        if (changedValues.day) {
          setDay(changedValues.day);
        }
        if (changedValues.contactId) {
          setContact(changedValues.contactId);
        }
      }}
      onFinish={handleFinish}
      layout="vertical"
    >
      <div className={classes.wrapper}>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name="projectId"
              label={t('timeRecords:form.labels.projectId')}
              rules={[
                {
                  required: true,
                  message: t('timeRecords:form.validation.missingProjectId'),
                },
              ]}
            >
              <ProjectPicker
                disabled={disableForm}
                contextType={contextType}
                officeRoles={officeRoles}
                onlyOfficeProjects={onlyOffice}
                include={currentProject}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name="contactId"
              label={t('timeRecords:form.labels.contactId')}
              rules={[
                {
                  required: true,
                  message: t('timeRecords:form.validation.missingContactId'),
                },
                () => ({
                  async validator(rule, value) {
                    if (
                      projectContactIds?.length > 0 &&
                      !projectContactIds.includes(value)
                    ) {
                      return Promise.reject(
                        t('timeRecords:form.validation.invalidContactId')
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <ContactPicker
                onlyInternalProject
                projectId={currentProjectId}
                disabled={
                  contextType === 'me'
                    ? true
                    : !canSelectContact || disableForm || !currentProjectId
                }
                loading={
                  (isFetchingMe && !!userMeContactId) ||
                  isFetchingIncludedContact
                }
                includedContacts={
                  shouldIncludeIncludedContact ? includedContact : []
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Divider className={classes.divider} />
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name="day"
              label={t('timeRecords:form.labels.day')}
              colon={colon}
              rules={[
                {
                  required: true,
                  message: t('timeRecords:form.validation.missingDay'),
                },
              ]}
            >
              <CustomSingleDatePicker
                id="edit_timerecord_id"
                anchorDirection={'ANCHOR_RIGHT'}
                small={true}
                regular={false}
                withFullScreenPortal={false}
                twoMonths={true}
                daySize={30}
                hideKeyboardShortcutsPanel={true}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.List name="periods">
              {(fields, { add, remove }) => {
                return (
                  <div>
                    {fields.map((field, index) => (
                      <Form.Item
                        label={
                          index === 0
                            ? t('timeRecords:form.labels.timeRecordEntries')
                            : null
                        }
                        required={false}
                        key={field.key}
                      >
                        <Row>
                          <Col span={fields.length > 1 ? 22 : 24}>
                            <Slider
                              className={classes.slider}
                              range
                              defaultValue={[8, 40]}
                              disabled={false}
                              min={0.0}
                              max={56.0}
                              tipFormatter={(value) => (
                                <p>{calculateTime([0, value], 6)}</p>
                              )}
                              onChange={(e) => {
                                handleOnRangeChange(e, index, true);
                              }}
                              value={timeRecordsSliderRange[index]}
                            />
                            <Flex.Row>
                              <Flex.Item flexGrow={1}>
                                <div className={classes.duration}>
                                  <Row
                                    style={
                                      fields.length > 1
                                        ? { paddingRight: '10px' }
                                        : null
                                    }
                                  >
                                    <Col
                                      span={8}
                                      style={{
                                        paddingRight: theme.old.spacing.unit(1),
                                      }}
                                    >
                                      <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                          option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                        onSelect={(e) => {
                                          handleOnRangeChange(
                                            [0, parseInt(e)],
                                            index,
                                            false
                                          );
                                        }}
                                        value={calculateTime(
                                          [
                                            decalculateTime(
                                              periods[index][0],
                                              periods[index][1]
                                            )[0],
                                            decalculateTime(
                                              periods[index][0],
                                              periods[index][1]
                                            )[1],
                                          ],
                                          0
                                        )}
                                      >
                                        {calculateRange(index).map(
                                          (quarterStep, i) => (
                                            <Select.Option
                                              key={i}
                                              value={quarterStep}
                                            >
                                              {calculateTime(
                                                [0, quarterStep],
                                                0
                                              )}
                                            </Select.Option>
                                          )
                                        )}
                                      </Select>
                                    </Col>
                                    <Col span={16}>
                                      <Form.Item
                                        {...field}
                                        rules={[
                                          {
                                            required: true,
                                            message: t(
                                              'timeRecords:form.validation.missingTimeRecordEntry'
                                            ),
                                          },
                                          () => ({
                                            async validator(rule, value) {
                                              if (value) {
                                                const start: Moment = value[0];
                                                const end: Moment = value[1];
                                                if (
                                                  end.diff(start, 'minutes') < 0
                                                ) {
                                                  return Promise.reject(
                                                    t(
                                                      'timeRecords:form.validation.invalidTimeRecordEntry'
                                                    )
                                                  );
                                                }

                                                if (
                                                  periods.filter(
                                                    (period, i) => {
                                                      return (
                                                        i !== index &&
                                                        period &&
                                                        !(
                                                          (period[0].diff(
                                                            start,
                                                            'minutes'
                                                          ) >= 0 &&
                                                            period[0].diff(
                                                              end,
                                                              'minutes'
                                                            ) >= 0) ||
                                                          (start.diff(
                                                            period[1],
                                                            'minutes'
                                                          ) >= 0 &&
                                                            end.diff(
                                                              period[1],
                                                              'minutes'
                                                            ) >= 0)
                                                        )
                                                      );
                                                    }
                                                  ).length > 0
                                                ) {
                                                  return Promise.reject(
                                                    t(
                                                      'timeRecords:form.validation.overlappingTimeRecordEntries'
                                                    )
                                                  );
                                                }
                                                return Promise.resolve();
                                              }
                                              return Promise.reject();
                                            },
                                          }),
                                        ]}
                                        noStyle
                                      >
                                        <TimePicker.RangePicker
                                          disabled={disableForm}
                                          locale={datePickerLocale}
                                          className={classes.timePicker}
                                          disabledHours={() => [
                                            0, 1, 2, 3, 4, 5, 21, 22, 23,
                                          ]}
                                          disabledMinutes={(
                                            selectedHour: number
                                          ) => {
                                            if (selectedHour === 20) {
                                              return [15, 30, 45];
                                            } else {
                                              return [];
                                            }
                                          }}
                                          //@ts-ignore
                                          picker="time"
                                          format="HH:mm"
                                          suffixIcon={null}
                                          minuteStep={15}
                                          allowClear={false}
                                          showNow={false}
                                        />
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </div>
                              </Flex.Item>
                            </Flex.Row>
                          </Col>
                          <Col
                            span={fields.length > 1 ? 2 : 0}
                            style={{ marginTop: '32px' }}
                          >
                            <Flex.Item flex={1}>
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                {fields.length > 1 ? (
                                  <Button
                                    type="link"
                                    className={classes.danger}
                                    onClick={() => {
                                      remove(field.name);
                                    }}
                                    iconProp={['fal', 'trash']}
                                  ></Button>
                                ) : null}
                              </div>
                            </Flex.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                    ))}
                    {fields.length > 1 ? (
                      <div className={classes.periodSum}>
                        {t('timeRecords:form.labels.periodSum')}{' '}
                        {periodSum.toLocaleString() + ' h'}
                      </div>
                    ) : null}
                    <Flex.Row>
                      <Flex.Item>
                        <Button
                          onClick={() => handleAddTimeRecordEntry(add)}
                          type="link"
                          iconProp={['fal', 'plus']}
                          style={{ marginRight: theme.old.spacing.unit(1) }}
                        >
                          {isBreakOpen
                            ? t(
                                'timeRecords:form.actions.addTimeRecordEntryWithBreak'
                              )
                            : t('timeRecords:form.actions.addTimeRecordEntry')}
                        </Button>
                      </Flex.Item>
                      <Flex.Item>
                        <Form.Item>
                          {isBreakOpen ? (
                            <Select
                              onSelect={handleBreakDurationChange}
                              defaultValue={30}
                            >
                              <Select.Option value={0}>
                                <Typography.Text>
                                  {t(
                                    'timeRecords:form.actions.breakDuration.noBreak'
                                  )}
                                </Typography.Text>
                              </Select.Option>
                              <Select.Option value={15}>
                                <Typography.Text>
                                  {t(
                                    'timeRecords:form.actions.breakDuration.15Min'
                                  )}
                                </Typography.Text>
                              </Select.Option>
                              <Select.Option value={30}>
                                <Typography.Text>
                                  {t(
                                    'timeRecords:form.actions.breakDuration.30Min'
                                  )}
                                </Typography.Text>
                              </Select.Option>
                              <Select.Option value={45}>
                                <Typography.Text>
                                  {t(
                                    'timeRecords:form.actions.breakDuration.45Min'
                                  )}
                                </Typography.Text>
                              </Select.Option>
                            </Select>
                          ) : (
                            <Checkbox
                              onChange={(e) => {
                                setIsBreakOpen(e.target.checked);
                                e.target.checked
                                  ? setCurrentBreakDuration(30)
                                  : setCurrentBreakDuration(0);
                              }}
                            >
                              {t('timeRecords:form.actions.break')}
                            </Checkbox>
                          )}
                        </Form.Item>
                      </Flex.Item>
                    </Flex.Row>
                  </div>
                );
              }}
            </Form.List>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name="kilometerDistance"
              colon={colon}
              label={t('timeRecords:form.labels.kilometerDistance')}
              rules={[
                {
                  message: t(
                    'timeRecords:form.validation.invalidKilometerDistance'
                  ),
                  pattern: numberPattern,
                },
                () => ({
                  async validator(rule, value) {
                    if (value < 0) {
                      return Promise.reject(
                        t(
                          'timeRecords:form.validation.invalidKilometerDistance'
                        )
                      );
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <Input
                disabled={disableForm}
                type="number"
                pattern="[0-9]"
                min={0}
                suffix={
                  <FontAwesomeIcon
                    icon={['fal', 'road']}
                    className={classes.mutedColor}
                  />
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={24}>
            <Form.Item
              name="title"
              colon={colon}
              label={t('timeRecords:form.labels.title')}
              rules={[
                {
                  required: true,
                  message: t('timeRecords:form.validation.missingTitle'),
                },
              ]}
            >
              <Input disabled={disableForm} />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={theme.old.spacing.unit(rowGutter)}>
          <Col span={12}>
            <Form.Item
              name="isSeparateService"
              valuePropName="checked"
              label={t('timeRecords:form.labels.isSeparateService')}
            >
              <Checkbox />
            </Form.Item>
          </Col>
          {projectPhases.length > 0 && (
            <Col span={12}>
              <Form.Item
                name="projectPhaseId"
                label={t('timeRecords:form.labels.projectPhase')}
              >
                <Select<string>
                  showSearch
                  value={projectPhases[0].projectPhaseId}
                >
                  {projectPhases.map((phase) => (
                    <Select.Option value={phase.projectPhaseId}>
                      {phase.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
        </Row>

        <Row
          gutter={theme.old.spacing.unit(rowGutter)}
          style={{ display: 'flex', flex: 1 }}
        >
          <Col span={24}>
            <Form.Item
              name="description"
              label={t('timeRecords:form.labels.description')}
              colon={colon}
              className={classes.noMargin}
            >
              <Input.TextArea
                disabled={disableForm}
                autoSize={{ minRows: 4, maxRows: 8 }}
              />
            </Form.Item>
          </Col>
        </Row>
        {isDuplicate && !initialValues && (
          <Row
            gutter={theme.old.spacing.unit(rowGutter)}
            style={{ display: 'flex', flex: 1 }}
          >
            <Col span={24}>
              <Typography.Text className={classes.warning}>
                {t('timeRecords:form.validation.duplicateTimeRecord')}
              </Typography.Text>
            </Col>
          </Row>
        )}
      </div>
      <Flex.Row className={classes.actionButtonsRow}>
        <Flex.Item flex={1}>
          {onDelete && (
            <Button
              type="default"
              onClick={handleDelete}
              disabled={disableActionButton}
              className={classes.danger}
            >
              {t('common:delete')}
            </Button>
          )}
        </Flex.Item>
        {onCancel && (
          <Button
            type="link"
            onClick={cancel}
            style={{ marginRight: theme.old.spacing.unit(1) }}
          >
            {cancelLabel}
          </Button>
        )}
        <Button
          type="primary"
          htmlType="submit"
          disabled={disableActionButton || (isDuplicate && !initialValues)}
        >
          {actionLabel}
        </Button>
      </Flex.Row>
    </Form>
  );
};

export default EditTimeRecordForm;
