import React, {
  useMemo,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import useFilterContext from '../../../components/Filter/hooks/useFilterContext';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import FilterResultNoItemsScreen from '../../../components/Filter/FilterResultNoItemsScreen';
import { ProjectId, ProjectRole } from '../../../models/Types';

import { useDispatch, useSelector } from 'react-redux';
import {
  RootReducerState,
  getAllHourlyRates,
  getContactsByIdState,
  getUserMe,
} from '../../../apps/main/rootReducer';

import FilterContextVirtualTable from '../../../components/Filter/FilterContextVirtualTable';
import {
  ProjectMember,
  ProjectMemberCalculatedData,
  ProjectMemberSearchResultItem,
} from '../../../models/ProjectContacts';
import { ButtonProps, Pill, Tooltip } from '@prio365/prio365-react-library';
import UserAvatar from '../../../components/UserAvatar';
import useCompaniesContext from '../../companies/hooks/useCompaniesContext';
import { HourlyRate } from '../../../models/HourlyRate';
import SvgIcon from '../../../components/SvgIcon';
import { getSvgIconPathByType } from '../../../util/icon';
import { fetchHourlyRates } from '../../projects/actions';
import { useExportProjectMembersToCsv } from '../export';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { openContactsDrawer } from '../actions/drawer';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  tableRow: {
    '&:hover $contactIdCell > svg': {
      display: 'block',
    },
  },
  contactIdCell: {
    '& > svg': {
      display: 'none',
    },
  },
}));

export interface ProjectContactsPageTableRef {
  fetchProjectMember: () => void;
}

export declare type actions =
  | 'adjustFunction'
  | 'adjustHourlyRate'
  | 'archive'
  | 'adjustRoles';

interface ProjectContactsTableProps {
  className?: string;
  projectId: ProjectId;
  tableId: string;
  selectedProjectMembers: ProjectMemberSearchResultItem[];
  onRowClick?: (entry: ProjectMember) => void;
  onSelectionChange?: (items: ProjectMemberSearchResultItem[]) => void;
  onDelete?: () => void;
  setProjectContacts?: (value: ProjectMember[]) => void;
  onOpenModal: (action: actions) => void;
}

export const ProjectContactsPageTable = forwardRef(
  (
    props: ProjectContactsTableProps,
    ref: React.Ref<ProjectContactsPageTableRef>
  ) => {
    //#region ------------------------------ Defaults

    const {
      className,
      projectId,
      tableId,
      onSelectionChange,
      selectedProjectMembers,
      onOpenModal,
    } = props;
    const { t } = useTranslation();

    const classes = useStyles();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const contactsById = useSelector(getContactsByIdState);
    const { getCompanyById } = useCompaniesContext();
    const hourlyRates = useSelector<RootReducerState, HourlyRate[]>((state) =>
      getAllHourlyRates(state, projectId)
    );

    const dispatch = useDispatch();

    const { data, isLoading, fetchSearch } = useFilterContext<
      ProjectMember,
      ProjectMemberCalculatedData
    >();

    const projectContacts = data?.items;
    //#endregion

    //#region ------------------------------ Methods / Handlers
    useImperativeHandle(ref, () => ({
      fetchProjectMember: () => {
        fetchSearch();
      },
    }));
    useEffect(() => {
      dispatch(fetchHourlyRates(projectId));
    }, [dispatch, projectId]);

    const exportToCsv = useExportProjectMembersToCsv();
    const userMe = useSelector(getUserMe);
    const myProjectRoles: ProjectRole[] =
      userMe?.prioData.projectRoles[projectId];

    const handleClick = useCallback(
      (contactId: string) => {
        dispatch(
          openContactsDrawer({
            view: 'contactDetail',
            selectedContact: contactId,
          })
        );
      },
      [dispatch]
    );

    const actionBarButtons = useMemo((): ButtonProps[] => {
      if (
        myProjectRoles.includes('projectAdmin') ||
        (myProjectRoles.includes('projectController') &&
          myProjectRoles.includes('projectAssistance'))
      ) {
        return [
          {
            onClick: () => {
              onOpenModal('adjustFunction');
            },
            children: t('contacts:projectContactsPage.actions.adjustFunction'),
          },
          {
            iconProp: ['fal', 'euro-sign'],
            onClick: () => {
              onOpenModal('adjustHourlyRate');
            },
            children: t(
              'contacts:projectContactsPage.actions.adjustHourlyRate'
            ),
          },
          {
            iconProp: ['fal', 'lock'],
            onClick: () => {
              onOpenModal('adjustRoles');
            },
            children: t('contacts:projectContactsPage.actions.adjustRoles'),
          },
          {
            iconProp: ['fal', 'file-csv'],
            onClick: () => {
              exportToCsv(selectedProjectMembers, hourlyRates);
            },
            children: t('contacts:projectContactsPage.actions.csvExport'),
          },
          {
            iconProp: ['fal', 'trash-alt'],
            onClick: () => {
              onOpenModal('archive');
            },
            children: t('contacts:projectContactsPage.actions.archive'),
          },
        ];
      } else if (myProjectRoles.includes('projectAssistance')) {
        return [
          {
            onClick: () => {
              onOpenModal('adjustFunction');
            },
            children: t('contacts:projectContactsPage.actions.adjustFunction'),
          },
          {
            iconProp: ['fal', 'lock'],
            onClick: () => {
              onOpenModal('adjustRoles');
            },
            children: t('contacts:projectContactsPage.actions.adjustRoles'),
          },
          {
            iconProp: ['fal', 'file-csv'],
            onClick: () => {
              exportToCsv(selectedProjectMembers, hourlyRates);
            },
            children: t('contacts:projectContactsPage.actions.csvExport'),
          },
          {
            iconProp: ['fal', 'trash-alt'],
            onClick: () => {
              onOpenModal('archive');
            },
            children: t('contacts:projectContactsPage.actions.archive'),
          },
        ];
      } else if (myProjectRoles.includes('projectController')) {
        return [
          {
            iconProp: ['fal', 'euro-sign'],
            onClick: () => {
              onOpenModal('adjustHourlyRate');
            },
            children: t(
              'contacts:projectContactsPage.actions.adjustHourlyRate'
            ),
          },
          {
            iconProp: ['fal', 'file-csv'],
            onClick: () => {
              exportToCsv(selectedProjectMembers, hourlyRates);
            },
            children: t('contacts:projectContactsPage.actions.csvExport'),
          },
        ];
      } else {
        return [
          {
            iconProp: ['fal', 'file-csv'],
            onClick: () => {
              exportToCsv(selectedProjectMembers, hourlyRates);
            },
            children: t('contacts:projectContactsPage.actions.csvExport'),
          },
        ];
      }
    }, [
      myProjectRoles,
      t,
      onOpenModal,
      exportToCsv,
      selectedProjectMembers,
      hourlyRates,
    ]);

    //#endregion

    //#region ------------------------------ Columns
    const columns: Column<ProjectMemberSearchResultItem>[] = useMemo(() => {
      const commonColumns: Column<ProjectMemberSearchResultItem>[] = [
        {
          id: 'contactId',
          accessor: 'data.contactId',
          title: t('contacts:projectContactsPage.columns.name'),
          width: 25,
          className: classes.contactIdCell,
          sortingFn: (rowA, rowB) =>
            contactsById[rowA.data.contactId]?.firstName?.localeCompare(
              contactsById[rowB.data.contactId]?.firstName
            ),
          Cell: ({
            originalData: {
              data: { contactId, externalProjectContactId },
            },
          }) => (
            <>
              <UserAvatar
                contact={contactsById[contactId]}
                backgroundColor="#1a2f41"
                size="small"
              ></UserAvatar>
              <div
                style={{
                  marginLeft: '8px',
                  flex: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                <div style={{ fontSize: '14px' }}>
                  {contactsById[contactId]?.firstName +
                    ' ' +
                    contactsById[contactId]?.lastName}
                </div>
                <div style={{ fontSize: '10px', fontWeight: '400' }}>
                  {getCompanyById(contactsById[contactId]?.companyId)?.fullName}
                </div>
              </div>
              <Tooltip
                overlay={
                  <div>{t('contacts:projectContactsPage.toContact')}</div>
                }
                placement="right"
              >
                <FontAwesomeIcon
                  onClick={() => handleClick(contactId)}
                  icon={['fal', 'address-book']}
                  style={{
                    marginLeft: '8px',
                    cursor: 'pointer',
                    height: '14px',
                  }}
                ></FontAwesomeIcon>
              </Tooltip>

              {externalProjectContactId && (
                <Pill
                  size="small"
                  style={{ marginLeft: '8px', height: '20px' }}
                  children="Ext."
                ></Pill>
              )}
            </>
          ),
          alignSelf: true,
        },
        {
          id: 'jobTitle',
          accessor: 'data.jobTitle',
          title: t('contacts:projectContactsPage.columns.funktion'),
          width: 10,
          sortingFn: (rowA, rowB) =>
            rowA.data.jobTitle?.localeCompare(rowB.data.jobTitle),
          Cell: ({
            originalData: {
              data: { jobTitle },
            },
          }) => <>{jobTitle}</>,
          alignSelf: true,
        },
        {
          id: 'projectRoles',
          accessor: 'data.projectRoles',
          title: t('contacts:projectContactsPage.columns.projectRoles'),
          width: 35,
          Cell: ({
            originalData: {
              data: { projectRoles },
            },
          }) => (
            <>
              {projectRoles
                ?.slice()
                .sort((a, b) => a.localeCompare(b))
                .map((projectRole, index) => (
                  <Pill
                    key={index}
                    size="small"
                    children={projectRole}
                    style={{ marginRight: '8px' }}
                  />
                ))}
            </>
          ),
          alignSelf: true,
        },
        {
          id: 'projectExtensionAccessProjectExtensionMaps',
          accessor: 'calculated.projectExtensionAccessProjectExtensionMaps',
          title: t('contacts:projectContactsPage.columns.addIns'),
          width: 20,
          Cell: ({
            originalData: {
              calculated: { projectExtensionAccessProjectExtensionMaps },
            },
          }) => (
            <>
              {projectExtensionAccessProjectExtensionMaps
                ?.slice()
                .sort((a, b) =>
                  a.projectExtensionType.localeCompare(b.projectExtensionType)
                )
                .map((projectExtensionAccessProjectExtensionMap, index) =>
                  projectExtensionAccessProjectExtensionMap.shouldHaveAccess ? (
                    <SvgIcon
                      key={index}
                      width={28}
                      height={28}
                      path={getSvgIconPathByType(
                        projectExtensionAccessProjectExtensionMap.projectExtensionType
                      )}
                    />
                  ) : null
                )}
            </>
          ),
          alignSelf: true,
        },
      ];

      if (
        myProjectRoles.includes('projectAdmin') ||
        myProjectRoles.includes('projectController')
      ) {
        commonColumns.splice(2, 0, {
          id: 'hourlyRateId',
          accessor: 'data.hourlyRateId',
          title: t('contacts:projectContactsPage.columns.hourlyRate'),
          width: 10,
          Cell: ({
            originalData: {
              data: { hourlyRateId },
            },
          }) => (
            <>
              {hourlyRateId &&
                hourlyRates &&
                hourlyRates.find(
                  (hourlyRate) => hourlyRate.hourlyRateId === hourlyRateId
                )?.name}
            </>
          ),
          alignSelf: true,
        });
      }

      return commonColumns;
    }, [
      contactsById,
      t,
      hourlyRates,
      getCompanyById,
      handleClick,
      myProjectRoles,
      classes,
    ]);
    //#endregion

    //#region ------------------------------ Effects
    //#endregion

    return (
      <FilterContextVirtualTable<ProjectMemberSearchResultItem>
        id={tableId}
        className={className}
        data={projectContacts}
        columns={columns}
        onSelectionChange={onSelectionChange}
        onCheckEquality={(a, b) => a.data.contactId === b.data.contactId}
        resizable="relative"
        noItemsScreen={<FilterResultNoItemsScreen />}
        loading={
          isLoading && {
            type: 'noItems',
          }
        }
        rowsAreSelectable
        actionBarButtons={actionBarButtons}
        classNameTableRow={classes.tableRow}
      />
    );
  }
);

export default ProjectContactsPageTable;
