import React from 'react';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../theme/utils';
import SvgIcon from '../../../components/SvgIcon';
import { getSvgIconPathByType } from '../../../util/icon';
import { ProjectExtensionType } from '../../../models/ProjectExtension';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import UserAvatar from '../../../components/UserAvatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makePrioStyles((theme) => ({
  root: {
    display: 'flex',
    padding: '10px',
    alignItems: 'flex-start',
    gap: '10px',
    alignSelf: 'stretch',
    borderRadius: '4px',
    backgroundColor: '#E6EFFC',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    alignSelf: 'center',
    color: theme.colors.application.typography.active,
  },
  discription: {
    display: 'flex',
    flexDirection: 'column',
    allignItems: 'flex-start',
    flex: '1 0 0',
    color: theme.colors.application.typography.default,
  },
  title: {
    fontWeight: '700',
  },
}));

interface MailTaskProps {
  messageId?: string;
  extensionType?: ProjectExtensionType;
}

export const MailTask: React.FC<MailTaskProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { extensionType } = props;
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors

  //#endregion

  //#region ------------------------------ Methods / Handlers

  //#endregion

  //#region ------------------------------ Effects

  //#endregion

  return (
    <div className={classes.root}>
      <SvgIcon
        width={28}
        height={28}
        path={getSvgIconPathByType(extensionType)}
      ></SvgIcon>
      <div className={classes.discription}>
        <span className={classes.title}>{t('mailTask')}</span>
        <span>{t('common:widgetArea.state') + ': '}</span>
        <span>{t('common:widgetArea.dueDate') + ': '}</span>
      </div>
      <div>
        <UserAvatar
          size="medium"
          backgroundColor={theme.colors.application.background.dark}
        ></UserAvatar>
      </div>
      <div className={classes.icon}>
        <FontAwesomeIcon icon={['fal', 'external-link-alt']}></FontAwesomeIcon>
      </div>
    </div>
  );
};

export default MailTask;
