import { combineReducers, Reducer } from 'redux';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import { ProjectId } from '../../../models/Types';
import {
  CLOSE_WIDGET_AREA,
  OPEN_WIDGET_AREA,
  SELECT_WIDGET,
  SET_DOCUMENTSWIDGET_ACTIVE_PROJECT,
  SET_WIDGETBAR_ACTIVE_PROJECT,
  WidgetAreaDisplayAction,
} from '../actions';

export type Widget =
  | 'mail'
  | 'documents'
  | 'planner'
  | 'uploadManager'
  | 'comment'
  | 'task';

export interface WidgetAreaReducerState {
  displayState: WidgetAreaDisplayState;
  activeProjectId: ProjectId;
  documentsWidget: DocumentsWidgetState;
}

export interface WidgetAreaDisplayState {
  open: boolean;
  selectedWidget: Widget;
}

const initialState: WidgetAreaDisplayState = {
  open: false,
  selectedWidget: 'documents',
};

const displayState: Reducer<WidgetAreaDisplayState, WidgetAreaDisplayAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case OPEN_WIDGET_AREA: {
      const { selectedWidget } = action;
      return {
        open: true,
        selectedWidget: selectedWidget ?? state.selectedWidget,
      };
    }
    case CLOSE_WIDGET_AREA: {
      const { selectedWidget } = action;
      return {
        open: false,
        selectedWidget: selectedWidget ?? state.selectedWidget,
      };
    }
    case SELECT_WIDGET: {
      const { selectedWidget } = action;
      return {
        ...state,
        selectedWidget,
      };
    }
    case CLEAR_PRIO_CACHE: {
      return initialState;
    }
    default:
      return state;
  }
};

const activeProjectId: Reducer<ProjectId, any> = (state = null, action) => {
  switch (action.type) {
    case SET_WIDGETBAR_ACTIVE_PROJECT: {
      return action.projectId;
    }
    case CLEAR_PRIO_CACHE: {
      return null;
    }
    default:
      return state;
  }
};

interface DocumentsWidgetState {
  activeProjectId?: ProjectId;
}

const initialDocumentsWidgetState = {
  activeProjectId: null,
};

const documentsWidget: Reducer<DocumentsWidgetState, any> = (
  state = initialDocumentsWidgetState,
  action
) => {
  switch (action.type) {
    case SET_DOCUMENTSWIDGET_ACTIVE_PROJECT: {
      return {
        activeProjectId: action.projectId,
      };
    }
    case CLEAR_PRIO_CACHE: {
      return initialDocumentsWidgetState;
    }
    default:
      return state;
  }
};

export default combineReducers<WidgetAreaReducerState>({
  displayState,
  activeProjectId,
  documentsWidget,
});

export const getDisplayState: (
  state: WidgetAreaReducerState
) => WidgetAreaDisplayState = (state) => state.displayState;

export const getActiveProjectId: (
  state: WidgetAreaReducerState
) => ProjectId = (state) => state.activeProjectId;

export const getDocumentsWidgetActiveProjectId: (
  state: WidgetAreaReducerState
) => ProjectId = (state) => state.documentsWidget.activeProjectId;
