import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Typography, Collapse, Menu } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import { useSelector } from 'react-redux';
import { getUserMe, RootReducerState } from '../../../apps/main/rootReducer';
import { User } from '../../../models/User';
import useAccessRights from '../../users/hooks/useAccessRights';
import useProjectsContext from '../hooks/useProjectsContext';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  title: {
    lineHeight: '44px!important',
    padding: `${theme.old.spacing.unit(2)}px ${
      theme.old.spacing.defaultPadding
    }px`,
    margin: '0!important',
  },
  collapse: {
    backgroundColor: 'transparent',
    '& > .ant-collapse-item': {
      borderBottom: 'none',
    },
    '& > .ant-collapse-item-active > .ant-collapse-header': {
      fontWeight: theme.old.typography.fontWeight.bold,
    },
    '&.ant-collapse > .ant-collapse-item > .ant-collapse-header': {
      paddingLeft: 16,
    },
    padding: `0 ${theme.old.spacing.unit(1)}px`,
  },
  menu: {
    background: 'none',
    border: 'none',
  },
  icon: {
    marginRight: theme.old.spacing.unit(1.5),
    width: 18,
  },
}));

interface ProjectSettingsMenuProps {
  projectId: string;
  selectedList: string;
}

export const ProjectSettingsMenu: React.FC<ProjectSettingsMenuProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { selectedList } = props;
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const userMe = useSelector<RootReducerState, User>(getUserMe);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <Flex.Column flexGrow={1} className={classes.root}>
      <Typography.Title className={classes.title}>
        <Trans i18nKey="projects:projectSettingsNav.title">Einstellungen</Trans>
      </Typography.Title>
      <Collapse
        bordered={false}
        className={classes.collapse}
        defaultActiveKey={[
          'generic',
          'project-contacts',
          'time-record',
          'admin',
        ]}
      >
        <Collapse.Panel
          key="generic"
          header={t('projects:projectSettingsNav.accordion.generic')}
        >
          <GeneralMenu selectedList={selectedList} />
        </Collapse.Panel>
        <Collapse.Panel
          key="project-contacts"
          header={t('projects:projectSettingsNav.accordion.projectContacts')}
        >
          <ProjectContactsMenu selectedList={selectedList} />
        </Collapse.Panel>
        <Collapse.Panel
          key="time-record"
          header={t('projects:projectSettingsNav.accordion.timeRecord')}
        >
          <TimeRecordMenu selectedList={selectedList} />
        </Collapse.Panel>
        {userMe.prioData.globalRoles.includes('globalAdmin') ? (
          <Collapse.Panel key="admin" header={t('Admin')}>
            <AdminMenu selectedList={selectedList} />
          </Collapse.Panel>
        ) : null}
      </Collapse>
    </Flex.Column>
  );
};

export default ProjectSettingsMenu;

interface GeneralMenuProps {
  selectedList?: string;
}

const GeneralMenu: React.FC<GeneralMenuProps> = (props) => {
  const { selectedList } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const { projectId } = useParams();

  const { getProjectById } = useProjectsContext();
  const project = getProjectById(projectId);

  const { showProjectSettingsProjectPhase } = useAccessRights(
    ['showProjectSettingsProjectPhase'],
    { projectId: projectId, officeId: project?.internalOfficeId }
  );

  return (
    <Menu className={classes.menu} selectedKeys={[selectedList]} mode="inline">
      <Menu.Item key="edit">
        <Link to="../edit">
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', 'cog']}
            className={classes.icon}
          />
          {t('projects:projectSettingsNav.edit')}
        </Link>
      </Menu.Item>
      <Menu.Item key="mail">
        <Link to="../mail">
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', 'envelope']}
            className={classes.icon}
          />
          {t('projects:projectSettingsNav.mail')}
        </Link>
      </Menu.Item>
      <Menu.Item key="documents">
        <Link to="../documents">
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', 'folder-tree']}
            className={classes.icon}
          />
          {t('projects:projectSettingsNav.documents')}
        </Link>
      </Menu.Item>
      <Menu.Item key="addins">
        <Link to="../addins">
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', 'puzzle-piece']}
            className={classes.icon}
          />
          {t('projects:projectSettingsNav.addins')}
        </Link>
      </Menu.Item>
      {showProjectSettingsProjectPhase && (
        <Menu.Item key="projectPhase">
          <Link to="../projectPhase">
            <FontAwesomeIcon
              fixedWidth
              icon={['fal', 'timeline-arrow']}
              className={classes.icon}
            />
            {t('projects:projectSettingsNav.projectPhase')}
          </Link>
        </Menu.Item>
      )}
    </Menu>
  );
};

interface ProjectContactsMenuProps {
  selectedList?: string;
}

const ProjectContactsMenu: React.FC<ProjectContactsMenuProps> = (props) => {
  const { selectedList } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Menu className={classes.menu} selectedKeys={[selectedList]} mode="inline">
      <Menu.Item key="project-contacts-internal">
        <Link to="../project-contacts-internal">
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', 'user-cog']}
            className={classes.icon}
          />
          {t('projects:projectSettingsNav.internalProjectContacts')}
        </Link>
      </Menu.Item>
      <Menu.Item key="project-contacts-external">
        <Link to="../project-contacts-external">
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', 'user-cog']}
            className={classes.icon}
          />
          {t('projects:projectSettingsNav.externalProjectContacts')}
        </Link>
      </Menu.Item>
      <Menu.Item key="project-distribution-list">
        <Link to="../project-distribution-list">
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', 'clipboard-list']}
            className={classes.icon}
          />
          {t('projects:projectSettingsNav.distributionLists')}
        </Link>
      </Menu.Item>
    </Menu>
  );
};

interface TimeRecordMenuProps {
  selectedList?: string;
}

const TimeRecordMenu: React.FC<TimeRecordMenuProps> = (props) => {
  const { selectedList } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Menu className={classes.menu} selectedKeys={[selectedList]} mode="inline">
      <Menu.Item key="hourly-rates">
        <Link to="../hourly-rates">
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', 'user-clock']}
            className={classes.icon}
          />
          {t('projects:projectSettingsNav.hourlyRates')}
        </Link>
      </Menu.Item>
    </Menu>
  );
};

/*interface AdminMenuProps {
  selectedList?: string;
}*/

const AdminMenu: React.FC<TimeRecordMenuProps> = (props) => {
  const { selectedList } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Menu className={classes.menu} selectedKeys={[selectedList]} mode="inline">
      <Menu.Item key="admin">
        <Link to="../admin">
          <FontAwesomeIcon
            fixedWidth
            icon={['fal', 'user-shield']}
            className={classes.icon}
          />
          {t('projects:adminSetting.navigationName')}
        </Link>
      </Menu.Item>
    </Menu>
  );
};
