import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Routes as ReactRouterDomRoutes,
  Route,
  Navigate,
} from 'react-router-dom';

import Flex from '../../../../components/Flex';
import MailNavigation from '../MailNavigation';
import {
  getActiveMailFolderId,
  getMailSettings,
  RootReducerState,
} from '../../../../apps/main/rootReducer';
import { MailFolderId, ProjectId } from '../../../../models/Types';
import MailNavigationBar from '../NavigationBar/MailNavigationBar';
import { setMailListNavigationState } from '../../actions/actionControllers/mailNavigationActionController';
import MailRowLayout from '../layouts/MailRowLayout';
import MailColumnLayout from '../layouts/MailColumnLayout';
import MailHiddenLayout from '../layouts/MailHiddenLayout';
import { Message } from '../../../../models/Message';
import MailSettingsDrawer from '../MailSettingsDrawer';
import { fetchMailFoldersSagaAction } from '../../actions/sagas';
import { addDraftMessage } from '../../actions/actionControllers/draftsActionController';
import { apiCreateMessageDraft } from '../../api';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

import { makePrioStyles } from '../../../../theme/utils';
import { MailFilterContextProvider } from '../MailSearch/MailFilterContext';
const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  content: {
    height: '100%',
    overflow: 'hidden',
  },
}));

interface MailProjectSubModuleProps {
  projectId: ProjectId;
}

export const MailProjectSubModule: React.FC<MailProjectSubModuleProps> =
  React.memo((props) => {
    //#region ------------------------------ Defaults
    const classes = useStyles();
    const { projectId } = props;

    const dispatch = useDispatch();
    const { t } = useTranslation();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const mailSettings = useSelector(getMailSettings);

    const [selectedMessages, setSelectedMessages] = useState<Message[]>([]);

    const [mailSettingsDrawerVisible, setMailSettingsDrawerVisible] =
      useState<boolean>(false);

    const activeMailFolderId = useSelector<RootReducerState, MailFolderId>(
      (state) => getActiveMailFolderId(state, projectId)
    );
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const clearSelection = () => setSelectedMessages([]);

    const doShowNewMessage = async (
      setIsCreating: (value: boolean) => void
    ) => {
      setIsCreating(true);
      const { data } = await apiCreateMessageDraft(projectId);
      if (data) {
        dispatch(addDraftMessage(projectId, data, true));
      } else {
        notification.open({
          message: t('common:error'),
          description: t('mail:errorMessages.messages.createDraftError'),
        });
      }
      setIsCreating(false);
    };
    //#endregion

    //#region ------------------------------ Effects
    useEffect(() => {
      setTimeout(() => {
        dispatch(fetchMailFoldersSagaAction(projectId));
      }, 500);
    }, [projectId, dispatch]);

    /*
    useEffect(() => {
      const handler = setTimeout(() => {
        dispatch(deleteProjectNews(projectId));
      }, 5000);
      return () => {
        clearTimeout(handler);
      };
    }, [projectId, dispatch]);
    */

    useEffect(() => {
      if (
        projectId &&
        !window.location.href.match(/message\/(.*)\/details?$/)
      ) {
        dispatch(setMailListNavigationState(null, projectId));
      }
    }, [projectId, dispatch]);
    //#endregion

    return (
      <Flex.Column className={classes.root}>
        <MailFilterContextProvider>
          <MailNavigationBar
            projectId={projectId}
            doShowNewMessage={doShowNewMessage}
            selectedMessages={selectedMessages}
            clearSelection={clearSelection}
          />
          <Flex.Row flex={1} className={classes.content}>
            <Routes>
              <Route
                path="*"
                element={<Navigate to={activeMailFolderId ?? 'inbox'} />}
              />
              <Route
                path=":mailFolderId/*"
                element={
                  <MailNavigation
                    projectId={projectId}
                    handleOpenSettings={setMailSettingsDrawerVisible}
                    openSettings={mailSettingsDrawerVisible}
                    clearSelectedMessages={setSelectedMessages}
                  />
                }
              />
              <Route
                path=":mailFolderId/message/:messageId/details"
                element={
                  <MailNavigation
                    projectId={projectId}
                    handleOpenSettings={setMailSettingsDrawerVisible}
                    openSettings={mailSettingsDrawerVisible}
                    clearSelectedMessages={setSelectedMessages}
                  />
                }
              />
            </Routes>
            {mailSettings.messageViewLayout === 'right' ? (
              <MailRowLayout
                projectId={projectId}
                pathPrefix="../../../"
                selectedMessages={selectedMessages}
                onSelectionChange={setSelectedMessages}
                mailSettings={mailSettings}
              />
            ) : mailSettings.messageViewLayout === 'bottom' ? (
              <MailColumnLayout
                projectId={projectId}
                pathPrefix="../../../"
                selectedMessages={selectedMessages}
                onSelectionChange={setSelectedMessages}
                mailSettings={mailSettings}
              />
            ) : (
              <MailHiddenLayout
                projectId={projectId}
                pathPrefix="../../../"
                selectedMessages={selectedMessages}
                onSelectionChange={setSelectedMessages}
                mailSettings={mailSettings}
              />
            )}
            <MailSettingsDrawer
              mailSettings={mailSettings}
              mailSettingsDrawerVisible={mailSettingsDrawerVisible}
              setMailSettingsDrawerVisible={setMailSettingsDrawerVisible}
            />
          </Flex.Row>
        </MailFilterContextProvider>
      </Flex.Column>
    );
  });

export default MailProjectSubModule;
