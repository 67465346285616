import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Typography } from 'antd';
import { useTheme } from '@prio365/prio365-react-library/lib/ThemeProvider';
import { Center } from '../../../../components/Center';
import { PrioSpinner } from '@prio365/prio365-react-library';
import Flex from '../../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrioTheme } from '../../../../theme/types';
import { DriveItem, Preview } from '../../../../models/Drive';
import { MessageAttachment } from '../../../../models/Message';
import { makePrioStyles } from '../../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  fullSize: {
    width: '100%',
    height: '100%',
  },
  checkerboardBackground: {
    height: '100%',
    background: `
      -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0),
      -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent 75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0),
      white
    `,
    backgroundRepeat: 'repeat, repeat',
    backgroundPosition: '0px 0, 5px 5px',
    transformOrigin: '0 0 0',
    backgroundOrigin: 'padding-box, padding-box',
    backgroundClip: 'border-box, border-box',
    backgroundSize: '10px 10px, 10px 10px',
    boxShadow: 'none',
    textShadow: 'none',
    transition: 'none',
    transform: 'scaleX(1) scaleY(1) scaleZ(1)',
  },
  thumbnailImageContainer: {
    width: '100%',
    height: '100%',
    '& .od-Branding': {
      display: 'none',
    },
  },
}));

interface PreviewModalPreviewComponentProps {
  className?: string;
  preview: Preview;
  selectedDriveItems: (DriveItem | MessageAttachment)[];
  isLoading: boolean;
}

export const PreviewModalPreviewComponent: React.FC<
  PreviewModalPreviewComponentProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className, preview, selectedDriveItems, isLoading } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme() as PrioTheme;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const generateMultiSelectString = useCallback(
    (numberOfSelectedItems: number) => {
      switch (numberOfSelectedItems) {
        case 1:
          return `${t('common:numbers.withCapitalLetter.oneFemale')} ${t(
            'documents:documentMetaData.multiSelect.placeholderSingular'
          )}`;
        case 2:
          return `${t('common:numbers.withCapitalLetter.two')} ${t(
            'documents:documentMetaData.multiSelect.placeholderPlural'
          )}`;
        case 3:
          return `${t('common:numbers.withCapitalLetter.three')} ${t(
            'documents:documentMetaData.multiSelect.placeholderPlural'
          )}`;
        case 4:
          return `${t('common:numbers.withCapitalLetter.four')} ${t(
            'documents:documentMetaData.multiSelect.placeholderPlural'
          )}`;
        case 5:
          return `${t('common:numbers.withCapitalLetter.five')} ${t(
            'documents:documentMetaData.multiSelect.placeholderPlural'
          )}`;
        case 6:
          return `${t('common:numbers.withCapitalLetter.six')} ${t(
            'documents:documentMetaData.multiSelect.placeholderPlural'
          )}`;
        case 7:
          return `${t('common:numbers.withCapitalLetter.seven')} ${t(
            'documents:documentMetaData.multiSelect.placeholderPlural'
          )}`;
        case 8:
          return `${t('common:numbers.withCapitalLetter.eight')} ${t(
            'documents:documentMetaData.multiSelect.placeholderPlural'
          )}`;
        case 9:
          return `${t('common:numbers.withCapitalLetter.nine')} ${t(
            'documents:documentMetaData.multiSelect.placeholderPlural'
          )}`;
        case 10:
          return `${t('common:numbers.withCapitalLetter.ten')} ${t(
            'documents:documentMetaData.multiSelect.placeholderPlural'
          )}`;
        case 11:
          return `${t('common:numbers.withCapitalLetter.eleven')} ${t(
            'documents:documentMetaData.multiSelect.placeholderPlural'
          )}`;
        case 12:
          return `${t('common:numbers.withCapitalLetter.twelve')} ${t(
            'documents:documentMetaData.multiSelect.placeholderPlural'
          )}`;
        default:
          return `${numberOfSelectedItems} ${t(
            'documents:documentMetaData.multiSelect.placeholderPlural'
          )}`;
      }
    },
    [t]
  );

  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div className={classNames(classes.root, className, classes.fullSize)}>
      {selectedDriveItems.length === 0 ? (
        <div
          className={classNames(classes.fullSize, {
            [classes.checkerboardBackground]:
              !isLoading && preview?.type === 'image',
          })}
        >
          <Center>
            {!isLoading ? (
              preview ? (
                preview.type === 'image' ? (
                  <img
                    alt=""
                    style={{ zIndex: 9999, maxWidth: '60%', maxHeight: '100%' }}
                    src={preview.src}
                  />
                ) : (
                  <iframe
                    title={preview.item.name}
                    style={{ flex: 3 }}
                    src={preview.src}
                    frameBorder="0"
                    className={classes.thumbnailImageContainer}
                  />
                )
              ) : (
                <Typography.Text
                  style={{
                    color: theme.colors.application.typography.inverse,
                  }}
                >
                  {t('documents:errorMessages.loadPreviewError')}
                </Typography.Text>
              )
            ) : (
              <PrioSpinner />
            )}
          </Center>
        </div>
      ) : (
        <Flex.Column
          className={classes.fullSize}
          justifyContent="center"
          alignItems="center"
          childrenGap={theme.spacing.large}
        >
          <FontAwesomeIcon
            icon={['fal', selectedDriveItems.length === 1 ? 'file' : 'files']}
            size="5x"
            color={theme.colors.application.typography.muted}
          />
          <div>{generateMultiSelectString(selectedDriveItems.length)}</div>
        </Flex.Column>
      )}
    </div>
  );
};

export default PreviewModalPreviewComponent;
