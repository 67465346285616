import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification, Select } from 'antd';
import { Button } from '@prio365/prio365-react-library';

import {
  getAllContacts,
  getContactsByIdState,
  getContactsIsFetching,
  getInternalProjectContactsIsFetching,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import { Contact } from '../../../models/Contact';
import {
  ContactId,
  ProjectId,
  ContactType,
  OfficeId,
  DriveItemId,
} from '../../../models/Types';
import { isTemporaryId } from '../../../util';
import {
  internalOfficeContactContactSelector,
  internalProjectContactContactSelector,
} from '../../projects/selectors';
import Flex from '../../../components/Flex';
import { useTranslation } from 'react-i18next';
import { apiSearchContacts } from '../api';
import useDebounce from '../../../hooks/useDebounce';
import PrioSpinner from '../../../components/PrioSpinner';
import { fetchInternalProjectContacts } from '../../projects/actions';
import { useTheme } from '@prio365/prio365-react-library/lib/ThemeProvider';
import { PrioTheme } from '../../../theme/types';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  noneStateLabel: {
    fontWeight: 400,
  },
  completeStateLabel: {
    fontWeight: 500,
  },
  partialStateLabel: {
    color: 'grey',
  },
  noneStateOption: {
    backgroundColor: '#ffffff!important',
  },
  partialStateOption: {
    backgroundColor: '#106ebe08!important',
    '&::before': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: 3,
      backgroundColor: '#106ebe40',
    },
  },
  completeStateOption: {
    '&::before': {
      content: "''",
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      width: 3,
      backgroundColor: theme.old.palette.primaryColor,
    },
  },
  hideCheckIcon: {
    '& .ant-select-item-option-state': {
      visibility: 'hidden',
    },
  },
  hover: {
    '&:hover': {
      backgroundColor: '#f5f5f5!important',
    },
  },
  textOverflowEllipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const getContactState = (
  parsedContacts: { contactId: ContactId; numberOfUses: number }[],
  contact: Contact,
  numberOfSelectedDriveItems: number
) => {
  const numberOfUses = parsedContacts.find(
    (parsedContact) => parsedContact.contactId === contact.contactId
  )?.numberOfUses;

  if (numberOfUses === 0) return 'none';
  if (numberOfUses === numberOfSelectedDriveItems) return 'complete';
  if (numberOfUses > 0 && numberOfUses < numberOfSelectedDriveItems)
    return 'partial';
  return 'none';
};

interface ById {
  [key: string]: string[];
}

interface ContactPickerMultiSelectProps {
  parsedValues: ById;
  setParsedValues: (value: ById) => void;
  selectDriveItemIds: DriveItemId[];
  placeholderLabel?: string;
  onSelect?: (value: string, option: any) => void;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  excludedContactIds?: ContactId[];
  includedContacts?: Contact[];
  onlyInternalProject?: boolean;
  contactType?: ContactType;
  projectId?: ProjectId;
  officeId?: OfficeId;
  fetch?: boolean;
  loading?: boolean;
  allowClear?: boolean;
  filter?: (contact: Contact) => boolean;
}

export const ContactPickerMultiSelect: React.FC<ContactPickerMultiSelectProps> =
  React.memo((props) => {
    //#region ------------------------------ Defaults
    const {
      className,
      parsedValues,
      setParsedValues,
      selectDriveItemIds,
      style,
      placeholderLabel,
      onSelect,
      disabled,
      excludedContactIds,
      onlyInternalProject: onlyInternal,
      contactType,
      projectId,
      officeId,
      loading,
      filter,
      includedContacts,
      allowClear,
    } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme() as PrioTheme;
    const classes = useStyles();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const contactsByIdState = useSelector(getContactsByIdState);
    const allContacts: Contact[] = useSelector<RootReducerState, Contact[]>(
      (state) =>
        onlyInternal && projectId
          ? internalProjectContactContactSelector(projectId)(state)
          : contactType === 'InternalContact' && officeId
          ? internalOfficeContactContactSelector(officeId)(state)
          : getAllContacts(state)
    );
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [isOnline, setIsOnline] = useState<boolean>(false);

    const debouncedSearchTerm = useDebounce(searchTerm, 1000);

    const [onlineData, setOnlineData] = useState<Contact[]>([]);
    const [onlineLoading, setOnlineLoading] = useState<boolean>(false);

    const isFetching = useSelector<RootReducerState, boolean>((state) =>
      onlyInternal
        ? getInternalProjectContactsIsFetching(state)
        : getContactsIsFetching(state)
    );

    const contacts: Contact[] = useMemo(() => {
      const list = !isOnline ? allContacts : onlineData;
      if (contactType) {
        return list.filter((c) => c.contactType === contactType);
      } else {
        return list;
      }
    }, [contactType, allContacts, onlineData, isOnline]);

    const contactsToShow: Contact[] = useMemo(
      () =>
        contacts
          .filter(
            (c) =>
              !isTemporaryId(c.contactId) &&
              (!excludedContactIds?.includes(c.contactId) ?? true) &&
              (!filter || filter(c)) &&
              !includedContacts?.find((iC) => iC.contactId === c.contactId)
          )
          .concat(includedContacts ?? [])
          .sort((a, b) => (a?.lastName ?? '').localeCompare(b?.lastName ?? '')),
      [contacts, excludedContactIds, includedContacts, filter]
    );

    const selectedValues: ContactId[] = useMemo(
      () =>
        Object.keys(parsedValues).sort(
          (a, b) =>
            contactsByIdState[a]?.lastName.localeCompare(
              contactsByIdState[b]?.lastName
            )
        ),
      [parsedValues, contactsByIdState]
    );

    const parsedContacts: { contactId: ContactId; numberOfUses: number }[] =
      useMemo(
        () =>
          Object.entries(parsedValues).reduce((acc, [key, value]) => {
            return [
              ...acc,
              {
                contactId: key,
                numberOfUses: (value as string[]).length,
              },
            ];
          }, []),
        [parsedValues]
      );
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const handleOnChange = (value: string[]) => {
      const initialContacts = Object.keys(parsedValues);
      const newContacts = value.filter(
        (contacts) => !initialContacts.includes(contacts)
      );
      const removedContacts = initialContacts.filter(
        (contacts) => !value.includes(contacts)
      );

      if (newContacts.length > 0) {
        const newParsedValues = newContacts.reduce((acc, contacts) => {
          return {
            ...acc,
            [contacts]: selectDriveItemIds,
          };
        }, parsedValues);
        setParsedValues(newParsedValues);
      }

      if (removedContacts.length > 0) {
        const newParsedValues = removedContacts.reduce((acc, contacts) => {
          const { [contacts]: removed, ...rest } = acc;
          return rest;
        }, parsedValues);
        setParsedValues(newParsedValues);
      }
    };

    const renderOption = (item: {
      label: string;
      contactId: ContactId;
      state: 'none' | 'partial' | 'complete';
    }) => {
      return (
        <div
          className={classNames({
            [classes.noneStateLabel]: item.state === 'none',
            [classes.partialStateLabel]: item.state === 'partial',
            [classes.completeStateLabel]: item.state === 'complete',
          })}
          title={item.label}
        >
          <div className={classes.textOverflowEllipsis}>{item.label}</div>
        </div>
      );
    };
    //#endregion

    //#region ------------------------------ Effects

    useEffect(() => {
      if (
        isOnline &&
        debouncedSearchTerm !== '' &&
        debouncedSearchTerm.length > 2
      ) {
        const searchOnline = async () => {
          setOnlineLoading(true);
          const { result, data } = await apiSearchContacts(
            debouncedSearchTerm,
            contactType
          );
          if (result.status >= 200 && result.status < 300) {
            setOnlineData(data);
          } else {
            notification.open({
              message: t('common:error'),
              description: t('contacts:errorMessages.fetchError'),
            });
          }
          setOnlineLoading(false);
        };
        searchOnline();
      }
    }, [debouncedSearchTerm, isOnline, t, contactType]);

    useEffect(() => {
      if (projectId && onlyInternal) {
        dispatch(fetchInternalProjectContacts(projectId));
      }
    }, [projectId, onlyInternal, dispatch]);
    //#endregion

    return (
      <Select
        className={className}
        style={{
          ...(style ?? {}),
          ...(disabled || isFetching
            ? { backgroundColor: 'rgb(0,0,0,0.05)' }
            : {}),
        }}
        mode={'multiple'}
        showSearch
        value={selectedValues}
        placeholder={placeholderLabel}
        onChange={handleOnChange}
        onSelect={onSelect}
        disabled={disabled || isFetching}
        loading={loading || isFetching}
        allowClear={allowClear}
        onSearch={(value: string) => {
          if (value === '') {
            setIsOnline(false);
            setOnlineData([]);
          }
          setSearchTerm(value);
        }}
        dropdownRender={(menu) => (
          <Flex.Column>
            {onlineLoading && (
              <div
                style={{
                  background: '#FFFFFFBF',
                  position: 'absolute',
                  top: 0,
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 1,
                }}
              >
                <PrioSpinner />
              </div>
            )}
            {menu}
            {!isOnline && searchTerm !== '' && (
              <Button
                type="link"
                onClick={() => {
                  setIsOnline(true);
                }}
                iconProp={['fal', 'plus']}
                style={{
                  width: '100%',
                  borderTop: theme.old.borders.content,
                  paddingTop: 4,
                  height: 32,
                }}
              >
                {t('contacts:actions.showMore')}
              </Button>
            )}
          </Flex.Column>
        )}
        options={contactsToShow.map((contact) => {
          const state = getContactState(
            parsedContacts,
            contact,
            selectDriveItemIds.length
          );

          return {
            value: contact.contactId,
            label: renderOption({
              label: `${contact.firstName} ${contact.lastName}`,
              contactId: contact.contactId,
              state: getContactState(
                parsedContacts,
                contact,
                selectDriveItemIds.length
              ),
            }),
            className: classNames(
              {
                [classes.noneStateOption]: state === 'none',
                [classes.partialStateOption]: state === 'partial',
                [classes.completeStateOption]: state === 'complete',
              },
              classes.hideCheckIcon,
              classes.hover
            ),
          };
        })}
      ></Select>
    );
  });

export default ContactPickerMultiSelect;
