import React, { forwardRef, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Dropdown, Menu, Modal } from 'antd';
import { Button } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../../theme/utils';
import { useTheme } from 'react-jss';
import {
  CompensationPayment,
  CompensationPaymentsCalculatedData,
  CompensationPaymentSearchResultItem,
} from '../../../../models/TimeKeeping';
import moment from 'moment';
import ContactText from '../../../contacts/components/ContactText';
import HREditCompensationPaymentDrawer from './HREditCompensationPaymentDrawer';
import Flex from '../../../../components/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrioTheme } from '../../../../theme/types';
import {
  apiDeleteCompensationPayment,
  apiPayCompensationPayment,
} from '../../../timeKeeping/api';
import useFilterContext from '../../../../components/Filter/hooks/useFilterContext';
import useContactsContext from '../../../contacts/hooks/useContactsProvider';
import FilterResultNoItemsScreen from '../../../../components/Filter/FilterResultNoItemsScreen';
import { Column } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTable';
import { VirtualListItemOnRowProps } from '@prio365/prio365-react-library/lib/VirtualList/components/VirtualListItem';
import { useQueryClient } from '@tanstack/react-query';
import { OptimisticWriteGenericSearchResultItem } from '../../../../components/Filter/types';
import { useSelector } from 'react-redux';
import { getUserMe } from '../../../../apps/main/rootReducer';
import FilterContextVirtualTable from '../../../../components/Filter/FilterContextVirtualTable';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    '& svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa': {
      width: '14px',
    },
    '& .cpt1-menu-header': {
      visibility: 'hidden',
    },
  },
  cell: {
    display: 'flex',
    alignItems: 'center',
  },
  cellCenter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  lastCell: {
    padding: '10px 0px 10px 2px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  row: { cursor: 'pointer' },
  menuButton: {
    backgroundColor: 'transparent',
    height: '100%',
    '& > .prio-button-icon': {
      color: theme.old.typography.colors.base,
    },
    '&:hover': {
      backgroundColor: theme.old.components.table.menuButton.backgroundColor,
      color: theme.old.components.table.menuButton.color,
      '& > .prio-button-icon': {
        color: theme.old.typography.colors.base,
      },
    },
  },
}));

export interface HRCompensationPaymentsPageTableRefProps {
  getSelectedCompensationPayments: () => CompensationPayment[];
  fetchCompensationPayments: () => void;
}

interface HRCompensationPaymentsTableProps {
  className?: string;
}

export const HRCompensationPaymentsTable = forwardRef(
  (
    props: HRCompensationPaymentsTableProps,
    ref: React.Ref<HRCompensationPaymentsPageTableRefProps>
  ) => {
    //#region ------------------------------ Defaults
    const { className } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme<PrioTheme>();
    const queryClient = useQueryClient();
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const { getContactById } = useContactsContext();
    const userMe = useSelector(getUserMe);

    const { data, isLoading, optimisticWrite } = useFilterContext<
      CompensationPayment,
      CompensationPaymentsCalculatedData
    >();

    const compensationPayments: CompensationPaymentSearchResultItem[] =
      useMemo(() => {
        if (data) {
          return data.items;
        }
        return [];
      }, [data]);

    const tableData = compensationPayments.sort((pA, pB) => {
      const employeeIdA = pA.data.employeeId;
      const employeeIdB = pB.data.employeeId;
      const a = getContactById(employeeIdA?.toLowerCase() ?? '');
      const b = getContactById(employeeIdB?.toLowerCase() ?? '');
      const lastNameCompare = a?.lastName?.localeCompare(b?.lastName);
      if (lastNameCompare !== 0) return lastNameCompare;
      return a?.firstName?.localeCompare(b?.firstName);
    });

    const [searchItemToEdit, setCompensationPaymentToEdit] =
      useState<CompensationPaymentSearchResultItem>(null);

    const [selectedSearchItems, setSelectedSearchItems] = useState<
      CompensationPaymentSearchResultItem[]
    >([]);
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const handleOnRow: (
      item: CompensationPaymentSearchResultItem
    ) => VirtualListItemOnRowProps = useCallback(
      (item) => {
        return {
          onClick: (e) => {
            setCompensationPaymentToEdit(item);
          },
          className: classes.row,
        };
      },
      [classes]
    );

    const onSelectionChange = (
      items: CompensationPaymentSearchResultItem[]
    ) => {
      setSelectedSearchItems(items);
    };

    const handleOnBulkPayOut = async () => {
      const optimisticWrites: OptimisticWriteGenericSearchResultItem<
        CompensationPayment,
        CompensationPaymentsCalculatedData
      >[] = selectedSearchItems
        .filter(({ data: { paidBy } }) => !paidBy)
        .map((item) => ({
          ...item,
          calculated: {
            ...item.calculated,
            isApproved: true,
            paidById: userMe?.id,
          },
          data: {
            ...item.data,
            payoutDate: moment().toISOString(true),
            paidBy: userMe?.id,
          },
          method: 'update',
          callback: async () => {
            const { result, data } = await apiPayCompensationPayment(
              item.data.compensationPaymentId,
              item.calculated.officeId
            );
            if (!result.ok) {
              return {
                result,
                data: null,
              };
            }
            return {
              result,
              data: {
                data,
                calculated: {
                  ...item.calculated,
                  isApproved: true,
                  paidById: userMe?.id,
                },
              },
            };
          },
        }));

      optimisticWrite(optimisticWrites);
      queryClient.invalidateQueries({
        queryKey: ['compensationPayments'],
        refetchType: 'all',
      });
      setSelectedSearchItems([]);
    };

    const handleOnBulkDelete = async () => {
      const optimisticWrites: OptimisticWriteGenericSearchResultItem<
        CompensationPayment,
        CompensationPaymentsCalculatedData
      >[] = selectedSearchItems
        .filter(({ data: { paidBy } }) => !paidBy)
        .map((item) => ({
          ...item,
          method: 'remove',
          callback: async () => {
            const response = await apiDeleteCompensationPayment(
              item.data.compensationPaymentId,
              item.calculated.officeId
            );
            if (!response.result.ok) {
              return {
                result: response.result,
                data: null,
              };
            }
            return {
              result: response.result,
              data: null,
            };
          },
        }));

      optimisticWrite(optimisticWrites);
      queryClient.invalidateQueries({
        queryKey: ['compensationPayments'],
        refetchType: 'all',
      });
      setSelectedSearchItems([]);
    };

    const onModalCancelAction = () => {
      setCompensationPaymentToEdit(null);
    };

    const onPayOutModalOkAction = useCallback(
      (item: CompensationPaymentSearchResultItem) => {
        const payCompensationPayment = async (
          item: CompensationPaymentSearchResultItem
        ) => {
          const response = await apiPayCompensationPayment(
            item.data.compensationPaymentId,
            item.calculated.officeId
          );

          if (!response.result.ok) {
            return {
              result: response.result,
              data: null,
            };
          }
          return {
            result: response.result,
            data: {
              data: response.data,
              calculated: {
                ...item.calculated,
                isApproved: true,
                paidById: userMe?.id,
              },
            },
          };
        };

        optimisticWrite([
          {
            data: {
              ...item.data,
              payoutDate: moment().toISOString(true),
              paidBy: userMe?.id,
            },
            calculated: {
              ...item.calculated,
              isApproved: true,
              paidById: userMe?.id,
            },
            callback: async () => await payCompensationPayment(item),
            method: 'update',
          },
        ]);

        queryClient.invalidateQueries({
          queryKey: ['compensationPayments'],
          refetchType: 'all',
        });
        setCompensationPaymentToEdit(null);
      },

      [userMe?.id, queryClient, optimisticWrite]
    );

    const onDeleteModalOkAction = async (
      item: CompensationPaymentSearchResultItem
    ) => {
      const deleteCompensationPayment = async () => {
        const response = await apiDeleteCompensationPayment(
          item.data.compensationPaymentId,
          item.calculated.officeId
        );
        if (!response.result.ok) {
          return {
            result: response.result,
            data: null,
          };
        }
        return {
          result: response.result,
          data: null,
        };
      };

      optimisticWrite([
        {
          ...item,
          data: item.data,
          method: 'remove',
          callback: deleteCompensationPayment,
        },
      ]);
      queryClient.invalidateQueries({
        queryKey: ['compensationPayments'],
        refetchType: 'all',
      });
      setCompensationPaymentToEdit(null);
    };

    const onEditDrawerSaveAction = () => {
      setCompensationPaymentToEdit(null);
    };
    //#endregion

    //#region ------------------------------ Components
    const menu = (item: CompensationPaymentSearchResultItem) => {
      const { data: payment } = item;
      const payoutDate = payment.payoutDate;
      const disableActionButton = !(
        payoutDate === null || payoutDate === '0001-01-01T00:00:00'
      );
      return (
        <Menu
          onClick={(e) => {
            e.domEvent.stopPropagation();
          }}
        >
          <Menu.Item
            disabled={disableActionButton}
            onClick={(e) => {
              e.domEvent.preventDefault();
              showPayOutSingleCompensationPaymentModal(item);
            }}
          >
            {t(
              'hr:timeAndLeaveManagement.compensationPaymentsTable.actions.payOut'
            )}
          </Menu.Item>
          <Menu.Item
            disabled={disableActionButton}
            onClick={(e) => {
              e.domEvent.preventDefault();
              setCompensationPaymentToEdit(item);
            }}
          >
            {t(
              'hr:timeAndLeaveManagement.compensationPaymentsTable.actions.edit'
            )}
          </Menu.Item>
          <Menu.Item
            disabled={disableActionButton}
            onClick={(e) => {
              e.domEvent.preventDefault();
              showDeleteSingleCompensationPaymentModal(item);
            }}
          >
            {t(
              'hr:timeAndLeaveManagement.compensationPaymentsTable.actions.delete'
            )}
          </Menu.Item>
        </Menu>
      );
    };
    //#endregion

    //#region ------------------------------ Modals
    const showDeleteSingleCompensationPaymentModal = (
      item: CompensationPaymentSearchResultItem
    ) => {
      Modal.confirm({
        icon: null,
        title: t(`hr:timeAndLeaveManagement.deleteModalSingle.title`),
        content: t(`hr:timeAndLeaveManagement.deleteModalSingle.content`),
        okText: t(`hr:timeAndLeaveManagement.deleteModalSingle.okText`),
        cancelText: t(`hr:timeAndLeaveManagement.deleteModalSingle.cancelText`),
        onOk: () => onDeleteModalOkAction(item),
        onCancel: onModalCancelAction,
      });
    };

    const showPayOutSingleCompensationPaymentModal = (
      item: CompensationPaymentSearchResultItem
    ) => {
      Modal.confirm({
        icon: null,
        title: t(`hr:timeAndLeaveManagement.payOutModalSingle.title`),
        content: t(`hr:timeAndLeaveManagement.payOutModalSingle.content`),
        okText: t(`hr:timeAndLeaveManagement.payOutModalSingle.okText`),
        cancelText: t(`hr:timeAndLeaveManagement.payOutModalSingle.cancelText`),
        onOk: () => onPayOutModalOkAction(item),
        onCancel: onModalCancelAction,
      });
    };

    const showPayOutBulkModal = () => {
      const translationSuffix =
        selectedSearchItems.length > 1 ? 'Multiple' : 'Single';
      Modal.confirm({
        icon: null,
        title: t(
          `hr:timeAndLeaveManagement.payOutModal${translationSuffix}.title`
        ),
        content: t(
          `hr:timeAndLeaveManagement.payOutModal${translationSuffix}.content`
        ),
        okText: t(
          `hr:timeAndLeaveManagement.payOutModal${translationSuffix}.okText`
        ),
        cancelText: t(
          `hr:timeAndLeaveManagement.payOutModal${translationSuffix}.cancelText`
        ),
        onOk: handleOnBulkPayOut,
        onCancel() {},
      });
    };

    const showDeleteBulkModal = () => {
      const translationSuffix =
        selectedSearchItems.length > 1 ? 'Multiple' : 'Single';
      Modal.confirm({
        icon: null,
        title: t(
          `hr:timeAndLeaveManagement.deleteModal${translationSuffix}.title`
        ),
        content: t(
          `hr:timeAndLeaveManagement.deleteModal${translationSuffix}.content`
        ),
        okText: t(
          `hr:timeAndLeaveManagement.deleteModal${translationSuffix}.okText`
        ),
        cancelText: t(
          `hr:timeAndLeaveManagement.deleteModal${translationSuffix}.cancelText`
        ),
        onOk: handleOnBulkDelete,
        onCancel() {},
      });
    }; //#endregion

    //#region ------------------------------ Columns
    const columns: Column<CompensationPaymentSearchResultItem>[] = [
      {
        Cell: ({
          originalData: {
            data: { payoutDate },
          },
        }) => {
          return (
            !(payoutDate === null || payoutDate === '0001-01-01T00:00:00') && (
              <div
                title={t(
                  'hr:timeAndLeaveManagement.compensationPaymentsTable.paidOut'
                )}
              >
                <FontAwesomeIcon icon={['fal', 'check']} />
              </div>
            )
          );
        },
        title: '',
        width: 3,
        minWidth: 34,
        id: 'status',
        accessor: 'data.payoutDate',
        className: classNames(classes.cell, classes.cellCenter),
        alignSelf: true,
        sortingFn: (rowA, rowB) => {
          const paymentA = !!rowA.data.payoutDate;
          const paymentB = !!rowB.data.payoutDate;
          if (paymentA === paymentB) return 0;
          if (paymentA) return -1;
          return 1;
        },
      },
      {
        id: 'employeeId',
        width: 18,
        title: t(
          'hr:timeAndLeaveManagement.compensationPaymentsTable.employeeId'
        ),
        accessor: 'data.employeeId',
        alignSelf: true,
        sortingFn: (rowA, rowB) => {
          const employeeIdA = rowA.data.employeeId;
          const employeeIdB = rowB.data.employeeId;
          const a = getContactById(employeeIdA?.toLowerCase() ?? '');
          const b = getContactById(employeeIdB?.toLowerCase() ?? '');
          const lastNameCompare = a?.lastName?.localeCompare(b?.lastName);
          if (lastNameCompare !== 0) return lastNameCompare;
          return a?.firstName?.localeCompare(b?.firstName);
        },
        Cell: ({
          originalData: {
            data: { employeeId },
          },
        }) => {
          if (!employeeId) {
            return '';
          }
          return <ContactText contactId={employeeId} />;
        },
        className: classes.cell,
      },
      {
        sortingFn: (a, b) => {
          return a.data.hours - b.data.hours;
        },
        Cell: ({
          originalData: {
            data: { hours },
          },
        }) => {
          return <Flex.Row justifyContent="center">{hours}</Flex.Row>;
        },
        title: t('hr:timeAndLeaveManagement.compensationPaymentsTable.hours'),
        width: 8,
        id: 'hours',
        accessor: 'data.hours',
        className: classes.cell,
        alignSelf: true,
      },
      {
        sortingFn: (a, b) => {
          return moment(a.data.expectedPayoutDate).diff(
            moment(b.data.expectedPayoutDate)
          );
        },
        Cell: ({
          originalData: {
            data: { expectedPayoutDate },
          },
        }) => {
          if (
            expectedPayoutDate &&
            !expectedPayoutDate.includes('0001-01-01T00:00:00')
          ) {
            return moment(expectedPayoutDate).format('DD.MM.YYYY');
          }
          return <Flex.Row justifyContent="center">-</Flex.Row>;
        },
        title: t(
          'hr:timeAndLeaveManagement.compensationPaymentsTable.expectedPayoutDate'
        ),
        width: 11,
        id: 'expectedPayoutDate',
        accessor: 'data.expectedPayoutDate',
        className: classes.cell,
        alignSelf: true,
      },
      {
        sortingFn: (a, b) => {
          return moment(a.data.payoutDate).diff(moment(b.data.payoutDate));
        },
        Cell: ({
          originalData: {
            data: { payoutDate },
          },
        }) => {
          if (payoutDate && !payoutDate.includes('0001-01-01T00:00:00')) {
            return moment(payoutDate).format('DD.MM.YYYY');
          }
          return <Flex.Row justifyContent="center">-</Flex.Row>;
        },
        title: t(
          'hr:timeAndLeaveManagement.compensationPaymentsTable.payoutDate'
        ),
        width: 10,
        id: 'payoutDate',
        accessor: 'data.payoutDate',
        className: classes.cell,
        alignSelf: true,
      },
      {
        Cell: ({
          originalData: {
            data: { paidBy },
          },
        }) => {
          return paidBy ? (
            <ContactText contactId={paidBy} />
          ) : (
            <div style={{ marginLeft: theme.spacing.large }}>-</div>
          );
        },
        title: t('hr:timeAndLeaveManagement.compensationPaymentsTable.paidBy'),
        width: 18,
        id: 'paidBy',
        accessor: 'data.paidBy',
        className: classes.cell,
        alignSelf: true,
        sortingFn: (rowA, rowB) => {
          const a = getContactById(rowA.data.paidBy?.toLowerCase() ?? '');
          const b = getContactById(rowB.data.paidBy?.toLowerCase() ?? '');
          const lastNameCompare = a?.lastName?.localeCompare(b?.lastName);
          if (lastNameCompare !== 0) return lastNameCompare;
          return a?.firstName?.localeCompare(b?.firstName);
        },
      },
      {
        sortingFn: (a, b) => {
          return moment(a.data.createdDate).diff(moment(b.data.createdDate));
        },
        Cell: ({
          originalData: {
            data: { createdDate },
          },
        }) => {
          return moment(createdDate).format('DD.MM.YYYY');
        },
        title: t(
          'hr:timeAndLeaveManagement.compensationPaymentsTable.createdDate'
        ),
        width: 10,
        id: 'createdDate',
        accessor: 'data.createdDate',
        className: classes.cell,
        alignSelf: true,
      },
      {
        Cell: ({
          originalData: {
            data: { createdBy },
          },
        }) => {
          return (
            <Flex.Row
              alignItems="center"
              childrenGap={theme.old.spacing.defaultPadding}
            >
              <Flex.Item flex={1}>
                <ContactText contactId={createdBy} />
              </Flex.Item>
            </Flex.Row>
          );
        },
        title: t(
          'hr:timeAndLeaveManagement.compensationPaymentsTable.createdBy'
        ),
        width: 17,
        alignSelf: true,
        id: 'createdBy',
        accessor: 'data.createdBy',
        className: classes.cell,
        sortingFn: (rowA, rowB) => {
          const a = getContactById(rowA.data.createdBy?.toLowerCase() ?? '');
          const b = getContactById(rowB.data.createdBy?.toLowerCase() ?? '');
          const lastNameCompare = a?.lastName?.localeCompare(b?.lastName);
          if (lastNameCompare !== 0) return lastNameCompare;
          return a?.firstName?.localeCompare(b?.firstName);
        },
      },
      {
        Cell: ({ originalData }) => {
          return (
            <Flex.Row
              alignItems="center"
              childrenGap={theme.old.spacing.defaultPadding}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Dropdown
                overlay={menu(originalData)}
                trigger={['click']}
                placement="bottomRight"
              >
                <Button
                  iconProp={['fal', 'ellipsis-v']}
                  className={classes.menuButton}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
              </Dropdown>
            </Flex.Row>
          );
        },
        title: '',
        width: 4,
        minWidth: 50,
        id: 'menu',
        accessor: 'data.monthlyCloseId',
        className: classes.lastCell,
        alignSelf: true,
      },
    ];
    //#endregion

    //#region ------------------------------ Effects
    //#endregion

    return (
      <div className={classNames(classes.root, className)}>
        <FilterContextVirtualTable<CompensationPaymentSearchResultItem>
          id={'cpt1'}
          className={classNames(classes.root, className)}
          columns={columns}
          data={tableData}
          selectedItems={selectedSearchItems}
          resizable="relative"
          onRow={handleOnRow}
          classNameTableRow={classes.row}
          onSelectionChange={onSelectionChange}
          onCheckEquality={(a, b) =>
            a.data.compensationPaymentId === b.data.compensationPaymentId
          }
          noItemsScreen={<FilterResultNoItemsScreen />}
          loading={
            isLoading && {
              type: 'noItems',
            }
          }
          rowsAreSelectable
          actionBarButtons={[
            {
              children: t(
                'hr:timeAndLeaveManagement.navigationBar.compensationPayments.payCompensationPayment'
              ),
              iconProp: ['fal', 'check'],
              onClick: () => {
                selectedSearchItems.length === 1
                  ? showPayOutSingleCompensationPaymentModal(
                      selectedSearchItems[0]
                    )
                  : showPayOutBulkModal();
              },
            },
            {
              children: t('common:actions.delete'),
              iconProp: ['fal', 'trash'],
              onClick: () => {
                selectedSearchItems.length === 1
                  ? showDeleteSingleCompensationPaymentModal(
                      selectedSearchItems[0]
                    )
                  : showDeleteBulkModal();
              },
            },
          ]}
        />
        <HREditCompensationPaymentDrawer
          drawerOpen={!!searchItemToEdit}
          searchItem={searchItemToEdit}
          onSave={onEditDrawerSaveAction}
          onCancel={onModalCancelAction}
          onPayOut={showPayOutSingleCompensationPaymentModal}
          onDelete={showDeleteSingleCompensationPaymentModal}
        />
      </div>
    );
  }
);

export default HRCompensationPaymentsTable;
