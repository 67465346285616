import React from 'react';
import { OfficeId } from '../../../models/Types';
import { HRAbsenceProposalPage } from './HRAbsenceProposalPage';
import { DefaultSearchParameterItem } from '../../../components/Filter/FilterBar';

interface HRAbsenceAllProposalPageProps {
  className?: string;
  officeId?: OfficeId;
}

export const HRAbsenceAllProposalPage: React.FC<
  HRAbsenceAllProposalPageProps
> = ({ className, officeId }) => {
  const customDefaultSearchParameters: DefaultSearchParameterItem[] = [
    ...(officeId
      ? [
          {
            parameterName: 'Data.OfficeId',
            defaultValue: officeId,
            defaultMethod: 'eq',
          },
        ]
      : []),
  ];

  return (
    <HRAbsenceProposalPage
      className={className}
      officeId={officeId}
      customDefaultSearchParameters={customDefaultSearchParameters}
    />
  );
};

export default HRAbsenceAllProposalPage;
