import React, { CSSProperties, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import {} from 'antd';
import { Classes } from 'jss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlagStatus, Message } from '../../../../models/Message';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../../theme/types';
import { MailListItemClassNames } from './MailListItem';
import { MailFolderId, ProjectId } from '../../../../models/Types';
import { useDispatch } from 'react-redux';
import {
  flagMessage,
  markAsRead,
} from '../../actions/actionControllers/messageActionController';
import { setUnreadItemCount } from '../../actions/actionControllers/mailFoldersController';
import { deleteMessageSagaAction } from '../../actions/sagas';
import { useNavigate } from 'react-router-dom';
import { apiCreateMessageReply } from '../../api';

interface QuickActionsProps {
  className?: string;
  style?: CSSProperties;
  classes: Classes<MailListItemClassNames>;
  message: Message;
  projectId: ProjectId;
  mailFolderId: MailFolderId;
  getInboxFolderId: (projectId: ProjectId) => MailFolderId;
  onDeselect: (message: Message) => void;
  loading?: boolean;
}

export const QuickActions: React.FC<QuickActionsProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    classes,
    style,
    message,
    projectId,
    mailFolderId,
    loading,
    getInboxFolderId,
    onDeselect,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [replyActionWait, setReplyActionWait] = useState<boolean>(false);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleReply = async () => {
    if (!replyActionWait) {
      setReplyActionWait(true);
      const { data } = await apiCreateMessageReply(
        projectId,
        message.id,
        'Reply'
      );
      setReplyActionWait(false);
      if (data && data.id) {
        const width = window.screen.availWidth / 2;
        const height = window.screen.availHeight * 0.6;
        window.open(
          `/view/${projectId}/${data.isDraft ? 'composer' : 'message'}/${
            data.id
          }${data.isDraft ? '' : '/details'}`,
          '_blank',
          `width=${width},height=${height},top=${height / 4}`
        );
      }
    }
  };

  const handleRead = () => {
    dispatch(
      markAsRead(projectId, [
        { messageId: message.id, isRead: !message.isRead },
      ])
    );
    if (mailFolderId === 'inbox') {
      const inboxFolderId = getInboxFolderId(projectId);
      dispatch(
        setUnreadItemCount(
          projectId,
          inboxFolderId,
          1,
          !message.isRead ? 'remove' : 'add'
        )
      );
    } else {
      dispatch(
        setUnreadItemCount(
          projectId,
          mailFolderId,
          1,
          !message.isRead ? 'remove' : 'add'
        )
      );
    }
  };

  const handleFlag = () => {
    let flagStatus: FlagStatus;
    switch (message.flag.flagStatus) {
      case 'NotFlagged':
        flagStatus = 'Flagged';
        break;
      case 'Flagged':
        flagStatus = 'Complete';
        break;
      case 'Complete':
        flagStatus = 'Flagged';
        break;
    }
    dispatch(
      flagMessage(
        projectId,
        [{ messageId: message.id, flag: { flagStatus } }],
        [{ messageId: message.id, flag: message.flag }]
      )
    );
  };

  const handleDelete = () => {
    dispatch(
      deleteMessageSagaAction(projectId, mailFolderId, [message], {
        navigate,
      })
    );
    onDeselect(message);
  };
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <div
      className={classNames(classes.flexRow, className)}
      onDoubleClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      style={style}
    >
      <div
        className={classes.informationIconRow}
        style={{ flex: 1, overflow: 'hidden', marginRight: 0 }}
      >
        {message.meetingMessageType &&
          message.meetingMessageType !== 'None' && (
            <div className={classes.informationIcon}>
              <FontAwesomeIcon icon={['fal', 'calendar']} />
            </div>
          )}
        {message.customSingleValueExtendedProperties
          ?.lastEmailInteractionType && (
          <div className={classes.informationIcon}>
            <FontAwesomeIcon
              icon={[
                'fal',
                message.customSingleValueExtendedProperties
                  ?.lastEmailInteractionType === '104'
                  ? 'arrow-right'
                  : 'reply',
              ]}
            />
          </div>
        )}
        {message.hasAttachments && (
          <div className={classes.informationIcon}>
            <FontAwesomeIcon icon={['fal', 'paperclip']} />
          </div>
        )}
        {message.customSingleValueExtendedProperties?.messageTaskRelationId && (
          <div className={classes.informationIcon}>
            <FontAwesomeIcon icon={['fal', 'tasks']} />
          </div>
        )}
        {message.flag?.flagStatus !== 'NotFlagged' && (
          <div className={classes.informationIcon}>
            <FontAwesomeIcon
              icon={
                message.flag?.flagStatus === 'Complete'
                  ? ['fal', 'check']
                  : ['fas', 'flag']
              }
              color={
                message.flag?.flagStatus === 'Flagged'
                  ? theme.old.palette.chromaticPalette.red
                  : null
              }
            />
          </div>
        )}
        {(message.flag?.flagStatus !== 'NotFlagged' ||
          message.importance !== 'normal') && (
          <div className={classes.informationIcon}>
            {message.importance !== 'normal' && (
              <FontAwesomeIcon
                icon={[
                  'fal',
                  message.importance === 'high' ? 'exclamation' : 'arrow-down',
                ]}
                color={
                  message.importance === 'high'
                    ? theme.old.palette.chromaticPalette.red
                    : null
                }
              />
            )}
          </div>
        )}
      </div>
      {!loading && (
        <div
          className={classes.quickActionRow}
          style={{ flex: 1, overflow: 'hidden' }}
        >
          <div
            className={classNames(classes.quickAction, {
              [classes.quickActionDisabled]: replyActionWait,
            })}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleReply();
            }}
            title={t('mail:messageDisplay.reply')}
          >
            <FontAwesomeIcon icon={['fal', 'reply']} />
          </div>
          <div
            className={classes.quickAction}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleRead();
            }}
            title={t(
              `mail:messageDisplay.${
                message.isRead ? 'markAsUnread' : 'markAsRead'
              }`
            )}
          >
            <FontAwesomeIcon
              icon={['fal', message.isRead ? 'envelope' : 'envelope-open']}
            />
          </div>
          <div
            className={classes.quickAction}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleFlag();
            }}
            title={t('mail:messageDisplay.flag')}
          >
            <FontAwesomeIcon
              icon={
                message.flag?.flagStatus === 'Complete'
                  ? ['fal', 'check']
                  : [
                      message.flag?.flagStatus === 'NotFlagged' ? 'fal' : 'fas',
                      'flag',
                    ]
              }
              color={
                message.flag?.flagStatus === 'Flagged'
                  ? theme.old.palette.chromaticPalette.red
                  : null
              }
            />
          </div>
          <div
            className={classes.quickAction}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleDelete();
            }}
            title={t('mail:messageDisplay.delete')}
          >
            <FontAwesomeIcon icon={['fal', 'trash']} />
          </div>
        </div>
      )}
    </div>
  );
};

export default QuickActions;
