import { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Drawer } from '@prio365/prio365-react-library';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { EditTimeRecordContextType, OfficeRole } from '../../../models/Types';
import { makePrioStyles } from '../../../theme/utils';
import { TimeRecord, TimeRecordsFilter } from '../../../models/TimeRecord';
import { getCompaniesByIdState } from '../../../apps/main/rootReducer';
import { openTimeAndLeaveManagementDrawer } from '../../timeAndLeaveManagement/actions';
import { syncGlobalProjects } from '../../projects/actions';
import NavigationBar from '../../../components/NavigationBar';
import DocumentTemplateFormWrapper, {
  DocumentTemplateFormWrapperRef,
} from '../../documents/components/DocumentTemplateFrom/DocumentTemplateFormWrapper';
import TimeRecordsPage from './TimeRecordsPage';
import FilterContextProvider from '../../../components/Filter/FilterContextProvider';
import { FilterBar } from '../../../components/Filter/FilterBar';
import DocumentTemplateCategoryContextProvider from '../../documents/components/DocumentTemplateCategoryContextProvider';
import DocumentTemplateTagContextProvider from '../../documents/components/DocumentTemplateTagContextProvider';
import { DocumentTemplatePreview } from '../../documents/components/DocumentTemplateFrom/DocumentTemplatePreview';
import moment from 'moment';

const useStyles = makePrioStyles(() => ({
  root: {
    flex: 1,
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    width: '100%',
    overflow: 'hidden',
  },
}));

export interface TimeRecordsModuleRef {
  setDeleteSelectionModalVisible: (value: boolean) => void;
}

interface TimeRecordsModuleProps {
  contextType: EditTimeRecordContextType;
  officeRoles?: OfficeRole[];
  isNavBarUnvisible?: boolean;
  setSelectedTimeRecordsMe?: (value: string[]) => void;
}

export const TimeRecordsModule = forwardRef<
  TimeRecordsModuleRef,
  TimeRecordsModuleProps
>((props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const { t } = useTranslation();
  const formWrapperRef = useRef<DocumentTemplateFormWrapperRef>(null);
  const { contextType, officeRoles, isNavBarUnvisible } = props;

  const { officeId } = useParams();
  const dispatch = useDispatch();
  //#endregion
  //#region ------------------------------ States / Attributes / Selectors
  const companiesById = useSelector(getCompaniesByIdState);

  const [selectedTimeRecords, setSelectedTimeRecords] = useState<TimeRecord[]>(
    []
  );
  const [allTimeRecords, setAllTimeRecords] = useState<TimeRecord[]>([]);
  const [thumbnailURL, setThumbnailURL] = useState<String[]>([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const [dateRange, setDateRange] = useState<TimeRecordsFilter>({
    from: null,
    to: null,
  });
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const [reportDrawerVisible, setReportDrawerVisible] = useState(false);

  const onCreateDocumentSuccess = () => {
    setReportDrawerVisible(false);
  };
  const onStartCreateDocument = () => {
    setReportDrawerVisible(false);
  };

  const onCancelReportDrawer = () => {
    setReportDrawerVisible(false);
    setSelectedTemplate({});
    setThumbnailURL([]);
  };

  const showTimeRecordDrawer = () =>
    dispatch(openTimeAndLeaveManagementDrawer({ tab: 'timeRecords' }));

  const handleSearchStringChange = (searchString: string) => {
    const fromMatch = searchString.match(/Data\.Day\sge\s'([^']+)'/);
    const toMatch = searchString.match(/Data\.Day\sle\s'([^']+)'/);

    const newDateRange: TimeRecordsFilter = {
      from: fromMatch ? moment(fromMatch[1]) : null,
      to: toMatch ? moment(toMatch[1]) : null,
    };

    if (
      (newDateRange.from && !newDateRange.from.isSame(dateRange.from)) ||
      (newDateRange.to && !newDateRange.to.isSame(dateRange.to))
    ) {
      setDateRange(newDateRange);
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    dispatch(syncGlobalProjects());
  }, [dispatch]);
  //#endregion

  return (
    <div className={classes.root}>
      {!isNavBarUnvisible && (
        <NavigationBar>
          <Button iconProp={['fal', 'pencil']} onClick={showTimeRecordDrawer}>
            <span>{t('common:navigationBar.recordTime')}</span>
          </Button>
          <Button
            disabled={!(allTimeRecords?.length > 0)}
            onClick={() => setReportDrawerVisible(true)}
            iconProp={['fal', 'file-excel']}
            type="default"
          >
            <span>{t('timeRecords:navigationBar.createReport')}</span>
          </Button>
        </NavigationBar>
      )}

      <TimeRecordsPage
        className={classes.content}
        officeRoles={officeRoles}
        contextType={contextType}
        setSelectedTimeRecordsObjects={setSelectedTimeRecords}
        setTimeRecords={setAllTimeRecords}
        setReportDrawerVisible={setReportDrawerVisible}
        setSearchString={handleSearchStringChange}
      />

      <Drawer
        title={t('timeRecords:navigationBar.createReport')}
        closable={true}
        onClose={onCancelReportDrawer}
        visible={reportDrawerVisible}
        customWidth={800}
        footer={
          <div style={{ textAlign: 'right' }}>
            <Button type="default" onClick={onCancelReportDrawer}>
              {t('common:actions.cancel')}
            </Button>
            <Button
              onClick={() => formWrapperRef.current?.onFinish()}
              disabled={!isFormValid}
              style={{
                marginLeft: '12px',
              }}
            >
              {t('common:actions.save')}
            </Button>
          </div>
        }
        mask={
          <DocumentTemplatePreview
            thumbnailURL={thumbnailURL}
            selectedTemplate={selectedTemplate}
          />
        }
      >
        <DocumentTemplateTagContextProvider>
          <DocumentTemplateCategoryContextProvider>
            <FilterContextProvider searchType="documentTemplates">
              <FilterBar
                disabledPickers={['Data.IsReport']}
                disableURLQuery={true}
                customDefaultSearchParameters={[
                  {
                    parameterName: 'Data.IsReport',
                    defaultValue: 'true',
                    defaultMethod: 'eq',
                  },
                ]}
              />{' '}
              <DocumentTemplateFormWrapper
                showOnlyReports={true}
                timeRecords={
                  selectedTimeRecords.length > 0
                    ? selectedTimeRecords
                    : allTimeRecords
                }
                filter={dateRange}
                onSuccess={onCreateDocumentSuccess}
                onStartCreateDocument={onStartCreateDocument}
                onCancel={onCancelReportDrawer}
                onThumbnailURLChange={setThumbnailURL}
                onSelectedTemplateChange={setSelectedTemplate}
                ref={formWrapperRef}
                setIsFormValid={setIsFormValid}
                projectPickerFilter={({ isArchived, companyId }) => {
                  if (isArchived) {
                    return false;
                  }
                  if (officeId) {
                    const company = companiesById[companyId];
                    if (company && company.companyType === 'InternalCompany') {
                      const { offices } = company;
                      const mainOfficeId = offices.find(
                        (office) => office.isMainOffice
                      )?.officeId;
                      if (!mainOfficeId) {
                        return false;
                      }
                      if (mainOfficeId !== officeId) {
                        return false;
                      }
                    }
                  }
                  return true;
                }}
              />
            </FilterContextProvider>
          </DocumentTemplateCategoryContextProvider>
        </DocumentTemplateTagContextProvider>
      </Drawer>
    </div>
  );
});

export default TimeRecordsModule;
