import React, {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Form, Input, Modal, notification } from 'antd';
import {
  DateTimeString,
  OfficeHolidayId,
  OfficeId,
} from '../../../models/Types';
import { OfficeHoliday } from '../../../models/AbsenceProposal';
import moment, { Moment } from 'moment';
import {
  apiCreateOfficeHoliday,
  apiDeleteHoliday,
  apiUpdateOfficeHoliday,
} from '../api';
import { useTheme } from '@prio365/prio365-react-library/lib/ThemeProvider';
import { PrioTheme } from '../../../theme/types';
import classNames from 'classnames';
import CustomSingleDatePicker from '../../../components/CustomSingleDatePicker';
import Flex from '../../../components/Flex';
import { Button, useKeyboardListener } from '@prio365/prio365-react-library';
import {
  DefaultSearchParameterItem,
  FILTER_DATA_LIST_CLASS_PREFIX,
  FilterBar,
} from '../../../components/Filter/FilterBar';
import FilterContextProvider from '../../../components/Filter/FilterContextProvider';
import HRHolidaysPageTable, {
  HolidaySearchResult,
  HolidaySearchResultCalculatedData,
  SearchResultHolidayPage,
} from './HRHolidaysPageTable';
import useFilterContext from '../../../components/Filter/hooks/useFilterContext';
import useOfficesContext from '../../companies/hooks/useOfficesContext';
import { createTemporaryId, distinct } from '../../../util';
import { ApiResult } from '../../../api';
import { makePrioStyles } from '../../../theme/utils';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    overflow: 'hidden',
  },
  fullWidth: {
    width: '100%',
  },
  content: {
    flex: 1,
    height: '100%',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  table: {
    flex: 1,
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  row: {
    cursor: 'pointer',
  },
  center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  smallErrorText: {
    fontSize: theme.font.fontSize.small,
    color: theme.colors.base.red.default,
  },
  showOverflow: {
    overflow: 'visible',
  },
  contentWrapper: {
    padding: 24,
    overflowY: 'auto',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  datePicker: {
    marginBottom: 24,
    '& .ant-form-item': {
      marginBottom: 0,
    },
  },
}));

export const convertOfficeHolidayToSearchResult = (
  holiday: OfficeHoliday,
  existingItem?: SearchResultHolidayPage
): SearchResultHolidayPage => {
  return {
    data: {
      date: holiday.date,
      name: holiday.name,
      isHalfDay: holiday.isHalfDay,
    },
    calculated: {
      internalPublic: 'Internal',
      officeIds: distinct([
        holiday.officeId,
        ...(existingItem?.calculated.officeIds ?? []),
      ]),
      isoCodes: distinct([
        holiday.isoCode,
        ...(existingItem?.calculated.isoCodes ?? []),
      ]),
      officeIdOfficeHolidayIdDtos: [
        {
          officeId: holiday.officeId,
          officeHolidayId: holiday.officeHolidayId,
        },
      ].concat(
        existingItem?.calculated.officeIdOfficeHolidayIdDtos?.filter(
          ({ officeId, officeHolidayId }) =>
            officeId === holiday.officeId &&
            officeHolidayId === holiday.officeHolidayId
        ) ?? []
      ),
    },
    isTemporary: false,
  };
};

interface OfficeHolidayForm {
  date: Moment;
  name: string;
  isHalfDay: boolean;
}

export interface HRHolidayPageRef {
  addOfficeHoliday: VoidFunction;
}

interface HRHolidayPageProps {
  officeId?: OfficeId;
}

export const HRHolidayPage = forwardRef(
  (props: HRHolidayPageProps, ref: ForwardedRef<HRHolidayPageRef>) => {
    //#region ------------------------------ Defaults
    const { officeId } = props;
    const classes = useStyles(props);
    //#endregion

    //#region ------------------------------ States / Attributes / Selectors
    const { getOfficeById } = useOfficesContext();

    const customDefaultSearchParameters: DefaultSearchParameterItem[] = [
      ...(officeId
        ? [
            {
              parameterName: 'Calculated.OfficeIds',
              defaultValue: officeId,
              defaultMethod: 'in',
            },
          ]
        : []),
    ];
    //#endregion
    const office = useMemo(
      () => getOfficeById(officeId),
      [officeId, getOfficeById]
    );

    const [holidayInModal, setHolidayInModal] =
      useState<SearchResultHolidayPage>(null);

    const [deleteHolidayInModal, setDeleteHolidayInModal] =
      useState<SearchResultHolidayPage>(null);
    //#endregion

    //#region ------------------------------ Methods / Handlers
    const addOfficeHoliday = useCallback(() => {
      setHolidayInModal({
        data: {
          date: moment().toISOString(),
          name: '',
          isHalfDay: false,
        },
        calculated: {
          internalPublic: 'Internal',
          officeIds: [officeId],
          isoCodes: office?.federalStateCode ? [office?.federalStateCode] : [],
          officeIdOfficeHolidayIdDtos: [
            {
              officeId,
              officeHolidayId: 'new-' + createTemporaryId(),
            },
          ],
        },
        isTemporary: true,
      });
    }, [officeId, office?.federalStateCode]);
    //#endregion

    //#region ------------------------------ Effects
    useImperativeHandle(ref, () => ({
      addOfficeHoliday,
    }));

    useKeyboardListener({
      Escape: () => {
        setHolidayInModal(null);
        setDeleteHolidayInModal(null);
      },
    });
    //#endregion

    return (
      <div className={classes.contentWrapper}>
        <FilterContextProvider<
          HolidaySearchResult,
          HolidaySearchResultCalculatedData
        >
          searchType="holidays"
          equalityFunction={({ data: a }, { data: b }) =>
            a.date === b.date && a.name === b.name
          }
        >
          <FilterBar
            customDefaultSearchParameters={customDefaultSearchParameters}
          />
          <HRHolidaysPageTable
            className={FILTER_DATA_LIST_CLASS_PREFIX}
            tableId={'hp1'}
            officeId={officeId}
            setHolidayInModal={setHolidayInModal}
            setDeleteHolidayInModal={setDeleteHolidayInModal}
          />
          <AddModal
            holidayInModal={holidayInModal}
            setHolidayInModal={setHolidayInModal}
            officeId={officeId}
            holidayModalVisible={holidayInModal !== null}
          />
          <DeleteModal
            deleteHolidayModalVisible={deleteHolidayInModal !== null}
            deleteHolidayInModal={deleteHolidayInModal}
            setDeleteHolidayInModal={setDeleteHolidayInModal}
          />
        </FilterContextProvider>
      </div>
    );
  }
);

export default HRHolidayPage;

interface AddModalProps {
  className?: string;
  holidayInModal: SearchResultHolidayPage;
  setHolidayInModal: (holiday: SearchResultHolidayPage) => void;
  officeId: OfficeId;
  holidayModalVisible: boolean;
}

const AddModal: React.FC<AddModalProps> = (props) => {
  //#region ------------------------------ Defaults
  const { holidayInModal, setHolidayInModal, officeId, holidayModalVisible } =
    props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme() as PrioTheme;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [form] = Form.useForm();

  const { getOfficeById } = useOfficesContext();

  const office = useMemo(
    () => getOfficeById(officeId),
    [officeId, getOfficeById]
  );

  const { optimisticWrite } = useFilterContext<
    HolidaySearchResult,
    HolidaySearchResultCalculatedData
  >();

  const [selectedDate, setSelectedDate] = useState<DateTimeString>(null);

  const isEditModal = useMemo(() => {
    return !holidayInModal?.isTemporary ?? true;
  }, [holidayInModal]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleHolidayModalOk = async (formData?: OfficeHolidayForm) => {
    if (!isEditModal) {
      optimisticWrite([
        {
          ...holidayInModal,
          data: {
            ...holidayInModal.data,
            date: selectedDate,
            name: formData?.name,
            isHalfDay: formData?.isHalfDay,
          },
          callback: async () => {
            const { result, data } = await apiCreateOfficeHoliday({
              date: formData.date,
              isoCode: office?.federalStateCode || '',
              officeId,
              name: formData?.name,
              isHalfDay: formData?.isHalfDay,
            });

            if (!data) {
              notification.open({
                message: t('common:error'),
                description: t(
                  'absences:officeHolidays.messages.error.holidayCreate'
                ),
              });
              return {
                result,
                data: null,
              };
            }
            return {
              result,
              data: convertOfficeHolidayToSearchResult(data),
            };
          },
          method: 'add',
        },
      ]);
    } else {
      optimisticWrite([
        {
          ...holidayInModal,
          data: {
            ...holidayInModal.data,
            name: formData?.name,
            isHalfDay: formData?.isHalfDay,
          },
          originalData: holidayInModal,
          method: 'update',
          callback: async () => {
            const updateFunction = async (officeHolidayId: OfficeHolidayId) => {
              return await apiUpdateOfficeHoliday(
                {
                  isoCode: office?.federalStateCode || '',
                  officeId,
                  name: formData?.name,
                  isHalfDay: formData?.isHalfDay,
                },
                officeHolidayId,
                officeId
              );
            };

            const responses = await Promise.all(
              holidayInModal.calculated.officeIdOfficeHolidayIdDtos.map(
                async ({ officeHolidayId }) => {
                  return await updateFunction(officeHolidayId);
                }
              )
            );

            if (responses.some(({ result }) => !result.ok)) {
              notification.open({
                message: t('common:error'),
                description: t(
                  'absences:officeHolidays.messages.error.holidayUpdate'
                ),
              });
              return {
                result: responses.find(({ result }) => !result.ok)?.result,
                data: null,
              };
            }
            return responses.reduce(
              (acc, { result, data }) => {
                return {
                  result,
                  data: convertOfficeHolidayToSearchResult(data, acc.data),
                };
              },
              {
                result: null,
                data: null,
              } as ApiResult<SearchResultHolidayPage>
            );
          },
        },
      ]);
    }

    setHolidayInModal(null);
  };

  const generateLabel = (type: 'title' | 'actionButton') => {
    const _type = type === 'title' ? 'titles' : 'okText';
    if (
      holidayInModal?.calculated.officeIdOfficeHolidayIdDtos.some(
        ({ officeHolidayId }) => officeHolidayId.includes('new')
      )
    ) {
      return t(`absences:officeHolidays.modal.${_type}.new`);
    }
    if (
      holidayInModal?.calculated.officeIdOfficeHolidayIdDtos.some(
        ({ officeHolidayId }) => officeHolidayId.includes('copy')
      )
    ) {
      return t(`absences:officeHolidays.modal.${_type}.copy`);
    }
    return t(`absences:officeHolidays.modal.${_type}.edit`);
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (holidayInModal) {
      form?.setFieldsValue({
        date: moment(holidayInModal?.data?.date),
        name: holidayInModal?.data?.name,
        isHalfDay: holidayInModal?.data?.isHalfDay,
      });
    }
  }, [holidayInModal, form]);
  //#endregion

  return (
    <Modal
      title={generateLabel('title')}
      visible={holidayModalVisible}
      onCancel={() => {
        setHolidayInModal(null);
      }}
      footer={
        <Flex.Row childrenGap={theme.spacing.small} justifyContent="flex-end">
          <Button
            key="cancel"
            type="default"
            onClick={() => {
              setHolidayInModal(null);
            }}
          >
            {t('mail:modals.assignMessage.cancelText')}
          </Button>
          <Button
            key="ok"
            type="primary"
            onClick={() => {
              form.submit();
            }}
          >
            {generateLabel('actionButton')}
          </Button>
        </Flex.Row>
      }
    >
      <Form<OfficeHolidayForm>
        form={form}
        className={classNames(classes.showOverflow, classes.content)}
        style={{ padding: '0px' }}
        layout="vertical"
        onFinish={handleHolidayModalOk}
        onValuesChange={(changedValues) => {
          if (changedValues.date) {
            setSelectedDate(changedValues.date.toISOString(true));
          }
        }}
      >
        <Flex.Row>
          <Flex.Column
            className={classNames(classes.fullWidth, classes.datePicker)}
          >
            <Form.Item
              name="date"
              label={t('absences:officeHolidays.table.columnTitle.date')}
              className={classes.fullWidth}
            >
              <CustomSingleDatePicker
                disabled={isEditModal}
                id="office_holiday_datepicker"
                anchorDirection={'ANCHOR_RIGHT'}
                small={true}
                regular={false}
                twoMonths={false}
                withFullScreenPortal={false}
                daySize={30}
                hideKeyboardShortcutsPanel={true}
                showDefaultInputIcon={true}
                inputIconPosition={'after'}
              />
            </Form.Item>
          </Flex.Column>
        </Flex.Row>
        <Flex.Row>
          <Form.Item
            name="name"
            label={t('absences:officeHolidays.table.columnTitle.name')}
            className={classes.fullWidth}
            required
          >
            <Input
              placeholder={t('absences:officeHolidays.modal.placeholderInput')}
            />
          </Form.Item>
        </Flex.Row>
        <Flex.Row>
          <Form.Item
            name="isHalfDay"
            valuePropName="checked"
            label={t('absences:officeHolidays.table.columnTitle.isHalfDay')}
          >
            <Checkbox />
          </Form.Item>
        </Flex.Row>
      </Form>
    </Modal>
  );
};

interface DeleteModalProps {
  className?: string;
  deleteHolidayModalVisible: boolean;
  deleteHolidayInModal: SearchResultHolidayPage;
  setDeleteHolidayInModal: (holiday: SearchResultHolidayPage) => void;
}

const DeleteModal: React.FC<DeleteModalProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    deleteHolidayModalVisible,
    deleteHolidayInModal,
    setDeleteHolidayInModal,
  } = props;
  const theme = useTheme() as PrioTheme;
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { optimisticWrite } = useFilterContext<
    HolidaySearchResult,
    HolidaySearchResultCalculatedData
  >();
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleDeleteHolidayModalOk = async () => {
    await optimisticWrite(
      [
        {
          ...deleteHolidayInModal,
          method: 'remove',
        },
      ],
      async () => {
        const promises =
          deleteHolidayInModal.calculated.officeIdOfficeHolidayIdDtos.map(
            async ({ officeHolidayId }) => {
              return await apiDeleteHoliday(officeHolidayId);
            }
          );
        const responses = await Promise.all(promises);
        if (responses.some(({ result }) => !result.ok)) {
          notification.open({
            message: t('common:error'),
            description: t(
              'absences:officeHolidays.messages.error.holidayDelete'
            ),
          });
          return {
            result: responses.find(({ result }) => !result.ok)?.result,
            data: null,
          };
        }
        return {
          result: responses[0].result,
          data: [deleteHolidayInModal],
        };
      }
    );
    setDeleteHolidayInModal(null);
  };
  //#endregion

  return (
    <Modal
      title={t('absences:officeHolidays.table.dropdown.actions.delete')}
      visible={deleteHolidayModalVisible}
      onOk={handleDeleteHolidayModalOk}
      onCancel={() => setDeleteHolidayInModal(null)}
      cancelText={t('mail:modals.assignMessage.cancelText')}
      okText={t('absences:officeHolidays.table.dropdown.actions.delete')}
      footer={
        <Flex.Row childrenGap={theme.spacing.small} justifyContent="flex-end">
          <Button
            type="default"
            key="cancel"
            onClick={() => setDeleteHolidayInModal(null)}
          >
            {t('mail:modals.assignMessage.cancelText')}
          </Button>
          <Button key="ok" type="primary" onClick={handleDeleteHolidayModalOk}>
            {t('absences:officeHolidays.table.dropdown.actions.delete')}
          </Button>
        </Flex.Row>
      }
    >
      {t('absences:officeHolidays.deleteModal.areYouSure', {
        holidayName: deleteHolidayInModal?.data?.name,
        holidayDate: moment(deleteHolidayInModal?.data?.date).format(
          'DD.MM.YYYY'
        ),
      })}
    </Modal>
  );
};
