import React from 'react';
import { Routes as ReactRouterDomRoutes, Route } from 'react-router-dom';
import Flex from '../../../components/Flex';
import { makePrioStyles } from '../../../theme/utils';
import NavigationBar from '../../../components/NavigationBar';
import { ProjectId } from '../../../models/Types';
import * as Sentry from '@sentry/react';
import { PrioTheme } from '../../../theme/types';

import ProjectContactsPage from './ProjectContactsPage';
const Routes = Sentry.withSentryReactRouterV6Routing(ReactRouterDomRoutes);

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    position: 'relative',
    height: '100%',
  },
  body: {
    overflow: 'hidden',
  },
  menu: {
    maxWidth: theme.old.components.menuMaxWidth,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
  },
  page: {
    height: '100%',
  },
  content: {
    overflow: 'hidden',
  },
}));

interface ProjectContactsSubModuleProps {
  projectId: ProjectId;
}

export const ProjectContactsSubModule: React.FC<
  ProjectContactsSubModuleProps
> = (props) => {
  const classes = useStyles();
  const { projectId } = props;

  return (
    <Flex.Column className={classes.root}>
      <NavigationBar></NavigationBar>
      <Flex.Row flex={1} className={classes.body}>
        <Flex.Item flex={1} className={classes.content}>
          <Routes>
            <Route
              path="/"
              element={
                <ProjectContactsPage
                  projectId={projectId}
                  className={classes.page}
                />
              }
            />
          </Routes>
        </Flex.Item>
      </Flex.Row>
    </Flex.Column>
  );
};

export default ProjectContactsSubModule;
