import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import Flex from '../../../../components/Flex';
import { getMailSettings } from '../../../../apps/main/rootReducer';
import { ProjectId } from '../../../../models/Types';
import { setMailListNavigationState } from '../../actions/actionControllers/mailNavigationActionController';
import MessageCenterNavigation from './MessageCenterNavigation';
import MessageCenterNavigationBar from './MessageCenterNavigationBar';
import { setActiveProjectInMessageCenter } from '../../actions/favorites/meta';
import MailRowLayout from '../layouts/MailRowLayout';
import MailColumnLayout from '../layouts/MailColumnLayout';
import MailHiddenLayout from '../layouts/MailHiddenLayout';
import { Message } from '../../../../models/Message';
import MailSettingsDrawer from '../MailSettingsDrawer';
import { addDraftMessage } from '../../actions/actionControllers/draftsActionController';
import { apiCreateMessageDraft } from '../../api';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { makePrioStyles } from '../../../../theme/utils';
import { MailFilterContextProvider } from '../MailSearch/MailFilterContext';

const useStyles = makePrioStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    overflow: 'hidden',
  },
  content: {
    height: '100%',
    overflow: 'hidden',
  },
  panel: {
    transition: 'width 375ms ease-in-out, opacity 375ms ease-in-out',
    overflow: 'hidden',
    position: 'relative',
  },
}));

interface MessageCenterSubModuleProps {
  pathPrefix?: string;
}

export const MessageCenterSubModule: React.FC<MessageCenterSubModuleProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { pathPrefix } = props;
  const classes = useStyles();

  const dispatch = useDispatch();

  const { projectId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const mailSettings = useSelector(getMailSettings);

  const [selectedMessages, setSelectedMessages] = useState<Message[]>([]);

  const [mailSettingsDrawerVisible, setMailSettingsDrawerVisible] =
    useState<boolean>(false);

  //#endregion

  //#region ------------------------------ Methods / Handlers
  const clearSelection = () => setSelectedMessages([]);

  const doShowNewMessage = async (
    projectId: ProjectId,
    setIsCreating: (value: boolean) => void
  ) => {
    setIsCreating(true);
    const { data } = await apiCreateMessageDraft(projectId);
    if (data) {
      dispatch(addDraftMessage(projectId, data, true));
    } else {
      notification.open({
        message: t('common:error'),
        description: t('mail:errorMessages.messages.createDraftError'),
      });
    }
    setIsCreating(false);
  };
  //#endregion

  //#region ------------------------------ Effects
  /*
  useEffect(() => {
    const handler = setTimeout(() => {
      projectFavorites.forEach((project) =>
        dispatch(deleteProjectNews(project.projectId))
      );
    }, 5000);
    return () => {
      clearTimeout(handler);
    };
  }, [projectFavorites, dispatch]);
  */

  useEffect(() => {
    if (!window.location.href.match(/message\/(.*)\/details?$/)) {
      dispatch(setMailListNavigationState(null, 'favorites'));
    }
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(setActiveProjectInMessageCenter(projectId ?? null));
  }, [projectId, dispatch]);
  //#endregion

  if (window.location.href.match(/(\/mail\/)$/)) {
    return (
      <Navigate to={`${pathPrefix}${projectId ?? 'favorites'}/mail/inbox`} />
    );
  }

  return (
    <Flex.Column className={classes.root}>
      <MailFilterContextProvider>
        <MessageCenterNavigationBar
          projectId={projectId}
          doShowNewMessage={doShowNewMessage}
          selectedMessages={selectedMessages}
          clearSelection={clearSelection}
        />
        <Flex.Row flex={1} className={classes.content}>
          <MessageCenterNavigation
            projectId={projectId ?? 'favorites'}
            handleOpenSettings={setMailSettingsDrawerVisible}
            openSettings={mailSettingsDrawerVisible}
            pathPrefix={pathPrefix}
          />
          {mailSettings.messageViewLayout === 'right' ? (
            <MailRowLayout
              projectId={projectId ?? 'favorites'}
              pathPrefix="../../../"
              selectedMessages={selectedMessages}
              onSelectionChange={setSelectedMessages}
              mailSettings={mailSettings}
            />
          ) : mailSettings.messageViewLayout === 'bottom' ? (
            <MailColumnLayout
              projectId={projectId ?? 'favorites'}
              pathPrefix="../../../"
              selectedMessages={selectedMessages}
              onSelectionChange={setSelectedMessages}
              mailSettings={mailSettings}
            />
          ) : (
            <MailHiddenLayout
              projectId={projectId ?? 'favorites'}
              pathPrefix="../../../"
              selectedMessages={selectedMessages}
              onSelectionChange={setSelectedMessages}
              mailSettings={mailSettings}
            />
          )}
          <MailSettingsDrawer
            mailSettings={mailSettings}
            mailSettingsDrawerVisible={mailSettingsDrawerVisible}
            setMailSettingsDrawerVisible={setMailSettingsDrawerVisible}
          />
        </Flex.Row>
      </MailFilterContextProvider>
    </Flex.Column>
  );
};

export default MessageCenterSubModule;
