import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import FilterContext from './context';
import {
  FilterContextProps,
  GenericSearchResult,
  GenericSearchResultItem,
  OptimisticWriteGenericSearchResultItem,
  SearchType,
} from './types';
import {
  QueryFunction,
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  UseQueryOptions,
  useQuery,
} from '@tanstack/react-query';
import { ApiResult } from '../../api';
import { apiFetchSearchData } from './api';
import { notification } from 'antd';
import i18n from '../../i18n';
import { filterSearchDataBasedOnSearchString } from './utils';
import equals from 'deep-equal';
import { VirtualTableBodyRef } from '@prio365/prio365-react-library/lib/VirtualTable/components/VirtualTableBody';

const DEFAULT_THRESHOLD = 1000;

declare type QueryKey = [string, SearchType, string, number, boolean];

type AdditionalOptimisticWriteInfo<
  ResultData = unknown,
  CalculatedData = unknown,
> = {
  item: GenericSearchResultItem<ResultData, CalculatedData> | null;
  index: number | null;
};

interface FilterContextProviderProps<
  ResultData = unknown,
  CalculatedData = unknown,
  TError = Error,
> {
  searchType: SearchType;
  queryFn?: QueryFunction<
    ApiResult<GenericSearchResult<ResultData, CalculatedData>>,
    QueryKey
  >;
  options?: UseQueryOptions<
    ApiResult<GenericSearchResult<ResultData, CalculatedData>>,
    TError,
    ApiResult<GenericSearchResult<ResultData, CalculatedData>>,
    QueryKey
  >;
  equalityFunction?: (
    a: Partial<GenericSearchResultItem<ResultData, CalculatedData>>,
    b: Partial<GenericSearchResultItem<ResultData, CalculatedData>>
  ) => boolean;
  enableFilterBasedOnSearchString?: boolean;
  children: React.ReactNode;
}

function FilterContextProvider<ResultData = unknown, CalculatedData = unknown>(
  props: FilterContextProviderProps<ResultData, CalculatedData>
) {
  //#region ------------------------------ Defaults
  const {
    children,
    searchType,
    queryFn,
    options,
    equalityFunction = (a, b) => equals(a, b),
    enableFilterBasedOnSearchString = true,
  } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const tableRef =
    useRef<
      VirtualTableBodyRef<GenericSearchResultItem<ResultData, CalculatedData>>
    >(null);

  const uniqueSearchQueryKey = useMemo(() => {
    return `search${searchType.charAt(0).toUpperCase() + searchType.slice(1)}`;
  }, [searchType]);

  const [searchString, setSearchString] = useState<string>(null);
  const searchStringRef = useRef<string>(searchString);
  searchStringRef.current = searchString;

  const [threshold, setThreshold] = useState<number>(DEFAULT_THRESHOLD);
  const thresholdRef = useRef<number>(threshold);
  thresholdRef.current = threshold;

  const forceResult = useMemo(
    () => DEFAULT_THRESHOLD !== threshold,
    [threshold]
  );

  const optimisticResultRef = useRef<ApiResult<
    GenericSearchResult<ResultData, CalculatedData>
  > | null>(null);

  const queryResult = useQuery(
    [uniqueSearchQueryKey, searchType, searchString, threshold, forceResult],
    queryFn ??
      (({ queryKey }) => {
        const [, searchType, searchString, threshold, forceResult] =
          queryKey as QueryKey;

        if (optimisticResultRef.current) {
          const optimisticResult = { ...optimisticResultRef.current };
          return optimisticResult;
        }
        return apiFetchSearchData<ResultData, CalculatedData>(
          searchType,
          searchString,
          threshold,
          forceResult
        );
      }),
    options ?? {
      enabled: false,
    }
  );

  const queryResultDataRef = useRef<
    ApiResult<GenericSearchResult<ResultData, CalculatedData>>
  >(queryResult?.data);
  queryResultDataRef.current = queryResult.data;

  const _refetch = useMemo(() => queryResult.refetch, [queryResult.refetch]);

  /**
   * Refetch method for the search result.
   * @param args - Arguments for the refetch method. It can be used to override the default options.
   * @param options - Options for the refetch method. It can be used to override the default options.
   * @param enableOptimistic - If true, the optimistic data will be used for the refetch. default is false.
   * @returns QueryObserverResult<ApiResult<GenericSearchResult<ResultData, CalculatedData>>, Error>
   */
  const refetch: <TPageData>(args?: {
    options?: RefetchOptions & RefetchQueryFilters<TPageData>;
    enableOptimistic?: boolean;
  }) => Promise<
    QueryObserverResult<
      ApiResult<GenericSearchResult<ResultData, CalculatedData>>,
      Error
    >
  > = useCallback(
    async (
      { options, enableOptimistic } = {
        options: undefined,
        enableOptimistic: false,
      }
    ) => {
      if (!enableOptimistic) {
        optimisticResultRef.current = null;
      }

      if (tableRef.current) {
        tableRef.current.setSelectAll(false);
      }
      return await _refetch(options);
    },
    [_refetch]
  );

  const remove = useMemo(() => queryResult.remove, [queryResult.remove]);

  const fetchSearch: (_searchString?: string, threshold?: number) => void =
    useCallback(
      (
        _searchString = searchStringRef.current,
        threshold = thresholdRef.current
      ) => {
        if (
          searchStringRef.current === _searchString &&
          thresholdRef.current === threshold
        ) {
          refetch();
        } else {
          if (searchStringRef.current !== _searchString) {
            setSearchString(_searchString);
            setThreshold(DEFAULT_THRESHOLD);
          }
          if (thresholdRef.current !== threshold) {
            setThreshold(threshold);
          }
        }
      },
      [refetch]
    );

  const clearSearch = useCallback(() => {
    setSearchString('');
    setThreshold(DEFAULT_THRESHOLD);
    remove();
  }, [remove]);
  //#endregion

  //#region ------------------------------ Methods
  const getItemById = useCallback(
    (value: string, key: keyof ResultData) => {
      const data = queryResult.data?.data?.items ?? [];
      if (data.length === 0) {
        return null;
      }
      const item = data.find(({ data: item }) => item[key] === value);
      return item ?? null;
    },
    [queryResult.data]
  );

  const getSearchResultItem = useCallback(
    (
      predicate: (
        item: GenericSearchResultItem<ResultData, CalculatedData>
      ) => boolean
    ) => {
      const data = queryResult.data?.data?.items ?? [];
      if (data.length === 0) {
        return null;
      }
      const item = data.find((item) => predicate(item));
      return item ?? null;
    },
    [queryResult.data]
  );

  /**
   * Optimistic write method for the search result.
   * @param dataToOverwrite - Data to overwrite in the search result. Enriched items with method information (add, remove, update) and callback function.
   * @param bulkCallback - Callback function to update the items in the backend. If provided, it will be used instead of the callback functions in the dataToOverwrite.
   * @returns Promise<Array<GenericSearchResultItem<ResultData, CalculatedData>>>
   */
  const optimisticWrite = useCallback(
    async (
      dataToOverwrite: Array<
        OptimisticWriteGenericSearchResultItem<ResultData, CalculatedData>
      >,
      bulkCallback?: () => Promise<
        ApiResult<Array<GenericSearchResultItem<ResultData, CalculatedData>>>
      >
    ) => {
      let newSearchResultItems = [
        ...(queryResultDataRef.current?.data?.items ?? []),
      ];
      let dataToOverwriteWithOptimisticInfo: Array<
        OptimisticWriteGenericSearchResultItem<ResultData, CalculatedData> & {
          commit: AdditionalOptimisticWriteInfo<
            ResultData,
            CalculatedData
          > | null;
          rollback: AdditionalOptimisticWriteInfo<ResultData, CalculatedData>;
        }
      > = [];

      //prepare optimistic data and adjust newSearchResultItems
      for (const item of dataToOverwrite) {
        //find original item if exists - if not, set to null
        let originalItem: GenericSearchResultItem<
          ResultData,
          CalculatedData
        > | null = null;
        let originalItemIndex: number | null = null;
        if (item.method !== 'add') {
          let index = newSearchResultItems.findIndex((i) =>
            equalityFunction(item, i)
          );
          if (index === -1 && item.originalData) {
            index = newSearchResultItems.findIndex((i) =>
              equalityFunction(item.originalData, i)
            );
          }
          if (index > -1) {
            originalItem = newSearchResultItems[index];
            originalItemIndex = index;
          }
        }

        //prepare newSearchResultItems
        if (item.method === 'add') {
          //if the item is added, add it to the list
          newSearchResultItems.push({
            data: item.data,
            calculated: item.calculated,
            isTemporary: true,
          });
        } else if (item.method === 'remove') {
          //if the item is removed, remove it from the list
          if (originalItemIndex !== null) {
            newSearchResultItems.splice(originalItemIndex, 1);
          }
        } else if (item.method === 'update') {
          //if the item is updated, update it in the list
          if (
            originalItemIndex !== null &&
            originalItemIndex !== undefined &&
            originalItemIndex > -1
          ) {
            newSearchResultItems[originalItemIndex] = {
              data: item.data,
              calculated: item.calculated ?? originalItem?.calculated,
            };
          }
        }

        //prepare dataToOverwriteWithOptimisticInfo
        let _item: OptimisticWriteGenericSearchResultItem<
          ResultData,
          CalculatedData
        > & {
          commit: AdditionalOptimisticWriteInfo<
            ResultData,
            CalculatedData
          > | null;
          rollback: AdditionalOptimisticWriteInfo<ResultData, CalculatedData>;
        } = {
          ...item,
          commit: null,
          rollback: {
            item: originalItem,
            index: item.method === 'update' ? originalItemIndex : null,
          },
        };

        dataToOverwriteWithOptimisticInfo.push(_item);
      }

      //filter the data with the new search string
      if (enableFilterBasedOnSearchString) {
        try {
          newSearchResultItems = filterSearchDataBasedOnSearchString(
            newSearchResultItems,
            searchString
          );
        } catch (e) {}
      }

      //update optimistic data and refetch
      optimisticResultRef.current = {
        result: new Response(
          JSON.stringify({
            items: newSearchResultItems,
            totalItems: newSearchResultItems.length,
          }),
          {
            status: 200,
            statusText: 'OK',
          }
        ),
        data: {
          items: newSearchResultItems,
          totalItems: newSearchResultItems.length,
        },
      };

      refetch({ enableOptimistic: true });

      //execute callbacks if exists and add commit info to the item if successful
      let callbackUsed = false;

      if (bulkCallback) {
        callbackUsed = true;

        const { result, data } = await bulkCallback();

        dataToOverwriteWithOptimisticInfo =
          dataToOverwriteWithOptimisticInfo.map((item, index) => {
            if (result.ok) {
              return {
                ...item,
                commit: {
                  item: {
                    isTemporary: false,
                    ...data[index],
                  },
                  index: item.rollback.index,
                },
              };
            }
            return item;
          });
      } else {
        for (let i = 0; i < dataToOverwriteWithOptimisticInfo.length; i++) {
          const item = dataToOverwriteWithOptimisticInfo[i];
          if (item.callback) {
            callbackUsed = true;
            const response = await item.callback();
            if (response.result.ok) {
              dataToOverwriteWithOptimisticInfo[i].commit = {
                item: {
                  isTemporary: false,
                  ...response.data,
                },
                index: item.rollback.index,
              };
            }
          } else {
            dataToOverwriteWithOptimisticInfo[i].commit = {
              item: {
                data: item.data,
                calculated: item.calculated,
              },
              index: item.rollback.index,
            };
          }
        }
      }

      //if callback is used, update the optimistic data with real data
      //if not, the optimistic data will be updated with the real data in the next refetch
      if (callbackUsed) {
        newSearchResultItems = [
          ...(queryResultDataRef.current?.data?.items ?? newSearchResultItems),
        ];

        //overwrite optimistic data with real data
        for (const item of dataToOverwriteWithOptimisticInfo) {
          const _item = { data: item.data, calculated: item.calculated };

          if (item.commit) {
            //if commit exists, update the item in the list

            //if the item was removed, do not update the list
            if (item.method !== 'remove') {
              const index = newSearchResultItems.findIndex((i) =>
                equalityFunction(_item, i)
              );

              if (index > -1) {
                newSearchResultItems[index] = item.commit.item;
              }
            }
          } else if (item.rollback.item) {
            //if commit does not exist, rollback the item in the list

            if (item.method === 'add') {
              //if the item was added, remove it from the list
              const index = newSearchResultItems.findIndex((i) =>
                equalityFunction(_item, i)
              );
              if (index > -1) {
                newSearchResultItems.splice(index, 1);
              }
            } else if (item.method === 'update') {
              //if the item was updated, rollback the item in the list
              const index = newSearchResultItems.findIndex((i) =>
                equalityFunction(_item, i)
              );
              if (index > -1) {
                newSearchResultItems[index] = item.rollback.item;
              }
            } else if (item.method === 'remove') {
              //if the item was removed, add it back to the list
              newSearchResultItems.push(item.rollback.item);
            }
          }
        }

        //filter the data with the new search string
        if (enableFilterBasedOnSearchString) {
          try {
            newSearchResultItems = filterSearchDataBasedOnSearchString(
              newSearchResultItems,
              searchString
            );
          } catch (e) {}
        }

        optimisticResultRef.current = {
          result: new Response(
            JSON.stringify({
              items: newSearchResultItems,
              totalItems: newSearchResultItems.length,
            }),
            {
              status: 200,
              statusText: 'OK',
            }
          ),
          data: {
            items: newSearchResultItems,
            totalItems: newSearchResultItems.length,
          },
        };

        refetch({ enableOptimistic: true });
      }

      return newSearchResultItems;
    },
    [searchString, enableFilterBasedOnSearchString, refetch, equalityFunction]
  );
  //#endregion

  //#region ------------------------------ Context
  const value: FilterContextProps<ResultData, CalculatedData> = useMemo(() => {
    let data: GenericSearchResult<ResultData, CalculatedData> | null = null;
    let thresholdExceeded = null;
    if (!queryResult.isError) {
      data = queryResult.data?.data ?? null;
      if (
        (queryResult.data?.data?.totalItems ?? 0) !==
        (queryResult.data?.data?.items ?? []).length
      ) {
        thresholdExceeded = queryResult.data?.data?.totalItems ?? 0;
      }
    } else {
      notification.error({
        message: i18n.t('common:error'),
        description: queryResult.error.message,
      });
    }

    return {
      searchType,
      searchString,
      data,
      thresholdExceeded,
      fetchSearch,
      isLoading: queryResult.isLoading && searchString !== null,
      isError: queryResult.isError,
      isSuccess: queryResult.isSuccess,
      isFetching: queryResult.isFetching,
      getItemById,
      getSearchResultItem,
      clearSearch,
      optimisticWrite,
      tableRef,
    };
  }, [
    searchString,
    searchType,
    queryResult.data,
    queryResult.error,
    queryResult.isLoading,
    queryResult.isError,
    queryResult.isSuccess,
    queryResult.isFetching,
    fetchSearch,
    getItemById,
    getSearchResultItem,
    clearSearch,
    optimisticWrite,
  ]);
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    if (searchString !== null) {
      refetch();
    }
  }, [searchString, threshold, searchType, refetch]);
  //#endregion

  return (
    <FilterContext.Provider value={value}>{children}</FilterContext.Provider>
  );
}

export default FilterContextProvider;
