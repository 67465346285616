import {
  ContactId,
  ProjectExtensionAccessId,
  ProjectExtensionAccessOptionId,
  ProjectExtensionId,
  ProjectExtensionOptionId,
  ProjectId,
  ProjectRole,
} from './Types';

export type ProjectExtensionType =
  | 'sharedMailbox'
  | 'sharepoint'
  | 'planner'
  | 'openExperience'
  | 'url'
  | 'jira';

export type TriggerTypes = 'CreateFolderStructure';

export type ProjectExtensionAvailableState =
  | 'none'
  | 'available'
  | 'unique'
  | 'dependent'
  | 'deprecated'
  | 'beta';

export type ExtensionAccessState = 'idle' | 'waitingForFinish';

export type ProjectExtensionOptionKeys =
  | 'EmailNotificationEnabled'
  | 'projectFileSystemStructureId'
  | 'plannerDetails'
  | 'teams';

export type ExtensionState =
  | 'draft'
  | 'waitingForSetup'
  | 'waitingForReadyUpdate'
  | 'ready'
  | 'deleteRequested'
  | 'deleted'
  | 'error'
  | 'triggered';

export type ExtensionUserState =
  | 'noAccess'
  | 'waitingForSetup'
  | 'waitingForAccess'
  | 'ready';

export interface TeamsOption {
  allowCreatePrivateChannels?: boolean;
  allowCreateUpdateChannels?: boolean;
  allowUserEditMessages?: boolean;
  allowUserDeleteMessages?: boolean;
  allowGiphy?: boolean;
  giphyContentRating?: string;
}

export interface PlannerDetailsOptions {
  GroupId: string;
}

export type AccessOptionsSharedMailbox = 'EmailNotificationEnabled';

export type AccessOptionsSharepoint = 'tbd';

export type AccessOptionsSharepointJira = 'tbd2';

export interface ProjectExtension {
  projectExtensionId: ProjectExtensionId;
  displayName: string;
  key?: string;
  value: string;
  projectExtensionType: ProjectExtensionType;
  projectIds?: ProjectId[];
  options?: ProjectExtensionOption[];
  accesses?: ProjectExtensionAccess[];
  rowVersion: string;
  projectExtensionProcess?: ProjectExtensionProcess;
  hasDefaultAccess?: boolean;
}

export interface ProjectExtensionMe {
  projectExtensionId?: ProjectExtensionId;
  displayName?: string;
  projectExtensionType?: ProjectExtensionType;
  projectExtensionState?: ExtensionState;
  extensionUserState?: ExtensionUserState;
}

export interface CreateProjectExtension {
  displayName: string;
  key?: string;
  value?: string;
  projectExtensionType: ProjectExtensionType;

  addProjectExtensionOptions?: CreateProjectExtensionOption[];
  accesses?: ProjectExtensionAccess[];
  hasDefaultAccess?: boolean;
}

export interface ProjectExtensionSuggestion {
  createProjectExtensionDto: CreateProjectExtension;
  existingProjectExtensions: ProjectExtension[];
  availableProjectExtensions: CreateProjectExtension[];
  errorString: string;
}

export interface GetProjectExtensionSuggestion {
  key: string;
  value: string;
  projectExtensionType: ProjectExtensionType;
}

export interface CreateProjectExtensionOption {
  key: ProjectExtensionOptionKeys;
  value: string | TeamsOption | PlannerDetailsOptions;
}

export interface UpdateProjectExtension {
  displayName?: string;
  key?: string;
  value?: string;

  options?: ProjectExtensionOption;
  accesses?: ProjectExtensionAccess[];
  hasDefaultAccess?: boolean;
}

export interface ProjectExtensionProcess {
  extensionState: ExtensionState;
  errorState?: string;
  isBackgroundWorkerWorking: boolean;
  projectId: ProjectId;
  projectExtensionId: ProjectExtensionId;
  projectExtensionProcessId: string;
}

export interface AvailableProjectExtension {
  projectExtensionType: ProjectExtensionType;
  projectExtensionAvailableState: ProjectExtensionAvailableState;
  description?: string;
  stateExplanation?: string;
  dependencies?: ProjectExtensionType[];
}

export interface ProjectExtensionOption {
  projectExtensionOptionId: ProjectExtensionOptionId;
  projectExtensionId: ProjectExtensionId;
  key: ProjectExtensionOptionKeys;
  value: string;
}

export interface ProjectExtensionAccessOption {
  projectExtensionAccessOptionId: ProjectExtensionAccessOptionId;
  key:
    | AccessOptionsSharedMailbox
    | AccessOptionsSharepoint
    | AccessOptionsSharepointJira;
  value: string;
  rowVersion: string;
  projectExtensionAccessId: ProjectExtensionAccessId;
}

export interface ProjectExtensionAccess {
  projectExtensionAccessId: ProjectExtensionAccessId;
  contactId: ContactId;
  projectId: ProjectId;
  projectExtensionId: ProjectExtensionId;
  projectExtensionAccessOptions?: ProjectExtensionAccessOption[];
  extensionAccessState?: ExtensionAccessState;
  rowVersion: string;
  shouldHaveAccess: boolean;
  projectRoles?: ProjectRole[];
}

export interface ProjectExtensionConfiguration {
  projectExtensionConfigurationId: ProjectExtensionId;
  extensionType: ProjectExtensionType;
  isEnabled: boolean;
  objectJson: string;
  rowVersion: string;
}

export interface UpdateProjectExtensionConfiguration {
  isEnabled: boolean;
  objectJson: string;
  rowVersion: string;
}

export interface CreateProjectExtensionConfiguration {
  extensionType: ProjectExtensionType;
  isEnabled: boolean;
  objectJson: string;
}

export interface JiraConfiguration {
  isEnabled: boolean;
  rowVersion: string;
  objectJson: {
    displayName: string;
    baseURL: string;
    description: string;
    serviceUserCredentials: {
      mail: string;
      apiKey: string;
    };
  };
}

export interface JiraUserConfigurationData {
  isEnabled: boolean;
  objectJson: {
    displayName: string;
    userCredentials: {
      mail: string;
      apiKey: string;
    };
  };
}

export const defaultJiraConfiguration: JiraConfiguration = {
  isEnabled: false,
  rowVersion: '',
  objectJson: {
    displayName: '',
    baseURL: '',
    description: '',
    serviceUserCredentials: {
      mail: '',
      apiKey: '',
    },
  },
};

export interface ProjectExtensionUserConfiguration {
  projectExtensionUserConfigurationId: string;
  contactId: string;
  extensionType: ProjectExtensionType;
  isEnabled: boolean;
  objectJson: string;
  rowVersion: string;
}

export interface UpdateProjectExtensionUserConfiguration {
  isEnabled: boolean;
  objectJson: string;
}

export interface CreateProjectExtensionUserConfiguration {
  contactId: ContactId;
  extensionType: ProjectExtensionType;
  objectJson: string;
}

export const defaultJiraUserConfigurationData: JiraUserConfigurationData = {
  isEnabled: false,
  objectJson: {
    displayName: '',
    userCredentials: {
      mail: '',
      apiKey: '',
    },
  },
};

export interface UpdateUserExtensionAccess {
  projectExtensionId: ProjectExtensionId;
  contactId: ContactId;
  shouldHaveAccess: boolean;
  Options?: ProjectExtensionAccessOption[];
}
