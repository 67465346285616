import React, { CSSProperties, useState } from 'react';
import { Avatar } from 'antd';
import { useSelector } from 'react-redux';
import { stringToColour } from '../util/index';
import { getUserMe } from '../apps/main/rootReducer';
import { User } from '../models/User';
import { Contact } from '../models/Contact';
import { makePrioStyles } from '../theme/utils';

import * as ConfigValues from '../util/configValues';

const useStyles = makePrioStyles((theme) => ({
  avatar: {
    '& .ant-avatar-string': {
      transform: 'translateX(-50%)!important',
    },
  },
  avatarPicture: {
    position: 'relative',
    display: 'inline-block',
    marginBottom: '2px',
  },
  pictureContainer: {
    position: 'relative',
    display: 'inline-block',
  },
  pictureOverlay: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const STYLES: { [key: string]: CSSProperties } = {
  tiny: {
    width: 18,
    height: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 8,
  },
  small: {
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 10,
  },
  medium: {
    width: 35,
    height: 35,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 15,
  },
  large: {
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 25,
  },
  extraLarge: {
    width: 88,
    height: 88,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 44,
  },
};

type AvatarSize = 'tiny' | 'small' | 'medium' | 'large' | 'extraLarge';

interface UserAvatarProps {
  size?: AvatarSize;
  user?: User;
  contact?: Contact;
  backgroundColor?: string;
}

export const UserAvatar: React.FC<UserAvatarProps> = (props) => {
  //#region -------------------------------- Variables
  const { size, user, contact, backgroundColor } = props;
  const userMe = useSelector(getUserMe);
  const classes = useStyles();
  const [pictureError, setPictureError] = useState(false);
  //#endregion

  //#region -------------------------------- Methods
  const getUserOrContactName = (user: User, contact: Contact) => {
    if (contact) {
      return [contact.firstName ?? ' ', contact.lastName ?? ' '];
    }
    if (user) {
      return user.displayName?.split(' ') ?? [' ', ' '];
    }
    return userMe?.displayName?.split(' ') ?? [' ', ' '];
  };

  const getUserOrContactEmail = (user: User, contact: Contact) => {
    if (contact) {
      return contact?.eMail;
    }
    if (user) {
      return user?.mail;
    }
    return userMe?.mail;
  };

  const getStyle = () => {
    switch (size) {
      case 'tiny':
        return STYLES.tiny;

      case 'small':
        return STYLES.small;

      case 'medium':
        return STYLES.medium;

      case 'large':
        return STYLES.large;

      case 'extraLarge':
        return STYLES.extraLarge;
    }
  };

  const getImgStyle = () => {
    const avatarSize = STYLES[size]?.width;
    return { ...useStyles, width: avatarSize, height: avatarSize };
  };

  const handlePictureError = () => {
    setPictureError(true);
  };

  const picture = () => {
    const name = getUserOrContactName(user, contact);
    return (
      <div className={classes.pictureContainer} style={{ overflow: 'visible' }}>
        <img
          className={classes.avatarPicture}
          style={getImgStyle()}
          src={
            ConfigValues.REACT_APP_API_HOST_NAME +
            `/api/contact/public/contact/${
              contact?.contactId ?? userMe?.id ?? user?.id
            }/profilePhoto`
          }
          alt=""
          onError={handlePictureError}
        />
        {pictureError && (
          <div
            className={classes.pictureOverlay}
            style={{
              backgroundColor: backgroundColor
                ? backgroundColor
                : stringToColour(getUserOrContactEmail(user, contact) ?? ''),
              overflow: 'visible',
            }}
          >
            {`${name?.[0]?.[0] ?? ' '}${name?.[1]?.[0] ?? ' '}`}
          </div>
        )}
      </div>
    );
  };

  //#endregion

  return (
    <Avatar className={classes.avatar} style={getStyle()}>
      {picture()}
    </Avatar>
  );
};

export default UserAvatar;
