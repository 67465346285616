import { useMemo } from 'react';
import { FilterPickerConsumerProps } from './FilterPickerDynamicConsumer';
import useCompaniesContext from '../../../modules/companies/hooks/useCompaniesContext';
import useAccessRights2 from '../../../modules/users/hooks/useAccessRights2';

const CompanyFilterPickerConsumer: React.FC<FilterPickerConsumerProps> = (
  props
) => {
  //#region ------------------------------ Defaults
  const { children, roles } = props;
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const { companies: _companies } = useCompaniesContext();

  const { checkOfficeRolesOfCompanyId, checkProjectRolesOfCompanyId } =
    useAccessRights2();

  const companies = useMemo(() => {
    if (roles.officeRoles.length > 0 && roles.projectRoles.length > 0) {
      return _companies.filter(
        (company) =>
          checkOfficeRolesOfCompanyId(roles.officeRoles, company.companyId) ||
          checkProjectRolesOfCompanyId(roles.projectRoles, company.companyId)
      );
    }
    return _companies;
  }, [
    roles,
    _companies,
    checkOfficeRolesOfCompanyId,
    checkProjectRolesOfCompanyId,
  ]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  //#endregion

  //#region ------------------------------ Effects
  //#endregion

  return (
    <>
      {children({
        options: companies.map(({ companyId, fullName, shortName }) => {
          return {
            value: companyId,
            label: fullName,
            searchValue: `${fullName} ${shortName} ${companyId}`,
          };
        }),
      })}
    </>
  );
};

export default CompanyFilterPickerConsumer;
