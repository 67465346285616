import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { makePrioStyles } from '../../../theme/utils';
import { PrioTheme } from '../../../theme/types';
import { Menu } from '@prio365/prio365-react-library';
import MenuItem from '@prio365/prio365-react-library/lib/Menu/components/MenuItem';
import { NewDocumentType } from '../../../models/Document';
import { apiCreateNewDocument, apiFetchDocumentPrefix } from '../api';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { DriveItemId, GroupId, ProjectId } from '../../../models/Types';

const useStyles = makePrioStyles((theme: PrioTheme) => ({
  root: {
    padding: 0,
  },
  menuItem: {
    margin: 0,
    fontSize: theme.font.fontSize.small,
    '& .prio-menu-suffixIcon': {
      display: 'flex',
    },
  },
  activeButton: {
    background: `${theme.colors.application.background.hover}!important`,
  },
  wordIcon: {
    '& > div > div > span > svg': {
      color: `${theme.colors.mimeType.word}!important`,
    },
  },
  powerPointIcon: {
    '& > div > div > span > svg': {
      color: theme.colors.mimeType.powerpoint,
    },
  },
  excelIcon: {
    '& > div > div > span > svg': {
      color: theme.colors.mimeType.excel,
    },
  },
}));

interface CreateNewDocumentDropdownOptionsProps {
  className?: string;
  saveFolderDriveItemId: DriveItemId;
  projectId: ProjectId;
  groupId: GroupId;
  handleReloadDocuments: () => void;
  setOpenNewDocDrawer: (open: boolean) => void;
  setIsDropdownNewDocumentOpen?: (open: boolean) => void;
}

export const CreateNewDocumentDropdownOptions: React.FC<
  CreateNewDocumentDropdownOptionsProps
> = (props) => {
  //#region ------------------------------ Defaults
  const {
    className,
    saveFolderDriveItemId,
    projectId,
    groupId,
    handleReloadDocuments,
    setOpenNewDocDrawer,
    setIsDropdownNewDocumentOpen,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isLoadingPrefix, setIsLoadingPrefix] = useState<boolean>(false);
  const [prefix, setPrefix] = useState<string>(null);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const newDocument = () => {
    setOpenNewDocDrawer(true);
    if (setIsDropdownNewDocumentOpen) {
      setIsDropdownNewDocumentOpen(false);
    }
  };

  const addNewEmptyDocument = async (id: NewDocumentType | 'template') => {
    if (setIsDropdownNewDocumentOpen) {
      setIsDropdownNewDocumentOpen(false);
    }
    if (id === 'template') {
      newDocument();
      return;
    }
    try {
      const { data } = await apiCreateNewDocument({
        documentName: prefix + t(`documents:defaultDocumentNameSuffix.${id}`),
        documentType: id,
        saveFolderDriveItemId: saveFolderDriveItemId,
        groupId,
      });

      if (data) {
        window.open(data.webUrl, '_blank');
        if (handleReloadDocuments) {
          handleReloadDocuments();
        }
      } else {
        notification.open({
          message: t('common:error'),
          description: t(
            'documents:newDocumentForm.errorMessages.createDocumentError'
          ),
        });
      }
    } catch (error) {}
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const fetchPrefix = async () => {
      setIsLoadingPrefix(true);
      const { data } = await apiFetchDocumentPrefix(projectId);
      if (data) {
        setIsLoadingPrefix(false);
        setPrefix(data.value);
      } else {
        setPrefix(null);
      }
    };
    fetchPrefix();
  }, [projectId]);
  //#endregion

  return (
    <Menu className={classNames(className, classes.root)} selectedItemId="">
      <MenuItem
        className={classNames(classes.menuItem, classes.wordIcon)}
        id="docx"
        iconProp={['fas', 'file-word']}
        label={t('documents:newDocumentMenu.word')}
        onClick={() => addNewEmptyDocument('docx')}
        disabled={isLoadingPrefix || !prefix}
      />
      <MenuItem
        className={classNames(classes.menuItem, classes.powerPointIcon)}
        id="pptx"
        iconProp={['fas', 'file-powerpoint']}
        label={t('documents:newDocumentMenu.powerpoint')}
        onClick={() => addNewEmptyDocument('pptx')}
      />
      <MenuItem
        className={classNames(classes.menuItem, classes.excelIcon)}
        id="xlsx"
        iconProp={['fas', 'file-excel']}
        label={t('documents:newDocumentMenu.excel')}
        onClick={() => addNewEmptyDocument('xlsx')}
        disabled={isLoadingPrefix || !prefix}
      />
      <MenuItem
        className={classNames(classes.menuItem)}
        id="template"
        label={t('documents:newDocumentMenu.template')}
        suffixIconProps={['fal', 'arrow-right']}
        onClick={() => addNewEmptyDocument('template')}
        disabled={isLoadingPrefix || !prefix}
      />
    </Menu>
  );
};

export default CreateNewDocumentDropdownOptions;
