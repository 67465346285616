import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Form } from 'antd';
import { Button, Checkbox, Select } from '@prio365/prio365-react-library';
import { makePrioStyles } from '../../../theme/utils';
import { TimeRecordId } from '../../../models/Types';
import { useSelector } from 'react-redux';
import {
  RootReducerState,
  getTimeRecord,
} from '../../../apps/main/rootReducer';
import { TimeRecord } from '../../../models/TimeRecord';
import Flex from '../../../components/Flex';
import { useForm } from 'antd/lib/form/Form';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TimeAndLeaveManagementTimelineItem } from '../../timeAndLeaveManagement/selectors';
import { isTemporaryId } from '../../../util';
import DebouncedInputSearch from '../../../components/DebouncedInputField/DebouncedInputSearch';
import DebouncedInputNumber from '../../../components/DebouncedInputField/DebouncedInputNumber';
import equals from 'deep-equal';
import DebouncedInputTextArea from '../../../components/DebouncedInputTextArea';
import { ProjectPhase } from '../../../models/ProjectPhase';
import { apiGetProjectPhases } from '../../projects/api';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  fullWidth: {
    width: '100%',
    minWidth: 150,
  },
  danger: {
    color: theme.old.palette.chromaticPalette.red,
    background: 'transparent',
    '&:hover': {
      backgroundColor: theme.old.palette.chromaticPalette.red,
      color: theme.old.palette.chromaticPalette.white + '!important',
      '& > .prio-button-icon': {
        color: theme.old.palette.chromaticPalette.white,
      },
    },
    '& > .prio-button-icon': {
      color: theme.old.palette.chromaticPalette.red,
    },
  },
}));

export interface TimeRecordFormModel {
  title: string;
  description: string;
  kilometerDistance?: number;
  isSeparateService?: boolean;
  projectPhaseId?: string;
}

interface TimeKeepingEditTimeRecordFormProps {
  className?: string;
  selectedItem?: TimeAndLeaveManagementTimelineItem;
  onFinish: (
    value: TimeRecordFormModel,
    timeRecord?: TimeAndLeaveManagementTimelineItem
  ) => void;
  onCancel: (item: TimeAndLeaveManagementTimelineItem) => void;
  onDelete: (timeRecordId?: TimeRecordId) => void;
  loading: boolean;
}

export const TimeKeepingEditTimeRecordForm: React.FC<
  TimeKeepingEditTimeRecordFormProps
> = (props) => {
  //#region ------------------------------ Defaults
  const { className, selectedItem, loading, onFinish, onCancel, onDelete } =
    props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<PrioTheme>();
  const [form] = useForm<TimeRecordFormModel>();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const timeRecord = useSelector<RootReducerState, TimeRecord>((state) =>
    getTimeRecord(state, selectedItem?.id)
  );

  const initialValue: TimeRecordFormModel = useMemo(
    () => ({
      title: timeRecord ? timeRecord.title : '',
      description: timeRecord ? timeRecord.description : '',
      kilometerDistance: timeRecord ? timeRecord.kilometerDistance : 0,
      projectPhaseId: timeRecord ? timeRecord.projectPhaseId : null,
      isSeparateService: timeRecord ? timeRecord.isSeparateService : false,
    }),
    [timeRecord]
  );

  const [isFormUpdated, setIsFormUpdated] = useState<boolean>(false);
  // get projectPhases
  const [projectPhases, setProjectPhases] = useState<ProjectPhase[]>([]);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleFinish: (value: TimeRecordFormModel) => void = (value) => {
    if (selectedItem) {
      onFinish(value, selectedItem);
      setIsFormUpdated(false);
    }
  };
  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    const getProjectPhases = async () => {
      // get projectPhases
      let projectId = selectedItem.groupId;
      if (projectId) {
        const { data } = await apiGetProjectPhases(projectId);
        setProjectPhases(
          data.filter((phase) => phase.isActive && !phase.isArchived)
        );
      } else {
        setProjectPhases([]);
      }
    };
    if (!equals(form.getFieldsValue(), initialValue)) {
      form.setFieldsValue(initialValue);
    }
    getProjectPhases();
  }, [form, initialValue, selectedItem.groupId]);
  //#endregion

  return (
    <Form<TimeRecordFormModel>
      className={classNames(classes.root, className)}
      initialValues={initialValue}
      onFinish={handleFinish}
      onValuesChange={(_, allValues) => {
        if (!equals(allValues, initialValue)) {
          setIsFormUpdated(true);
        } else setIsFormUpdated(false);
      }}
      form={form}
      layout="vertical"
    >
      <Flex.Column>
        <Flex.Row flexWrap="wrap" columnGap={theme.old.spacing.defaultPadding}>
          <Form.Item
            name="title"
            label={t('timeRecords:form.labels.title')}
            style={{ flex: 1 }}
            rules={[
              {
                required: true,
                message: t('timeRecords:form.validation.missingTitle'),
              },
            ]}
          >
            <DebouncedInputSearch
              className={classes.fullWidth}
              disabled={loading}
            />
          </Form.Item>
          <Form.Item
            name="kilometerDistance"
            label={t('timeRecords:form.labels.kilometerDistance')}
            style={{ flex: 1 }}
            rules={[
              {
                message: t(
                  'timeRecords:form.validation.invalidKilometerDistance'
                ),
                pattern: new RegExp('[0-9]+'),
              },
              () => ({
                async validator(rule, value) {
                  if (value < 0) {
                    return Promise.reject(
                      t('timeRecords:form.validation.invalidKilometerDistance')
                    );
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <DebouncedInputNumber
              className={classes.fullWidth}
              min={0}
              addonAfter={<FontAwesomeIcon icon={['fal', 'road']} />}
              disabled={loading}
            />
          </Form.Item>
        </Flex.Row>
        <Flex.Row
          flexWrap="wrap"
          columnGap={theme.old.spacing.defaultPadding}
          alignItems="center"
        >
          <Form.Item
            name="isSeparateService"
            style={{ flex: 1, height: '100%' }}
            valuePropName="checked"
            label={`${t('timeRecords:form.labels.isSeparateService')}`}
          >
            <Checkbox disabled={loading} style={{ height: '100%' }} />
          </Form.Item>
          <Form.Item
            name="projectPhaseId"
            style={{ flex: 1 }}
            label={`${t('timeRecords:form.labels.projectPhase')} ${
              projectPhases?.length === 0
                ? t('timeRecords:form.labels.noProjectPhase')
                : ''
            }`}
          >
            <Select<string>
              showSearch
              size="small"
              disabled={projectPhases?.length === 0 || loading}
            >
              {projectPhases?.map((phase) => (
                <Select.Option value={phase.projectPhaseId}>
                  {phase.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Flex.Row>
        <Form.Item
          name="description"
          label={t('timeRecords:form.labels.description')}
        >
          <DebouncedInputTextArea
            className={classes.fullWidth}
            autoSize={{ minRows: 2, maxRows: 5 }}
            disabled={loading}
          />
        </Form.Item>
      </Flex.Column>

      <Flex.Row>
        <Flex.Item flex={1}>
          {!isTemporaryId(selectedItem?.id) && (
            <Button
              type="default"
              onClick={() => onDelete(selectedItem?.id)}
              disabled={loading}
              className={classes.danger}
            >
              {t('common:actions.delete')}
            </Button>
          )}
        </Flex.Item>
        <>
          <Button
            onClick={() => onCancel(selectedItem)}
            style={{ marginRight: '8px' }}
            disabled={loading}
            type="default"
          >
            {t('common:actions.cancel')}
          </Button>
          <Button htmlType="submit" disabled={loading || !isFormUpdated}>
            {t('common:actions.save')}
          </Button>
        </>
      </Flex.Row>
    </Form>
  );
};

export default TimeKeepingEditTimeRecordForm;
