import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocumentTemplateId } from '../../../../models/Types';
import Flex from '../../../../components/Flex';
import { makePrioStyles } from '../../../../theme/utils';
import { Col, Form, Row } from 'antd';
import DocumentTemplatePicker from '../DocumentTemplatePicker';
import classNames from 'classnames';
import DocumentTemplateCategoryContextProvider from '../DocumentTemplateCategoryContextProvider';
import { DocumentTemplate } from '../../../../models/DocumentTemplate';
import useFilterContext, {
  useFilterContextDataConvertion,
} from '../../../../components/Filter/hooks/useFilterContext';

const useStyles = makePrioStyles((theme) => ({
  root: {},
  newDocument: {
    height: 'calc(100% - 294px)',
  },
  rightPanel: {
    borderLeft: theme.old.borders.sub,
    backgroundColor: theme.old.palette.backgroundPalette.sub,
  },
}));

interface DocumentTemplateFormSelectorProps {
  className?: string;
  children?: React.ReactNode;
  isSaving: boolean;
  showOnlyReports?: boolean;
  onTemplateIdChange?: (documentTemplateId: DocumentTemplateId) => void;
  onCancel: () => void;
  onChange?: () => void;
  cancelable?: boolean;
}

export const DocumentTemplateFormSelector: React.FC<
  DocumentTemplateFormSelectorProps
> = (props) => {
  const { className, children, onTemplateIdChange } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [templateId, setTemplateId] = useState<DocumentTemplateId>();
  const { data, isLoading } = useFilterContext<DocumentTemplate>();
  const documentTemplates = useFilterContextDataConvertion(data);
  // states
  const handleTemplateChange = (templateId: DocumentTemplateId) => {
    setTemplateId(templateId);
    if (onTemplateIdChange) {
      onTemplateIdChange(templateId);
    }
  };
  return (
    <DocumentTemplateCategoryContextProvider>
      <Flex.Column
        className={classNames(classes.root, className)}
        id={'prio-project-document-template-selector'}
      >
        <Form layout="vertical">
          <Row>
            <Col span={24}>
              <Form.Item label={t('documents:newDocumentForm.labels.template')}>
                <DocumentTemplatePicker
                  value={templateId}
                  onChange={handleTemplateChange}
                  showSearch
                  propsDocumentTemplates={documentTemplates}
                  placeholder={
                    isLoading
                      ? t(
                          'documents:newDocumentForm.placeholders.templateLoading'
                        )
                      : t('documents:newDocumentForm.placeholders.template')
                  }
                  emptyMessage={t(
                    'documents:newDocumentForm.noMatchingTemplate'
                  )}
                  disabled={isLoading}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        <Flex.Column flex={1}>{children}</Flex.Column>
      </Flex.Column>
    </DocumentTemplateCategoryContextProvider>
  );
};

export default DocumentTemplateFormSelector;
