import { makePrioStyles } from '../../theme/utils';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  FilterSelectPicker,
  LogikFilterPicker,
  Button,
  HistoryItem,
  BasisFilterPicker,
  DateRangePicker,
  MonthRangePicker,
  YearRangePicker,
  BooleanFilterPicker,
  formatDateRangePickerValueFromUrlString,
} from '@prio365/prio365-react-library';
import classNames from 'classnames';
import FilterPicker, { FilterPickerTypeStrings } from './FilterPicker';
import { FilterBarHistoryDrawer } from './FilterBarHistoryDrawer';
import useFilterContext from './hooks/useFilterContext';
import { GlobalRole, OfficeRole, ProjectRole } from '../../models/Types';
import { useTranslation } from 'react-i18next';
import FilterPickerSkeleton from './FilterPickerSkeleton';

import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  RootReducerState,
  getIsFetchingSearchConfig,
  getSearchConfigBySearchType,
} from '../../apps/main/rootReducer';
import { fetchSearchConfigBySearchType } from './actions';
import { SearchType } from './types';

const CLASS_PREFIX = 'prio-filter-bar';

export const FILTER_DATA_LIST_CLASS_PREFIX = 'prio-filter-data-list';

const ALLOWED_METHODS_HIERACHY: AllowedMethods[] = ['like', 'eq', 'in'];

const useStyles = makePrioStyles((theme) => ({
  bar: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 'fit',
    gap: `${theme?.old.spacing?.baseSpacing}px`,
    backgroundColor: `${theme?.old.palette?.chromaticPalette.white}`,
    marginBottom: theme.spacing.large,
  },
  filterContainerOuter: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    position: 'relative',
    flexGrow: 1,
    maxHeight: 136,
    overflowY: 'auto',
  },
  filterContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: `${theme?.old?.spacing?.baseSpacing}px`,
    overflowX: 'scroll',
    overflowY: 'hidden',
  },
  filters: {
    display: 'contents',
  },
  actionsContainer: {
    marginTop: 4,
    gap: `${theme?.old?.spacing?.baseSpacing * 2}px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  resetFilterButton: {
    '& > svg': {
      color: theme?.old?.palette?.chromaticPalette?.red,
    },
    '&:hover': {
      backgroundColor: `${theme?.old?.palette?.chromaticPalette?.red}20`,
    },
  },
  fullHeight: {
    height: '100%',
  },
  fitWidth: {
    width: 'fit-content',
  },
}));

type AllowedMethods =
  | 'eq'
  | 'ne'
  | 'gt'
  | 'lt'
  | 'ge'
  | 'le'
  | 'in'
  | 'notin'
  | 'like'
  | 'notlike'
  | 'isnull'
  | 'isnotnull';
type AllowedParameterTypes =
  | 'string'
  | 'number'
  | 'guid'
  | 'date'
  | 'dateTime'
  | 'enum'
  | 'boolean'
  | 'list'
  | 'year'
  | 'month';

interface SelectOptionsInterface {
  selectValue: string;
  selectValueTranslated: string;
}

export declare type FilterRole = GlobalRole | OfficeRole | ProjectRole;

interface DefaultParameter {
  defaultValue: string | string[];
  defaultMethod: string;
}

export interface DefaultSearchParameterItem extends DefaultParameter {
  parameterName: string;
}

export interface FilterConfig {
  parameterName: string;
  parameterNameTranslated: string;
  parameterType: AllowedParameterTypes;
  pickerType: string;
  allowedMethods: AllowedMethods[]; // allowedMethods (type) muss noch aus komponenten bibliothek importiert werden
  defaultValues: DefaultParameter[];
  selectOptions: SelectOptionsInterface[];
  sortKey: number;
  showAlways: boolean;
  pickerRoles: FilterRole[];
}

export interface FilterBarData {
  searchType: SearchType;
  searchableParameters: FilterConfig[];
  fetchDate?: string;
}

interface PickerStrings {
  method: string;
  value: string;
}

export interface FilterBarRef {
  handleResetFilterParameterFromOutside: () => void;
}

interface FilterBarProps {
  /**
   * The class name of the filter bar
   */
  className?: string;
  /**
   * If true, the URL query will not be updated when the filter state changes
   * @default false
   */
  disableURLQuery?: boolean;
  /**
   * Everytime the filter state changes, this function will be called with the new search string
   */
  onChange?: (searchString: string) => void;
  /**
   * An Array of Additional Default Search Parameters that extend the default search parameters coming from search parameter API Call. If both sources provide a default value for the same parameter, the customDefaultSearchParameters will overwrite the default value from the search parameter API Call.
   */
  customDefaultSearchParameters?: DefaultSearchParameterItem[];
  /**
   * Disables the filter picker for the given filter names
   */
  disabledPickers?: string[];
  /**
   * Search String to filter the data according to the contexttype
   */
  contextSearchString?: string;
  /**
   * Whether to fetch search on mount (initial rendering) of filter bar.
   * @default true
   */
  fetchSearchOnMount?: boolean;
  /**
   * Filter pickers that shall be hidden
   */
  hiddenPickers?: string[];
  /**
   * Function to set the search string to the parent component
   */
  setSearchStringParent?: (searchString: string) => void;
}

export const FilterBar = forwardRef(
  (props: FilterBarProps, ref: React.Ref<FilterBarRef>) => {
    //#region -------------------------------- Defaults
    const {
      className = '',
      disableURLQuery = false,
      contextSearchString,
      onChange = () => {},
      customDefaultSearchParameters = [],
      disabledPickers = [],
      fetchSearchOnMount = true,
      hiddenPickers = [],
      setSearchStringParent,
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    //#endregion

    //#region -------------------------------- States / Selectors
    const containerRef = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [isInitialFetch, setIsInitialFetch] = useState<boolean>(true);
    const [isClearClicked, setIsClearClicked] = useState<boolean>(false);

    const [selectedPickers, setSelectedPickers] = useState<string[]>(
      disableURLQuery ? [] : searchParams.get('pickedFilters')?.split(',') || []
    );

    const [searchString, setSearchString] = useState<string>(
      disableURLQuery ? '' : searchParams.get('s') || ''
    );

    const isSearchParamsInUrlEmpty = useMemo(() => {
      return searchParams.get('s') === null;
    }, [searchParams]);

    const cleanSearchString = useMemo(() => {
      const searchStringSplit =
        searchString?.split('&')?.map((s) => s?.trim()) || [];
      const newSearchString = searchStringSplit
        .filter((string) => string.length > 0)
        .join(' & ');
      return newSearchString;
    }, [searchString]);

    const searchStringRef = useRef<string>(cleanSearchString);

    const [lastSearchedCleanSearchString, setLastSearchedCleanSearchString] =
      useState<string>(searchString);

    const {
      fetchSearch,
      clearSearch,
      isFetching: isFetchingResults,
      searchType,
      isError,
    } = useFilterContext();

    const searchFilterConfig = useSelector((state: RootReducerState) =>
      getSearchConfigBySearchType(state, searchType)
    );

    const isFetchingConfig = useSelector(getIsFetchingSearchConfig);

    const checkIfSearchConfigOutdated = (searchFilterConfig: FilterBarData) => {
      if (!searchFilterConfig?.fetchDate) return true;
      const fetchDate = moment(searchFilterConfig?.fetchDate);
      const now = moment();
      const outdated = fetchDate.isBefore(now, 'month');
      return outdated;
    };

    const isFetchingSearchConfigNeeded = useMemo(() => {
      return (
        !searchFilterConfig || checkIfSearchConfigOutdated(searchFilterConfig)
      );
    }, [searchFilterConfig]);

    const { filters, requiredFilters, optionalFilters } = useMemo<{
      filters: FilterConfig[];
      requiredFilters: FilterConfig[];
      optionalFilters: FilterConfig[];
    }>(() => {
      let filters;
      if (disabledPickers) {
        filters = searchFilterConfig?.searchableParameters.filter(
          (item) => !disabledPickers?.includes(item.parameterName)
        );
      } else {
        filters = searchFilterConfig?.searchableParameters;
      }
      const displayedFilters = filters?.filter(
        (f) => !hiddenPickers?.includes(f?.parameterName)
      );
      const requiredFilters = displayedFilters?.filter((f) => f?.showAlways);
      const optionalFilters = displayedFilters?.filter((f) => !f?.showAlways);
      return { filters, requiredFilters, optionalFilters };
    }, [searchFilterConfig, disabledPickers, hiddenPickers]);

    const filtersForFilterPicker = useMemo(() => {
      return (optionalFilters ?? [])
        .sort((a, b) => a.sortKey - b.sortKey)
        .map((f) => ({
          value: f.parameterName,
          label: f.parameterNameTranslated || f.parameterName,
          searchValue: f.parameterNameTranslated || f.parameterName,
        }));
    }, [optionalFilters]);

    const defaultSearchParametersFromSearchConfig = useMemo(() => {
      const pickers =
        filters?.reduce((acc, f) => {
          const isDefaultParameter = !!f?.defaultValues;

          const defaultParameters: DefaultSearchParameterItem[] =
            (f?.defaultValues?.reduce((acc, value) => {
              const isInCustom = customDefaultSearchParameters?.find(
                (v) => v?.parameterName === f?.parameterName
              );

              const parameterName = f?.parameterName;
              const parameterValues = value;

              const parameter: DefaultSearchParameterItem = {
                parameterName,
                defaultValue: parameterValues?.defaultValue,
                defaultMethod: parameterValues?.defaultMethod,
              };

              return isDefaultParameter && !isInCustom
                ? [...acc, parameter]
                : acc;
            }, []) as DefaultSearchParameterItem[]) || [];

          if (!isDefaultParameter) return acc;
          return [...acc, ...defaultParameters];
        }, []) || [];
      return pickers;
    }, [filters, customDefaultSearchParameters]);

    const combinedDefaultParameter = useMemo(() => {
      return [
        ...customDefaultSearchParameters,
        ...defaultSearchParametersFromSearchConfig,
      ];
    }, [
      customDefaultSearchParameters,
      defaultSearchParametersFromSearchConfig,
    ]);

    const defaultSearchParameters = useMemo(() => {
      const defaultValuesString = combinedDefaultParameter.reduce(
        (acc, value) => {
          const _value = value
            ? `${value?.parameterName} ${value?.defaultMethod} '${
                Array.isArray(value?.defaultValue)
                  ? value?.defaultValue?.join("','")
                  : value?.defaultValue
              }'`
            : '';

          if (!_value) return acc;
          return `${acc}${acc ? ' & ' : ''}${_value}`.trim();
        },
        ''
      );

      return defaultValuesString;
    }, [combinedDefaultParameter]);

    const addedPickerNames = useMemo(() => {
      const pickers = selectedPickers.filter(
        // only add optional pickers to filter bar
        (picker) => optionalFilters?.find((f) => f?.parameterName === picker)
      );
      return pickers;
    }, [selectedPickers, optionalFilters]);
    //#endregion

    //#region ------------------------------ Functions / Handlers
    const deletePickerStrings = useCallback((parameterName: string) => {
      setSearchString((searchString) => {
        const searchStringSplit = (searchString?.split('&') || []).map((s) =>
          s.trim()
        );
        const newSearchString = searchStringSplit
          .filter((string) => !string.split(' ')?.[0]?.includes(parameterName))
          .join(' & ');
        return newSearchString;
      });
    }, []);

    // want multiple values? separate value by comma
    const setPickerStrings = useCallback(
      (parameterName: string, values: PickerStrings[]) => {
        setSearchString((searchString) => {
          const searchStringSplit = (searchString?.split('&') || []).map((s) =>
            s.trim()
          );
          const newSearchString =
            searchStringSplit.filter(
              (string) => !string.split(' ')?.[0]?.includes(parameterName)
            ) || [];
          const newValues =
            values
              ?.filter(
                (v) =>
                  v.value !== null &&
                  v.value !== undefined &&
                  v.value !== 'null'
              )
              ?.map(
                (value) =>
                  `${parameterName} ${value.method} '${value.value
                    .split(',')
                    .join("','")}'`
              ) || [];
          const combinedSearchString = [...newSearchString, ...newValues]
            .filter((s) => s?.length > 0)
            .join(' & ');

          return combinedSearchString;
        });
      },
      []
    );

    const getPickerStrings: (parameterName: string) => PickerStrings[] =
      useCallback(
        (parameterName: string) => {
          return calculatePickerStringsBasedOnSearchString(
            parameterName,
            searchString
          );
        },
        [searchString]
      );

    const handleDeleteFilterPickers = useCallback(
      (addedPickerNames: string[]) => {
        var notAddedPickers =
          optionalFilters?.filter(
            (f) => !addedPickerNames?.includes(f?.parameterName)
          ) || [];
        if (notAddedPickers?.length > 0) {
          notAddedPickers.forEach((f) => {
            deletePickerStrings(f?.parameterName);
          });
        }
      },
      [optionalFilters, deletePickerStrings]
    );

    const handleSetPickers = useCallback((searchString: string) => {
      setSelectedPickers(() => {
        const pickers = searchString
          .split('&')
          .map((s) => s.trim())
          .map((s) => s.split(' ')[0]);
        return pickers;
      });
    }, []);

    const handleFetchSearch = useCallback(
      (searchString: string) => {
        setLastSearchedCleanSearchString(searchString);
        fetchSearch(searchString);
        handleSetPickers(searchString);
        setIsClearClicked(false);
      },
      [fetchSearch, handleSetPickers]
    );

    const handleFilterFromHistory = useCallback(
      (historyItem: HistoryItem) => {
        const searchString = historyItem?.searchString || '';

        // addOptionalFilters based on search string (ignore already added pickers)
        const searchStringSplit =
          searchString?.split('&')?.map((s) => s?.trim()) || [];
        setSelectedPickers((selectedPickers) => {
          const newPickers = searchStringSplit.map(
            (string) => string.split(' ')?.[0]
          );

          const bothPickers = [...selectedPickers, ...newPickers];
          const uniqueNewPickers = Array.from(new Set(bothPickers));
          handleDeleteFilterPickers(uniqueNewPickers);
          return uniqueNewPickers;
        });

        setSearchString(searchString);

        setOpenDrawer(false);

        handleFetchSearch(historyItem?.searchString);
      },
      [handleFetchSearch, handleDeleteFilterPickers]
    );

    const handleClearSearch = useCallback(() => {
      setIsClearClicked(true);
      if (contextSearchString) {
        setSearchString(contextSearchString);
        handleFetchSearch(contextSearchString);
      } else {
        setSearchString('');
        setLastSearchedCleanSearchString('');
        clearSearch();
      }
      setSelectedPickers([]);
      handleDeleteFilterPickers([]);
    }, [
      clearSearch,
      handleDeleteFilterPickers,
      contextSearchString,
      handleFetchSearch,
    ]);

    const handleResetFilterParameterFromOutside = useCallback(() => {
      setSearchString('');
      setSelectedPickers([]);
      setLastSearchedCleanSearchString('');
    }, []);

    const handleSetDefaultValues = useCallback(() => {
      setSearchString(defaultSearchParameters);
      handleFetchSearch(defaultSearchParameters);
    }, [defaultSearchParameters, handleFetchSearch]);
    //#endregion

    //#region ------------------------------ Effects
    useEffect(() => {
      if (
        isSearchParamsInUrlEmpty &&
        searchFilterConfig &&
        !isClearClicked &&
        !isInitialFetch
      ) {
        setIsInitialFetch(true);
        setSearchString(defaultSearchParameters);
        handleFetchSearch(defaultSearchParameters);
      }
    }, [
      defaultSearchParameters,
      searchType,
      isSearchParamsInUrlEmpty,
      searchFilterConfig,
      handleFetchSearch,
      isClearClicked,
      isInitialFetch,
    ]);

    useEffect(() => {
      if (isFetchingSearchConfigNeeded) {
        dispatch(fetchSearchConfigBySearchType(searchType));
      }
    }, [isFetchingSearchConfigNeeded, dispatch, searchType]);

    useEffect(() => {
      if (
        searchString === '' &&
        defaultSearchParameters !== '' &&
        !isClearClicked
      ) {
        setSearchString(defaultSearchParameters);
        setLastSearchedCleanSearchString(defaultSearchParameters);
      }
    }, [defaultSearchParameters, searchString, isClearClicked]);

    useEffect(() => {
      if (fetchSearchOnMount && isInitialFetch && cleanSearchString) {
        setIsInitialFetch(false);
        handleFetchSearch(cleanSearchString);
      }
    }, [
      cleanSearchString,
      handleFetchSearch,
      fetchSearchOnMount,
      isInitialFetch,
    ]);

    useEffect(() => {
      if (
        fetchSearchOnMount &&
        isInitialFetch &&
        !contextSearchString &&
        !cleanSearchString
      ) {
        if (!defaultSearchParameters) {
          handleClearSearch();
          setIsInitialFetch(false);
        }
      }
    }, [
      contextSearchString,
      cleanSearchString,
      handleClearSearch,
      fetchSearchOnMount,
      isInitialFetch,
      defaultSearchParameters,
    ]);

    useEffect(() => {
      if (!disableURLQuery)
        setSearchParams((searchParams: any) => {
          if (cleanSearchString?.length <= 0) searchParams.delete('s');
          else searchParams.set('s', cleanSearchString);

          if (selectedPickers?.length <= 0)
            searchParams.delete('pickedFilters');
          else searchParams.set('pickedFilters', selectedPickers.join(','));
          return searchParams;
        });
    }, [cleanSearchString, selectedPickers, disableURLQuery, setSearchParams]);

    useEffect(() => {
      if (!searchFilterConfig) {
        dispatch(fetchSearchConfigBySearchType(searchType));
      }
    }, [searchFilterConfig, dispatch, searchType]);

    useEffect(() => {
      searchStringRef.current = searchString;
      onChange(searchString);
    }, [searchString, onChange]);

    useEffect(() => {
      if (setSearchStringParent) setSearchStringParent(searchString);
    }, [searchString, setSearchStringParent]);

    useImperativeHandle(ref, () => ({
      handleResetFilterParameterFromOutside,
    }));
    //#endregion

    return (
      <div className={classNames(className, CLASS_PREFIX, classes?.bar)}>
        <div className={classes?.filterContainerOuter}>
          <div
            id="filtersContainer"
            ref={containerRef}
            className={classNames(classes?.filterContainer, 'scrollbar-hide')}
          >
            <div id="filters" className={classes?.filters}>
              {!filters?.[0] ? (
                <>
                  <FilterPickerSkeleton />
                  <FilterPickerSkeleton />
                  <FilterPickerSkeleton />
                </>
              ) : (
                <>
                  {requiredFilters
                    .sort((a, b) => b?.sortKey - a?.sortKey)
                    .map((filter, i) => (
                      <DynamicFilterContainer
                        key={filter?.parameterName + i}
                        deletePickerStrings={deletePickerStrings}
                        setPickerStrings={setPickerStrings}
                        pickerStrings={getPickerStrings(filter?.parameterName)}
                        filter={filter}
                      />
                    ))}
                  {addedPickerNames?.map((name, i) => {
                    const filter = optionalFilters.find(
                      (f) => f?.parameterName === name
                    );

                    return (
                      <DynamicFilterContainer
                        key={filter?.parameterName + i}
                        deletePickerStrings={deletePickerStrings}
                        setPickerStrings={setPickerStrings}
                        pickerStrings={getPickerStrings(filter?.parameterName)}
                        filter={filter}
                      />
                    );
                  })}
                  {optionalFilters?.length > 0 && (
                    <div
                      onContextMenu={(e) => {
                        e.preventDefault();
                        setSelectedPickers([]);
                        handleDeleteFilterPickers([]);
                      }}
                    >
                      <FilterSelectPicker
                        value={addedPickerNames || []}
                        onChange={(names) => {
                          setSelectedPickers(names);
                          handleDeleteFilterPickers(names);
                        }}
                        filters={filtersForFilterPicker}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className={classNames('prio-filter-bar-actions', classes.fullHeight)}
        >
          <div className={classes?.actionsContainer}>
            <Button
              disabled={
                isFetchingConfig ||
                (selectedPickers?.length <= 0 && searchString?.length <= 0)
              }
              key={searchParams.size}
              iconProp={['fal', 'trash']}
              className={
                searchParams.size <= 0 ? undefined : classes.resetFilterButton
              }
              onClick={handleClearSearch}
              type="default"
              tooltip={t('components:filter.actions.clear')}
            ></Button>
            <Button
              disabled={
                isFetchingConfig || searchString === defaultSearchParameters
              }
              iconProp={['fal', 'rotate-left']}
              onClick={handleSetDefaultValues}
              type="default"
              tooltip={t('components:filter.actions.setDefault')}
            ></Button>
            <Button
              disabled={isFetchingConfig}
              iconProp={['fal', 'folder-bookmark']}
              onClick={() => setOpenDrawer(true)}
              type="default"
              tooltip={t('components:filter.actions.showHistory')}
            ></Button>
            <Button
              loading={isFetchingResults || isFetchingConfig}
              disabled={(isFetchingResults || isFetchingConfig) && !isError}
              iconProp={['fal', 'magnifying-glass']}
              tooltip={t('components:filter.actions.search')}
              onClick={() => handleFetchSearch(cleanSearchString)}
              type={
                cleanSearchString === lastSearchedCleanSearchString
                  ? 'default'
                  : 'primary'
              }
            ></Button>
          </div>
          <FilterBarHistoryDrawer
            onFilterAgain={handleFilterFromHistory}
            visible={openDrawer}
            filters={filters}
            setVisible={setOpenDrawer}
            searchType={searchType}
          />
        </div>
      </div>
    );
  }
);

interface DynamicFilterProps {
  filter: FilterConfig;
  pickerStrings: PickerStrings[];
  setPickerStrings: (parameterName: string, value: PickerStrings[]) => void;
  deletePickerStrings: (parameterName: string) => void;
}

const DynamicFilterContainer: React.FC<DynamicFilterProps> = (props) => {
  const { filter, deletePickerStrings } = props;

  return (
    <div
      onContextMenu={(e) => {
        e.preventDefault();
        deletePickerStrings(filter?.parameterName);
      }}
    >
      <DynamicFilter {...props} />
    </div>
  );
};

const DynamicFilter: React.FC<DynamicFilterProps> = (props) => {
  const { filter, pickerStrings, setPickerStrings } = props;

  const classes = useStyles();

  const { t } = useTranslation();

  const getFilterMethod = (allowedMethods: string[]): string => {
    for (let i = 0; i < ALLOWED_METHODS_HIERACHY.length; i++) {
      if (allowedMethods.includes(ALLOWED_METHODS_HIERACHY[i])) {
        return ALLOWED_METHODS_HIERACHY[i];
      }
    }
    return allowedMethods[0];
  };

  if (FilterPickerTypeStrings?.includes(filter?.pickerType))
    return (
      <FilterPicker
        zIndex="50000"
        className={classes.fitWidth}
        type={filter?.pickerType}
        prefixOption={
          filter?.parameterNameTranslated || filter?.parameterName
            ? `${
                filter?.parameterNameTranslated !== ''
                  ? filter?.parameterNameTranslated
                  : filter?.parameterName
              }:`
            : undefined
        }
        value={pickerStrings?.[0]?.value?.split(',') || null}
        onChange={(value) =>
          setPickerStrings(filter?.parameterName, [
            {
              method: getFilterMethod(filter.allowedMethods),
              value: value?.length > 0 ? value.join(',') : null,
            },
          ])
        }
        roles={filter?.pickerRoles}
      />
    );

  if (filter?.selectOptions?.[0]) {
    const mappedSelectOptions = filter?.selectOptions?.map((option) => ({
      value: option?.selectValue,
      label: option?.selectValueTranslated,
      searchValue: `${option?.selectValueTranslated} ${option?.selectValue}`,
    }));
    return (
      <BasisFilterPicker
        zIndex="50000"
        onChange={(value) =>
          setPickerStrings(filter?.parameterName, [
            {
              method: getFilterMethod(filter.allowedMethods),
              value: value?.length > 0 ? value.join(',') : null,
            },
          ])
        }
        value={pickerStrings?.[0]?.value?.split(',') || null}
        prefixOption={
          filter?.parameterNameTranslated || filter?.parameterName
            ? `${
                filter?.parameterNameTranslated !== ''
                  ? filter?.parameterNameTranslated
                  : filter?.parameterName
              }:`
            : undefined
        }
        options={mappedSelectOptions}
        placeholder={t('components:filter.all')}
        mode="multiple"
      />
    );
  }

  switch (filter?.parameterType) {
    case 'date':
    case 'dateTime':
      // es-lint-disable-next-line
      var [startISOString, endISOString] =
        pickerStrings?.map((S) => S.value).sort((a, b) => a.localeCompare(b)) ||
        [];

      return (
        <DateRangePicker
          prefixOption={
            filter?.parameterNameTranslated || filter?.parameterName
          }
          key={filter?.parameterName}
          onChange={(value) => {
            setPickerStrings(filter?.parameterName, [
              {
                method: 'ge',
                value: value?.start?.utc(true)?.toISOString(true) || null,
              },
              {
                method: 'le',
                value: value?.end?.utc(true)?.toISOString(true) || null,
              },
            ]);
          }}
          value={formatDateRangePickerValueFromUrlString(
            (startISOString?.length > 5
              ? moment(startISOString)?.utc(true)?.toISOString(true)
              : null) +
              '/' +
              (endISOString?.length > 5
                ? moment(endISOString)
                    ?.endOf('day')
                    ?.utc(true)
                    ?.subtract(1.5, 'day')
                    ?.toISOString(true)
                : null)
          )}
        />
      );
    case 'month':
      // es-lint-disable-next-line
      [startISOString, endISOString] =
        pickerStrings?.map((S) => S.value).sort((a, b) => a.localeCompare(b)) ||
        [];
      return (
        <MonthRangePicker
          prefixOption={
            filter?.parameterNameTranslated || filter?.parameterName
          }
          onChange={(value) =>
            setPickerStrings(filter?.parameterName, [
              {
                method: 'ge',
                value: value?.[0]?.utc(true)?.toISOString(true) || null,
              },
              {
                method: 'le',
                value: value?.[1]?.utc(true)?.toISOString(true) || null,
              },
            ])
          }
          value={[
            startISOString?.length > 5
              ? moment(startISOString)?.utc(true)
              : null,
            endISOString?.length > 5
              ? moment(endISOString)?.utc(true)?.subtract(2, 'hours')
              : null,
          ]}
          className={classes.fitWidth}
        />
      );
    case 'year':
      // es-lint-disable-next-line
      [startISOString, endISOString] =
        pickerStrings?.map((S) => S.value).sort((a, b) => a.localeCompare(b)) ||
        [];

      return (
        <YearRangePicker
          prefixOption={
            filter?.parameterNameTranslated || filter?.parameterName
          }
          onChange={(value) =>
            setPickerStrings(filter?.parameterName, [
              {
                method: 'ge',
                value: value?.[0]?.utc(true)?.toISOString(true) || null,
              },
              {
                method: 'le',
                value: value?.[1]?.utc(true)?.toISOString(true) || null,
              },
            ])
          }
          value={[
            startISOString?.length > 5
              ? moment(startISOString)?.utc(true)
              : null,
            endISOString?.length > 5
              ? moment(endISOString)?.utc(true)?.subtract(2, 'hours')
              : null,
          ]}
          className={classes.fitWidth}
        />
      );
    case 'boolean':
      return (
        <BooleanFilterPicker
          prefixOption={
            filter?.parameterNameTranslated || filter?.parameterName
          }
          className={classes.fitWidth}
          value={
            pickerStrings?.[0]?.value
              ? JSON.parse(pickerStrings?.[0]?.value || 'null')
              : null
          }
          onChange={(value) => {
            setPickerStrings(filter?.parameterName, [
              {
                method: getFilterMethod(filter.allowedMethods),
                value: JSON.stringify(value),
              },
            ]);
          }}
        />
      );
    default:
      return (
        <LogikFilterPicker
          key={filter?.parameterName}
          prefixOption={
            filter?.parameterNameTranslated || filter?.parameterName
          }
          parameterType={filter?.parameterType as any}
          className={classes.fitWidth}
          allowedMethods={filter?.allowedMethods || []}
          value={pickerStrings?.length > 0 ? (pickerStrings as any) : []}
          onChange={(value: any) => {
            setPickerStrings(filter?.parameterName, value);
          }}
        />
      );
  }
};

export function calculatePickerStringsBasedOnSearchString(
  parameterName: string,
  searchString: string
): PickerStrings[] {
  const searchStringSplit = (searchString?.split('&') || []).map((s) =>
    s.trim()
  );
  const strings = searchStringSplit.filter(
    (string) => string.split(' ')?.[0]?.includes(parameterName)
  );
  return strings.map((string) => {
    //eslint-disable-next-line
    var method = '';
    var value = '';

    var atPartIndex = 0;

    for (let i = 0; i < string.length; i++) {
      const char = string[i];
      if (char === ' ' && atPartIndex < 2) {
        atPartIndex++;
        continue;
      }
      if (atPartIndex === 1) {
        method += char;
      } else if (atPartIndex === 2) {
        value += char;
      }
    }
    return { method, value: value?.replace(/'/g, '') };
  });
}
